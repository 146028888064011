import { fetch } from './'

export const exchangesApi = {
  list: () => {
    return fetch
      .get('exchange')
      .json()
      .catch((err) => console.log(err))
  },
  create: (exchange) => {
    return fetch
      .post(`exchange`, { json: exchange })
      .json()
  },
  update: (exchangeId, exchange) => {
    return fetch
      .post(`exchange/${exchangeId}`, { json: exchange })
      .json()
  },
  remove: (exchangeId) => {
    return fetch
      .delete(`exchange/${exchangeId}`)
      .json()
  },
}
