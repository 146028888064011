import { cellRuleDirections } from './cellRuleDirections'
import { cellActions } from './cellActions'

export const getColumns = (handleActionClick) => [
  { field: 'id', headerName: 'ID', width: '20', headerAlign: 'center', align: 'center' },
  {
    field: 'enabled',
    headerName: 'Actions',
    renderCell: (cellValue) => {
      return cellActions(cellValue, handleActionClick)
    },
    minWidth: '150',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'rule_directions',
    headerName: 'Direction',
    renderCell: ({ row: { rule_directions, id } }) => {
      return cellRuleDirections(rule_directions, id)
    },
    minWidth: '150',
    headerAlign: 'center',
    align: 'center',
  },
  { field: 'exchange_from', headerName: 'Exchange From', minWidth: '150', headerAlign: 'center', align: 'center' },
  { field: 'exchange_to', headerName: 'Exchange To', minWidth: '150', headerAlign: 'center', align: 'center' },
  {
    field: 'left_exchange_from_asset',
    headerName: 'From Asset',
    minWidth: '160',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row: { left_exchange_from_asset, right_exchange_from_asset } }) => {
      return `${left_exchange_from_asset}-${right_exchange_from_asset}`
    },
  },
  {
    field: 'left_exchange_to_asset',
    headerName: 'To Asset',
    minWidth: '160',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row: { left_exchange_to_asset, right_exchange_to_asset } }) => {
      return `${left_exchange_to_asset}-${right_exchange_to_asset}`
    },
  },
  { field: 'network', headerName: 'Network', minWidth: '140', headerAlign: 'center', align: 'center' },
  { field: 'pmin', headerName: 'PMin', headerAlign: 'center', align: 'center', minWidth: '110' },
]
