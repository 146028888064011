import cexFirstAPI from 'api/cex-first-api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { globalFiltersQuerySelector } from 'redux/selectors/globalFilters'
import { useSelector } from 'react-redux'

export const useRuleLists = (paginationModel, filterModel, sortModel) => {
  const filters = useSelector(globalFiltersQuerySelector)

  return useQuery({
    queryKey: ['cex-first-rules-list', filters],
    queryFn: async () => {
      const data = await cexFirstAPI.getRulesList(filters)

      return data
    },
    staleTime: 10 * 1000,
    cacheTime: 10 * 1000,
    refetchInterval: 10 * 1000,
  })
}

export const useCreateRule = (openEditPage, snackbar) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values) => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      }

      return cexFirstAPI.createRule(requestOptions)
    },
    onSuccess: (_, variables) => {
      openEditPage(false)
      snackbar((popup) => ({ ...popup, open: true, severity: 'success', message: 'New rule is created' }))

      if (variables.id === undefined) {
        queryClient.invalidateQueries({ queryKey: ['cex-first-rules-list'] })

        return
      }

      queryClient.setQueryData(['cex-first-rules-list'], (oldData) => {
        return oldData.map((rule) => (rule.id === variables.id ? variables : rule))
      })
    },
    onError: (error) => {
      snackbar((popup) => ({ ...popup, open: true, severity: 'error', message: error.message }))
    },
  })
}

export const useUpdateRule = (openEditPage, snackbar) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values) => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      }

      console.log(requestOptions)

      return cexFirstAPI.updateRule(values.id, requestOptions)
    },
    onSuccess: (data, variables) => {
      openEditPage(false)
      snackbar((popup) => ({ ...popup, open: true, severity: 'success', message: 'Rule update is successful' }))

      try {
        queryClient.setQueryData(['cex-first-rules-list'], (oldData) => {
          return oldData.map((rule) => (rule.id === variables.id ? data : rule))
        })
      } catch (error) {
        queryClient.invalidateQueries({
          queryKey: ['cex-first-rules-list'],
        })
      }
    },
    onError: (error) => {
      snackbar((popup) => ({ ...popup, open: true, severity: 'error', message: error.message }))
    },
  })
}

export const useDeleteRule = (snackbar, queryClient) => {
  return useMutation({
    mutationFn: async (id) => {
      const response = await cexFirstAPI.deleteRule(id)

      if (!response.ok) {
        throw new Error('Delete failed')
      }

      return response
    },
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(['cex-first-rules-list'], (oldData) => {
        return oldData.filter((row) => row.id !== id)
      })
    },
    onError: (error) => {
      snackbar((popup) => ({ ...popup, open: true, severity: 'error', message: error.message }))
    },
  })
}

export const useEnableRule = (snackbar, queryClient) => {
  return useMutation({
    mutationFn: async (cell) => {
      const response = await cexFirstAPI.enableRule(cell.id)

      if (!response.ok) {
        throw new Error('Enable failed')
      }

      return response
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(['cex-first-rules-list'], (oldData) => {
        return oldData.map((rule) => {
          if (rule.id === variables.id) {
            rule.enabled = !rule.enabled
          }

          return rule
        })
      })
    },
    onError: (error) => {
      snackbar((popup) => ({ ...popup, open: true, severity: 'error', message: error.message }))
    },
  })
}

export const useDisableRule = (snackbar, queryClient) => {
  return useMutation({
    mutationFn: async (cellValue) => {
      const response = await cexFirstAPI.disableRule(cellValue.id)

      if (!response.ok) {
        throw new Error('Disable failed')
      }

      return response
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(['cex-first-rules-list'], (oldData) => {
        return oldData.map((rule) => {
          if (rule.id === variables.id) {
            rule.enabled = !rule.enabled
          }

          return rule
        })
      })
    },
    onError: (error) => {
      snackbar((popup) => ({ ...popup, open: true, severity: 'error', message: error.message }))
    },
  })
}
