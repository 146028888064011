import { Collapse, Typography } from '@mui/material'
import { useRef, useState, useCallback } from 'react'

import s from './Expandable.module.css'

const ExpandLabel = ({ expanded }) => {
  if (expanded) {
    return (
      <>
        <i className='fas fa-minus fa-sm' /> Collapse
      </>
    )
  }

  return (
    <>
      <i className='fas fa-plus fa-sm' /> Expand
    </>
  )
}

const Expandable = ({ title, children, onExpand }) => {
  const [expanded, setExpanded] = useState(false)

  const elRef = useRef(null)

  const executeScroll = () => {
    elRef.current.scrollIntoView()
  }

  const toggleExpand = useCallback(() => {
    setExpanded(!expanded)

    if (onExpand && !expanded) {
      onExpand()
    }
  }, [expanded, onExpand])

  return (
    <div className={s.root}>
      <div id='scroll-anchor' ref={elRef} style={{ position: 'relative', top: -60, left: 0 }} />
      <div className={s.heading}>
        <div className={s.title}>
          <Typography variant='h5'>{title}</Typography>
        </div>
        <div className={s.button} onClick={toggleExpand}>
          <Typography variant='h5'>
            <ExpandLabel expanded={expanded} />
          </Typography>
        </div>
      </div>

      <Collapse in={expanded} onEntered={executeScroll}>
        {children}
      </Collapse>
    </div>
  )
}

export default Expandable
