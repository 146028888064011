import Button from 'components/Button/Button'
import { NavLink } from 'react-router-dom'

export const Tabs = ({ tabs, activeTab }) => {
  return (
    <div>
      {tabs.map(({ label, id, variant, href, onClick }) =>
        href ? (
          <NavLink key={id} to={href}>
            <Button variant={variant ? variant : 'text'} color={id === activeTab ? 'primary' : undefined}>
              {label}
            </Button>
          </NavLink>
        ) : (
          <Button
            key={id}
            variant={variant ? variant : 'text'}
            color={id === activeTab ? 'primary' : undefined}
            onClick={onClick}
            name={id}
          >
            {label}
          </Button>
        )
      )}
    </div>
  )
}
