import { fetch } from './'

const blockedBotsAPI = {
  getBlockedBots: () => {
    return fetch
      .get(`list-blocked-bots`, {})
      .json()
      .catch((err) => console.log(err))
  },

  unblockBot: (botId) => {
    return fetch
      .post(`unblock-bot`, {
        json: {
          bot_id: botId,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },

  blockBot: (botId) => {
    return fetch
      .post(`block-bot`, {
        json: {
          bot_id: botId,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
}

export default blockedBotsAPI