import { useState, useCallback } from 'react'

import { dexExecutionApi } from 'api/dex-execution-api'

const displayPercentage = (valueStr) => {
  const num = Number(valueStr)

  return Number((num * 100).toFixed(2))
}

const parcePercentage = (valueNum) => {
  return `${(Number(valueNum) / 100).toFixed(4)}`
}

const parseToken = (token) => ({
  ...token,
  buy_tax: token.buy_tax !== '0' ? displayPercentage(token.buy_tax) : 0,
  sale_tax: token.sale_tax !== '0' ? displayPercentage(token.sale_tax) : 0,
})

export const useTokensApi = (network, enqueueSnackbar) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const fetch = useCallback(async (networkId) => {
    setLoading(true)
    try {
      const res = await dexExecutionApi.getTokens(networkId)

      if (res) {
        setData(res.map(parseToken))
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [])

  const save = useCallback(async ({ id, ...data }) => {
    await dexExecutionApi.saveToken(id, {
      ...data,
      network_id: network.id,
      buy_tax: data.buy_tax !== 0 ? parcePercentage(data.buy_tax) : '0',
      sale_tax: data.sale_tax !== 0 ? parcePercentage(data.sale_tax) : '0',
    })
    await fetch(network.id)
  }, [fetch, network])

  const remove = useCallback(async (id) => {
    try {
      await dexExecutionApi.removeToken(id)
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
    await fetch(network.id)
  }, [fetch, network, enqueueSnackbar])

  const toggle = useCallback(async (e) => {
    const checked = e.target.checked
    const tokenId = e.target.name

    try {
      if (checked) {
        await dexExecutionApi.enableToken(tokenId)
      } else {
        await dexExecutionApi.disableToken(tokenId)
      }
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }

    await fetch(network.id)
  }, [fetch, network, enqueueSnackbar])

  const reset = useCallback(() => setData([]), [])

  return { data, fetch, save, remove, reset, loading, toggle }
}

export const usePairsApi = (network, enqueueSnackbar) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const fetch = useCallback(async (networkId) => {
    setLoading(true)

    try {
      const res = await dexExecutionApi.getPairs(networkId)

      setData(res)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [])

  const save = useCallback(async ({ id, ...data }) => {
    const payload = {
      exchange: data.exchange.internal_name,
      enabled: data.enabled,
      asset0: data.asset0.name,
      asset1: data.asset1.name,
      network_id: network.id,
    }

    await dexExecutionApi.savePair(id, payload)
    await fetch(network.id)
  }, [fetch, network])

  const remove = useCallback(async (id) => {
    await dexExecutionApi.removePair(id)
    try {
      await fetch(network.id)
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
  }, [fetch, network, enqueueSnackbar])

  const toggle = useCallback(async (e) => {
    const checked = e.target.checked
    const pairId = e.target.name

    try {
      if (checked) {
        await dexExecutionApi.enablePair(pairId)
      } else {
        await dexExecutionApi.disablePair(pairId)
      }
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }

    await fetch(network.id)
  }, [fetch, network, enqueueSnackbar])

  const reset = useCallback(() => setData([]), [])

  return { data, toggle, fetch, save, remove, reset, loading }
}
