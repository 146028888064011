import React, { useContext } from 'react'
import Container from '@mui/material/Container'
import { useLocation } from 'react-router'
import { FullScreen } from 'react-full-screen'
import { FullscreenContext } from 'providers/Fullscreen/Provider'

import Header from './Header/Header'

const Layout = ({ children }) => {
  const location = useLocation()
  const { container } = useContext(FullscreenContext)

  return (
    <FullScreen handle={container} className='app'>
      {location.pathname !== '/login' && <Header />}
      <Container maxWidth='false'>
        <div className={'content'}>{children}</div>
      </Container>
    </FullScreen>
  )
}

export default Layout
