import { useSnackbar } from 'notistack'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { redirectToCognitoPage } from 'api/auth-api'

import { exchangesApi } from 'api/exchanges-api'

export const EXCHANGE_ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  REMOVE: 'remove',
}

export const useExchangesApi = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: ['exchanges'],
    queryFn: async () => {
      const data = await exchangesApi.list()

      if (data.status === 401) {
        redirectToCognitoPage()

        return []
      }

      return Array.isArray(data) ? data : []
    },
    cacheTime: 60000 * 60, // 1 hour
    staleTime: 60000 * 60, // 1 hour
    placeholderData: [],
  })

  const update = useMutation({
    mutationFn: ({ id, data }) => {
      return exchangesApi.update(id, data)
    },
    onSuccess: (_, data) => {
      queryClient.invalidateQueries(['exchanges'])
      enqueueSnackbar(`exchange ${data.data.name} updated`, { variant: 'success' })
    },
    onError: (e) => {
      enqueueSnackbar(e.message || 'Unknown error', { variant: 'error' })
    },
  })

  const create = useMutation({
    mutationFn: (data) => {
      return exchangesApi.create(data)
    },
    onSuccess: (_, data) => {
      queryClient.invalidateQueries(['exchanges'])
      enqueueSnackbar(`exchange ${data.name} created`, { variant: 'success' })
    },
    onError: (e) => {
      enqueueSnackbar(e.message || 'Unknown error', { variant: 'error' })
    },
  })

  const remove = useMutation({
    mutationFn: async ({ exchangeId, exchangeName }) => {
      await exchangesApi.remove(exchangeId)

      return { exchangeName, exchangeId }
    },
    onSuccess: (_, data) => {
      queryClient.invalidateQueries(['exchanges'])
      enqueueSnackbar(`exchange ${data.exchangeName} removed`, { variant: 'success' })
    },
    onError: (e) => {
      enqueueSnackbar(e.message || 'Unknown error', { variant: 'error' })
    },
  })

  return [query, { update: update.mutateAsync, create: create.mutateAsync, remove: remove.mutateAsync }]
}
