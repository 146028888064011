export const COLUMNS = [
  {
    Header: 'Bot ID',
    accessor: 'bot_id',
    disableSortBy: true,
  },
  {
    Header: 'Pair',
    accessor: 'pair',
  },
  {
    Header: 'Direction',
    accessor: 'direction',
  },
  {
    Header: 'Profit, %',
    accessor: 'profit',
  },
  {
    Header: 'Number of fills',
    accessor: 'fills_number',
  },
  {
    Header: 'Fills details',
    accessor: 'key',
    id: 'details',
    Cell: ({ row, ...cell }) => (
      <div onMouseEnter={(e) => cell.openFills(e, row.original.fills_data)} onMouseLeave={cell.closeFills}>
        <i className='fas fa-info-circle fa-lg' style={{ color: 'grey' }} />
      </div>
    ),
  },
  {
    Header: 'Profit, %',
    accessor: 'fills_data',
  },
  {
    Header: 'Current arbitrage',
    accessor: 'current_arbitrage',
  },
  {
    Header: 'Start',
    accessor: 'started_at',
  },
  {
    Header: 'End',
    accessor: 'ended_at',
  },
]
