import { useCallback } from 'react'
import { balanceLevelingApi } from 'api/balance-leveling-api'
import { useSnackbar } from 'notistack'

export const useBotBalanceLevelingApi = () => {
  const { enqueueSnackbar } = useSnackbar()

  const enableRule = useCallback(async (ruleId) => {
    try {
      await balanceLevelingApi.enableRule(ruleId)
      enqueueSnackbar(`rule ${ruleId} enabled`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
  }, [enqueueSnackbar])

  const disableRule = useCallback(async (ruleId) => {
    try {
      await balanceLevelingApi.disableRule(ruleId)
      enqueueSnackbar(`rule ${ruleId} disabled`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
  }, [enqueueSnackbar])

  const updateRule = useCallback(async (rule) => {
    try {
      await balanceLevelingApi.updateRule(rule)
      enqueueSnackbar(`rule ${rule.id} updated`, { variant: 'success' })

      return rule
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })

      return undefined
    }
  }, [enqueueSnackbar])

  return {
    updateRule,
    enableRule,
    disableRule,
  }
}
