import Switch from '@mui/material/Switch'
import styled from '@emotion/styled'

const SSwitch = (props) => {
  const StyledSwitch = styled(Switch)`
    margin: 0.8px;
  `
  const id = 'toggle_state_' + props.cell.id

  return (
    <StyledSwitch
      id={id}
      size='small'
      color='primary'
      checked={props.cell.row.enabled}
      onChange={(e) => {
        e.preventDefault()
        props.actions.enableDisableRule(props.cell)
      }}
    />
  )
}

export default SSwitch
