import { useMemo } from 'react'
import { useNetworksApi } from 'pages/Networks/hooks'
import { useExchangesApi } from 'pages/Exchanges/hooks'

export const useNetworksSelector = () => {
  const [query] = useNetworksApi()

  return useMemo(() => query.data?.reduce((acc, network) => {
    acc[network.name] = network

    return acc
  }, []) || [], [query.data])
}

export const useNetworksSelectorById = () => {
  const [query] = useNetworksApi()

  return useMemo(() => query.data?.reduce((acc, network) => {
    acc[network.id] = network

    return acc
  }, []) || [], [query.data])
}

export const useNetworksListSelector = () => {
  const [query] = useNetworksApi()
  const networks = query.data || []

  return networks
}

export const useExchangesSelector = () => {
  const [query] = useExchangesApi()
  const exchanges = query.data || []

  return exchanges
}

export const useExchangesListSelector = () => {
  const networks = useNetworksSelectorById()
  const [query] = useExchangesApi()

  return useMemo(() =>
    query.data?.map(exchange => ({ ...exchange, network_name: networks[exchange.network_id]?.name })) || []
  , [query.data, networks])
}

export const useExchangesByNetworkSelector = () => {
  const [query] = useExchangesApi()

  return useMemo(() => query.data?.reduce((acc, exchange) => {
    if (!acc[exchange.network_id]) {
      return {
        ...acc,
        [exchange.network_id]: [exchange],
      }
    }

    acc[exchange.network_id].push(exchange)

    return acc
  }, {}) || {}, [query.data])
}
