import { fetch } from './'

const nonceApi = {
  setNonce: (network, wallet, nonce) => {
    return fetch
      .post(`set-nonce`, { json: { network, wallet, nonce } })
  },
}

export default nonceApi
