import { useState, useEffect, Fragment } from 'react'
import { menu } from 'config/menu'
import { NavLink } from 'react-router-dom'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'
import { useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom'

import BurgerButton from './BurgerButton/BurgerButton'
import s from './Header.module.css'

const DropDownMenu = ({ pathname }) => {
  const [menuOpened, setMenuOpened] = useState(false)

  useEffect(() => {
    setMenuOpened(false)
  }, [pathname])

  return (
    <div className={s.dropdownContainer}>
      <BurgerButton
        opened={menuOpened}
        onClick={() => setMenuOpened(!menuOpened)}
        label={menu.find(({ path }) => path === pathname)?.name || ''}
      />

      <div
        className={s.dropdown}
        style={{
          display: menuOpened ? 'block' : 'none',
        }}
      >
        <List>
          {menu.map(({ name, path }, i) => (
            <Fragment key={name}>
              <ListItemButton
                className={s.dropdownItem}
                component={NavLink}
                selected={path === pathname}
                to={path}
                label={name}
                value={path}
              >
                {name}
              </ListItemButton>
              {i !== menu.length - 1 && <Divider />}
            </Fragment>
          ))}
        </List>
      </div>
    </div>
  )
}

export const Nav = () => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery('(max-width: 1000px)')

  if (isMobile) {
    return (
      <DropDownMenu pathname={pathname} />
    )
  }

  return (
    <Tabs
      value={pathname}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 },
        },
      }}
    >
      {menu.map(({ name, path }) => <Tab
        component={NavLink}
        to={path}
        key={name}
        label={name}
        value={path}
      />)}
    </Tabs>
  )
}

export default Nav
