import React from 'react'
import moment from 'moment'
import styled from '@emotion/styled'

import { getTxPage } from 'utilities/get-tx-page'
import QueryFilter from 'components/Table/components/QueryFilter'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { Tooltip } from '@mui/material'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

const TxLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const linkLenght = 20

function truncate(text) {
  if (text.length > linkLenght) {
    return text.slice(0, linkLenght) + '...'
  }

  return text
}

export const getColumns = (networks) => {
  return [
    {
      Header: 'Tx ID',
      accessor: 'id',
      Filter: renderFilter,
      Cell: ({ row, value }) => {
        const network = /_SPOT$/g.test(row.original.to) ? row.original.from : row.original.to
        const txHash = row.original.tx_hash

        if (txHash && network && networks[network] && networks[network].block_explorer_url) {
          const href = getTxPage(networks, network, txHash)

          return (
            <TxLink href={href} target='_blank' className={row.original.success ? 'green' : 'red'} rel='noreferrer'>
              {value}&nbsp;
              <i className='fas fa-external-link-alt fa-xs' />
            </TxLink>
          )
        }

        return <span className={row.original.success ? 'green' : 'red'}>{value}</span>
      },
    },
    {
      Header: 'Tx Hash',
      accessor: 'tx_hash',
      Filter: renderFilter,
      Cell: ({ row, value }) => {
        const network = /_SPOT$/g.test(row.original.to) ? row.original.from : row.original.to
        const txHash = row.original.tx_hash

        if (txHash && network && networks[network] && networks[network].block_explorer_url) {
          return (
            <div>
              <Tooltip title={value}>
                {truncate(value)}
                <CopyToClipboard text={value} />
              </Tooltip>
            </div>
          )
        }
      },
    },
    {
      Header: 'Tx Time',
      accessor: 'tx_time',
      Cell: ({ value }) =>
        value !== '1970-01-01T00:00:00Z'
          ? moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('YYYY-MM-DD HH:mm')
          : '',
      Filter: renderFilter,
    },
    {
      Header: 'From',
      accessor: 'from',
      Filter: renderFilter,
    },
    {
      Header: 'To',
      accessor: 'to',
      Filter: renderFilter,
    },
    {
      Header: 'Asset',
      accessor: 'asset',
      Filter: renderFilter,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Filter: renderFilter,
      Cell: ({ value }) => {
        if (value) {
          const num = parseFloat(value);

          return num.toString();
        }

        return value;
      },
    },
    {
      Header: 'Message',
      accessor: 'message',
      Filter: renderFilter,
    },
    {
      Header: 'Max Fee',
      accessor: 'max_fee',
      Filter: renderFilter,
    },
    {
      Header: 'Actual Fee',
      accessor: 'actual_fee',
      Filter: renderFilter,
      Cell: ({ value }) => {
        if (value) {
          const num = parseFloat(value);
          const rounded = Math.round(num * 1000) / 1000;

          return rounded.toFixed(3);
        }

        return value;
      },
    },
    {
      Header: 'Bot ID',
      accessor: 'bot_id',
      Filter: renderFilter,
    },
  ]
}
