import { Button, Paper, Popover } from '@mui/material'
import { DateRangePicker as BaseDateRangePicker } from 'react-date-range'

const DateRangePicker = ({ anchorEl, value, handleClose, handleChange, handleConfirm }) => {
  const open = Boolean(anchorEl)
  const id = open ? 'date-range' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper className='column' style={{ alignItems: 'center', paddingBottom: 8 }}>
        <BaseDateRangePicker
          startDatePlaceholder='From'
          endDatePlaceholder='To'
          ranges={[
            {
              key: value.key,
              startDate: new Date(value.startDate),
              endDate: new Date(value.endDate),
            },
          ]}
          dateDisplayFormat={'dd/MM/yyyy'}
          fixedHeight
          staticRanges={[]}
          inputRanges={[]}
          onChange={handleChange}
        />
        <Button variant='outlined' onClick={handleConfirm}>
          OK
        </Button>
      </Paper>
    </Popover>
  )
}

export default DateRangePicker
