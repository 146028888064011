import { useMemo, useState } from 'react'
import Table from 'components/Table/Table'
import isEmpty from 'lodash/isEmpty'
import { getColumns, DEFAULT_VALUES } from './columns'
import { useExchangesByNetworkSelector } from 'redux/selectors/settings'

import { Dialog } from '../Dialog'
import { CreateBtn } from '../Dialog/CreateBtn'

export const PairsTable = ({ pairs, tokens, selectedNetwork }) => {
  const exchangesMap = useExchangesByNetworkSelector()
  const exchanges = useMemo(() => selectedNetwork ? exchangesMap[selectedNetwork.id] : [], [exchangesMap, selectedNetwork])

  const [selected, setSelected] = useState(null)
  const columns = useMemo(() => getColumns(setSelected, exchanges, tokens, pairs.data, pairs.toggle)
    , [setSelected, exchanges, tokens, pairs.data, pairs.toggle])

  return (
    <>
      <Table
        title={<CreateBtn title="Pairs" openCreateForm={setSelected}  />}
        data={pairs.data}
        columns={columns}
        loading={pairs.loading}
        initialState={{
          sortBy: [{ id: 'asset0', desc: false }],
        }}
        enableFilters
      />
      <Dialog
        value={selected?.value}
        defaultValues={isEmpty(selected?.value) ? DEFAULT_VALUES : selected.value}
        formType={selected?.type}
        onClose={() => setSelected(null)}
        save={pairs.save}
        remove={pairs.remove}
        columns={columns}
      />
    </>
  )
}
