import { Paper, Popover, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Table from 'components/Table/Table'
import Button from 'components/Button/Button'

import botAPI from '../../api/bot-api'
import { useQuery } from '../../hooks/use-query'

import { COLUMNS } from './columns'
import s from './BotHistory.module.css'

const BotHistory = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [lastPage, setLastPage] = useState(1)
  const [fillsAnchor, setFillsAnchor] = useState(null)
  const [fillsData, setFillsData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const { botId } = useParams()

  const navigate = useNavigate()
  const query = useQuery()
  const page = Number(query?.get('page')) || 1

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)
      const response = await botAPI.getBotHistory(botId, page < 1 ? 1 : page)

      if (response?.data?.length > 0) {
        setData(response.data)
        setLastPage(response.meta?.last_page)
      }
      setIsFetching(false)
    })()
  }, [page, botId])

  const openFills = (e, fills) => {
    setFillsData(fills)
    setFillsAnchor(e.currentTarget)
  }

  const closeFills = () => setFillsAnchor(null)

  const showFills = Boolean(fillsAnchor)

  return (
    <Paper className={s.paper} elevation={2}>
      <Typography variant={'h5'} style={{ padding: '0 10px' }}>
        Bot #{botId} history
      </Typography>
      <Table data={data} columns={columns} loading={isFetching} cellProps={{ openFills, closeFills }} />
      <div className={s.pagination}>
        <Button onClick={() => navigate(`/bot-history/${botId}?page=${page - 1}`)} disabled={!page || page <= 1}>
          Prev
        </Button>
        <Button
          onClick={() => navigate(`/bot-history/${botId}?page=${page ? page + 1 : 2}`)}
          disabled={page >= lastPage}
        >
          Next
        </Button>
      </div>
      <Popover
        open={showFills}
        anchorEl={fillsAnchor}
        onClose={closeFills}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ pointerEvents: 'none' }}
        disableRestoreFocus
        useLayerForClickAway={false}
      >
        <div style={{ padding: 6, width: 220 }}>
          {fillsData?.map((fill, i) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div>{i}</div>
              <div>{fill[i]}</div>
            </div>
          ))}
        </div>
      </Popover>
    </Paper>
  )
}

export default BotHistory
