import IconButton from '@mui/material/IconButton'

import { NETWORK_ACTION } from '../hooks'

export const CreateNetworksBtn = ({ setSelectedNetwork }) => (
  <>
    Networks
    <IconButton
      onClick={() => {
        setSelectedNetwork({ _type: NETWORK_ACTION.CREATE })
      }}
      size={'small'}
      style={{ margin: '0 8px', color: '#1976d2' }}
    >
      <i className='fas fa-plus' />
    </IconButton>
  </>
)
