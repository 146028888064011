const config = {
  cognito: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    redirectUri:
      process.env.REACT_APP_REDIRECT_URI || 'https://1-test-arbitrage.internal-fun-chegonibudj.com/token-callback',
    provider: process.env.REACT_APP_PROVIDER || 'https://auth.internal-fun-chegonibudj.com',
    clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
  },
  ws: {
    baseUrl: process.env.REACT_APP_WS_BASE_URL || window.location.origin,
  },
}

export default config
