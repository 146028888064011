const SAVE_BOTS_LIST = 'bots/save-list'

const initialState = {
  exchanges: [],
  pairs: [],
}

const botsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BOTS_LIST: {
      return {
        ...state,
        list: action.payload,
      }
    }
    default:
      return state
  }
}

export const botsActions = {
  saveBotsList: (payload) => ({ type: SAVE_BOTS_LIST, payload }),
}

export default botsReducer
