import { fetch } from './'

const arbitrageAPI = {
  getPairs: (name, onlyTopDeals = false) => {
    const params = {}

    if (name) params.name = name
    if (onlyTopDeals) params.onlyTopDeals = 1

    return fetch
      .get(`pair`, { searchParams: params })
      .json()
      .catch((err) => console.log(err))
  },

  savePairs: (pairs) => {
    return fetch
      .post(`pair`, { json: { id: pairs } })
      .json()
      .catch((err) => console.log(err))
  },

  getArbitrationData: () => {
    return fetch
      .get(`get-arbitrage-prices`)
      .json()
      .catch((err) => console.log(err))
  },

  getChartData: (pair, bid, ask) => {
    return fetch
      .get(`chart?pair=${pair}&bid_from_exchange=${bid}&ask_from_exchange=${ask}`)
      .json()
      .then(({ data }) => data)
      .catch((err) => console.log(err))
  },
}

export default arbitrageAPI
