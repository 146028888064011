import { Paper, Typography } from '@mui/material'
import { Route, Routes } from 'react-router'
import { NavLink } from 'react-router-dom'

import s from './Nonce.module.css'
import NonceForm from './NonceForm/NonceForm'
import BotForm from './BotForm/BotForm'

const Wrapper = ({ label, children, ...props }) => {
  return (
    <Paper className={s.paper} elevation={2} {...props}>
      <Typography variant={'h5'}>{label}</Typography>
      {children}
    </Paper>
  )
}

const Nonce = () => {
  return (
    <div className='flex-column'>
      <div style={{ marginBottom: 20 }}>
        <NavLink
          className={({ isActive }) => isActive ? s.activeNavItem : s.navItem}
          to=""
          id='nonce_link'
          end
        >
          Nonce
        </NavLink>
        <NavLink
          className={({ isActive }) => isActive ? s.activeNavItem : s.navItem}
          to="bot"
          id='bot_link'
        >
          Bot
        </NavLink>
      </div>
      <Routes>
        <Route
          path=""
          element={
            <Wrapper label='Set Nonce' id='set_nonce_block'>
              <NonceForm />
            </Wrapper>
          }
        />
        <Route
          path="/bot"
          element={
            <Wrapper label='Bot' id='update_bot'>
              <BotForm />
            </Wrapper>
          }
        />
      </Routes>
    </div>
  )
}

export default Nonce
