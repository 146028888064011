import IconButton from '@mui/material/IconButton'

import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Autocomplete from '@mui/material/Autocomplete'
import Switch from '@mui/material/Switch';
import QueryFilter from 'components/Table/components/QueryFilter'
import { FORM_ACTION } from '../Dialog/Form'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

export const DEFAULT_VALUES = {
  enabled: false,
  exchange: null,
  asset0: null,
  asset1: null,
}

export const getColumns = (setSelected, exchanges, tokens, pairs, toggle) => ([
  {
    Header: 'Exchange',
    accessor: 'exchange',
    Filter: renderFilter,
    formProps: {
      disabled: false,
      hidden: false,
      type: 'string',
      renderInput: ({
        errors,
        isRemoveForm,
        field,
        setValue,
      }) => {
        return (
          <FormControl fullWidth>
            <Autocomplete
              openOnFocus
              disabled={isRemoveForm}
              blurOnSelect
              value={field.value}
              onChange={(_, newValue) => {
                field.onChange(newValue)
                setValue('asset0', null)
                setValue('asset1', null)
              }}
              isOptionEqualToValue={({ internal_name }) => internal_name === field.value.internal_name}
              getOptionLabel={(option) => option.internal_name}
              options={exchanges}
              renderInput={(params) => <TextField {...params} label="Exchange" />}
              size="small"
            />
            {errors[field.name] && <FormHelperText error>required</FormHelperText>}
          </FormControl>
        )
      },
    },
  },
  {
    Header: 'Asset 0',
    accessor: 'asset0',
    Filter: renderFilter,
    formProps: {
      disabled: false,
      hidden: false,
      type: 'string',
      renderInput: ({
        errors,
        isRemoveForm,
        field,
        setValue,
        watch,
      }) => {
        const exchange = watch('exchange')

        return (
          <FormControl fullWidth>
            <Autocomplete
              openOnFocus
              blurOnSelect
              disabled={isRemoveForm || !exchange}
              value={field.value}
              onChange={(_, newValue) => {
                field.onChange(newValue)
                setValue('asset1', null)
              }}
              isOptionEqualToValue={({ name }) => name === field.value.name}
              getOptionDisabled={({ enabled }) => !enabled}
              getOptionLabel={(option) => option.name}
              options={tokens}
              renderInput={(params) => <TextField {...params} label="Asset 0" />}
              size="small"
            />
            {errors[field.name] && <FormHelperText error>required</FormHelperText>}
          </FormControl>
        )
      },
    },
  },
  {
    Header: 'Asset 1',
    accessor: 'asset1',
    Filter: renderFilter,
    formProps: {
      disabled: false,
      hidden: false,
      type: 'string',
      renderInput: ({
        errors,
        isRemoveForm,
        field,
        watch,
      }) => {
        const val = watch('asset0')
        const exchange = watch('exchange')
        const skipTokens = new Set([val?.name])

        if (!isRemoveForm && val) {
          pairs.forEach(pair => {
            if (exchange.internal_name !== pair.exchange) {
              return
            }

            if (pair.asset0 === val.name) {
              skipTokens.add(pair.asset1)
            }

            if (pair.asset1 === val.name) {
              skipTokens.add(pair.asset0)
            }
          })
        }

        return (
          <FormControl fullWidth>
            <Autocomplete
              openOnFocus
              disabled={isRemoveForm || !val}
              blurOnSelect
              value={field.value}
              onChange={(_, newValue) => {
                field.onChange(newValue)
              }}
              isOptionEqualToValue={({ name }) => name === field.value.name}
              getOptionDisabled={({ name, enabled }) => !enabled || skipTokens.has(name)}
              getOptionLabel={(option) => option.name}
              options={tokens}
              renderInput={(params) => <TextField {...params} label="Asset 1" />}
              size="small"
            />
            {errors[field.name] && <FormHelperText error>required</FormHelperText>}
          </FormControl>
        )
      },
    },
  },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    sortType: 'alphanumericCustom',
    Filter: renderFilter,
    disableFilters: true,
    Cell: ({ value, row }) => (
      <Switch
        checked={value}
        name={row.original.id.toString()}
        onChange={toggle}
      />
    ),
    formProps: {
      disabled: false,
      hidden: false,
      type: 'boolean',
    },
  },
  {
    Header: '',
    accessor: '_update-action',
    formProps: {
      hidden: true,
    },
    Cell: ({ row }) => {
      const parsedValue = {
        ...row.original,
        exchange: {
          internal_name: row.original.exchange,
        },
        asset0: {
          name: row.original.asset0,
        },
        asset1: {
          name: row.original.asset1,
        },
      }

      return (
        <>
          <IconButton
            onClick={() => {
              setSelected({ value: parsedValue, type: FORM_ACTION.UPDATE })
            }}
            size={'small'}
            style={{ margin: '0 8px', color: '#1976d2' }}
          >
            <i className='fas fa-edit' />
          </IconButton>
          <IconButton
            onClick={() => {
              setSelected({ value: parsedValue, type: FORM_ACTION.REMOVE })
            }}
            size={'small'}
            style={{ margin: '0 8px', color: 'red' }}
          >
            <i className='fas fa-trash' />
          </IconButton>
        </>
      )
    },
    disableSortBy: true,
    disableFilters: true,
  },
])
