import { Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

export const cellRuleDirections = (data, rule_id) => {
  const boxKey = 'rule_direction_' + rule_id + '_'

  return (
    <Table size='normal'>
      <TableBody>
        {data?.map(function (data) {
          return (
            <TableRow size='small' key={boxKey + data.id}>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Tooltip title='Side'>{data.side}</Tooltip>
              </TableCell>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Tooltip title='Amount'>{data.amount}</Tooltip>
              </TableCell>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Tooltip title='Profit'>{data.profit}</Tooltip>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
