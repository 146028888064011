import Paper from '@mui/material/Paper'
import styled from '@emotion/styled'
import Table from 'components/Table/Table'
import { SpenderSelector } from './SpenderSelector'
import { useAllowance } from './hooks'

const AllowancesContainer = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
  width: 100%;
  max-width: 800px;
`;

export const Allowances = () => {
  const {
    exchanges,
    networks,
    selectSpender,
    data,
    columns,
    loading,
    selectedExchange,
    spenderAddress,
  } = useAllowance()

  return (
    <AllowancesContainer elevation={2} id='allowances_table'>
      <SpenderSelector
        spenderAddress={spenderAddress}
        networks={networks}
        exchanges={exchanges}
        selectedExchange={selectedExchange}
        onSelectExchange={selectSpender}
        focus
      />
      <Table
        data={data}
        columns={columns}
        loading={loading}
        initialState={{
          sortBy: [{ id: 'allowance', desc: true }],
        }}
        enableFilters
      />
    </AllowancesContainer>
  )
}
