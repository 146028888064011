import { useCallback, useEffect, useState } from 'react'
import { Alert, Divider, IconButton, Paper, Snackbar, Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'

import blocksApi from '../../../../api/blocks-api'

import s from './BlocksTable.module.css'

const BlocksAlert = ({ popup, handlePopupClose }) => (
  <Alert style={{ display: 'flex', alignItems: 'center' }} onClose={handlePopupClose} severity={popup.severity}>
    <Typography variant={'h6'}>{popup.message}</Typography>
  </Alert>
)

const BlocksTable = () => {
  const [data, setData] = useState([])
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const parseData = (data) => {
    return [...(data?.blocksActive?.map((item) => ({ ...item, active: true })) || [])]
  }

  useEffect(() => {
    ;(async () => {
      const res = await blocksApi.getBlocks()
      const parsedData = parseData(res)

      setData(parsedData)
      if (parsedData.length < 1) {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'System runs smoothly and earns money!',
          severity: 'success',
        }))
      }
    })()
  }, [])

  const handleStatusChange = async (block) => {
    let res

    if (block.active) {
      res = await blocksApi.unblockExecution(block.name, block.exchange)
      if (res) {
        setData((prev) => prev.map((item) => (item.id === block.id ? { ...item, active: false } : item)))
      }

      return
    }

    res = await blocksApi.blockExecution(block.name, block.exchange)
    if (res) {
      setData((prev) => prev.map((item) => (item.id === block.id ? { ...item, active: true } : item)))
    }
  }

  const handlePopupClose = () => setPopup((popup) => ({ ...popup, open: false }))

  const closeBlockList = () => {
    setData([])
  }

  const BlockItem = useCallback(({ block }) => {
    return (
      <>
        <Divider />
        <div className='flex-row' style={{ gap: 12 }} id='blocks_item'>
          <IconButton onClick={() => handleStatusChange(block)}>
            <i className='fas fa-power-off' style={{ color: block.active ? 'green' : 'red' }} />
          </IconButton>
          <div className='column'>
            <Typography style={{ fontWeight: 700 }}>{block.name}</Typography>
            <Typography style={{ wordBreak: 'break-word' }}>{block.exchange}</Typography>
          </div>
        </div>
      </>
    )
  }, [])

  if (data?.length) {
    return (
      <div className={s.blockContainer}>
        <Paper>
          <div className={s.blockList} id='blocks_table'>
            <div className={s.heading}>
              <Typography variant={'h5'} gutterBottom>
                Blocks
              </Typography>
              <IconButton size='small' onClick={closeBlockList} id='close_blocks_icon'>
                <i className='fa fa-times fa-sm' />
              </IconButton>
            </div>
            <div className='column' style={{ gap: 8 }}>
              {data?.map((block, i) => (
                <BlockItem key={block.name + i} block={block} />
              ))}
            </div>
          </div>
        </Paper>
      </div>
    )
  }

  if (isMobile && popup.open) {
    return (
      <div style={{ margin: '0 4px 12px', maxWidth: 480, alignSelf: 'center' }}>
        <BlocksAlert popup={popup} handlePopupClose={handlePopupClose} />
      </div>
    )
  }

  return (
    <Snackbar open={popup.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handlePopupClose}>
      <div>
        <BlocksAlert popup={popup} handlePopupClose={handlePopupClose} />
      </div>
    </Snackbar>
  )
}

export default BlocksTable
