import Button from 'components/Button/Button'
import { Paper, Typography, Select, MenuItem } from '@mui/material'
import useFullscreen from 'hooks/use-fullscreen'
import { useMemo, useRef, useEffect } from 'react'

import Table from 'components/Table/Table'
import { useNetworksSelector } from 'redux/selectors/settings'

import { getColumns } from './columns'
import s from './DetailedBalanceLeveling.module.css'
import { useBalanceLevelingApi, usePagination, useSort, PAGE_SIZES } from './hooks'
import { BalanceLevelingBanner } from './BalanceLevelingBanner'

const DetailedBalanceLeveling = () => {
  useFullscreen()
  const networks = useNetworksSelector()
  const columns = useMemo(() => getColumns(networks), [networks])

  const [page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize] = usePagination()
  const [params, handleSortChange] = useSort(setStartPage)

  const [data, isFetching, isLastPage] = useBalanceLevelingApi(page, pageSize, params)

  const tableRef = useRef()

  useEffect(() => {
    tableRef.current.scrollIntoView()
  }, [page, pageSize])

  return (
    <Paper sx={{ width: '100%' }} className={s.paper} elevation={2} id='balance_leveling_tx_table'>
      <Typography variant={'h5'} style={{ padding: '0 10px' }}>
        Balance Leveling transactions
      </Typography>
      <BalanceLevelingBanner />
      <Table
        tableRef={tableRef}
        manualFilters
        enableFilters
        multiSelectFilters={false}
        autoSubmitFilters={false}
        columns={columns}
        data={data}
        loading={isFetching}
        tableStyle={{ maxHeight: '600px', minHeight: '200px' }}
        handleSortChange={handleSortChange}
      />
      <div className={s.pagination}>
        <Button onClick={decrementPage} disabled={isFetching || page === 1} id='prev_page'>
          Prev
        </Button>
        <div>{page}</div>
        <div>
          <Select value={pageSize} onChange={changePageSize}>
            {PAGE_SIZES.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={incrementPage} disabled={isFetching || isLastPage} id='next_page'>
            Next
          </Button>
        </div>
      </div>
    </Paper>
  )
}

export default DetailedBalanceLeveling
