import { IconButton, Switch } from '@mui/material'
import QueryFilter from 'components/Table/components/QueryFilter'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

export const getColumns = (exchangesMap, toggleRule, setSelectedNetwork, toggleMonitoring) => [
  {
    Header: 'Update',
    accessor: '_update-action',
    Cell: ({ row }) => {
      return (
        <IconButton
          onClick={() => {
            setSelectedNetwork({ ...row.original })
          }}
          size={'small'}
          style={{ margin: '0 8px', color: '#1976d2' }}
        >
          <i className='fas fa-edit' />
        </IconButton>
      )
    },
    Filter: () => null,
  },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    Cell: ({ value, row }) => {
      return <Switch checked={value} onChange={() => toggleRule(row.original.id, value)} />
    },
    Filter: renderFilter,
  },
  {
    Header: 'Monitoring enabled',
    accessor: 'monitoring_enabled',
    Cell: ({ value, row }) => {
      return <Switch checked={value} onChange={() => toggleMonitoring(row.original.id, value)} />
    },
    Filter: renderFilter,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Filter: renderFilter,
  },
  {
    Header: 'Bot ID',
    accessor: 'bot_id',
    Filter: renderFilter,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Filter: renderFilter,
  },

  {
    Header: 'Min threshold',
    accessor: 'min_threshold',
    Filter: renderFilter,
  },
  {
    Header: 'Period',
    accessor: 'period',
    Filter: renderFilter,
  },
  {
    Header: 'Max fee ',
    accessor: 'max_fee',
    Filter: renderFilter,
  },
  {
    Header: 'From',
    accessor: 'from',
    Cell: ({ value }) => exchangesMap[value] || value,
    Filter: renderFilter,
  },
  {
    Header: 'To',
    accessor: 'to',
    Cell: ({ value }) => exchangesMap[value] || value,
    Filter: renderFilter,
  },
  {
    Header: 'Token',
    accessor: 'coin',
    Filter: renderFilter,
  },
  {
    Header: 'Dex Wallet',
    accessor: 'dex_wallet',
    Filter: renderFilter,
  },
]
