import { useQuery } from '@tanstack/react-query'
import { fetch } from '.'

export const ordersApi = {
  getOrdersCountList: () => {
    return fetch
      .get(`orders-any-spend-count`)
      .json()
      .catch((err) => console.log(err))
  },
}

export const useOrders = () => {
  const query = useQuery({
    queryKey: ['orders'],
    queryFn: () => ordersApi.getOrdersCountList(),
    cacheTime: 60000 * 5, // 5 minutes
    staleTime: 60000 * 5, // 5 minutes
  })

  return query
}
