import Dialog from '@mui/material/Dialog'

import { Rule } from './Rule'

export const RuleDialog = ({ onClose, value, save }) => {
  const { _type, ...rule } = value || {}

  return (
    <Dialog onClose={onClose} open={!!value}>
      <Rule
        type={_type}
        rule={rule}
        close={onClose}
        save={save}
      />
    </Dialog>
  )
}
