import { useEffect, useRef } from 'react'

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()
  const interval = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }

    if (delay !== null) {
      interval.current = setInterval(tick, delay)
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [callback, delay])

  return interval
}
