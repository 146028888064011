import { Table as MuiTable, TableBody, IconButton, Typography, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Preloader from 'components/Preloader/Preloader'
import { useMemo, Fragment } from 'react'
import { useSortBy, useFilters, useTable, useGroupBy, useExpanded } from 'react-table'
import { isMobile } from 'react-device-detect'
import styled from '@emotion/styled'

import { alphanumericFalsyLast, alphanumericCustom, signalSort, botErrorSort, momentSort } from '../../utilities/sorting'

import s from './Table.module.css'

const HoverTableRow = styled(TableRow)`
  &:hover {
    background: #d6eaff;
  }
`

const GroupTableRow = styled(HoverTableRow)`
  background: ${({ isexpanded }) => isexpanded === 'true' ? '#83b5e7' : 'inherit'};

  &:hover {
    background: ${({ isexpanded }) => isexpanded === 'true' ? '#83b5e7' : '#d6eaff'};
  }

  ${({ canexpand }) => canexpand === 'true' ? '' : `
    background: #d6eaff;
    animation-name: show-table-row;
    animation-duration: .5s;

    @keyframes show-table-row {
      from {
        transform: translateX(100%);
        opacity: 0;
      }

      to {
        transform: translateX(0);
        opacity: 1;
      }
    }
  `}
`

function renderTableRow(row) {
  const { prepareRow, cellProps } = this

  prepareRow(row)

  return (
    <HoverTableRow key={row.requestId} {...row.getRowProps()}>
      {row.cells.map((cell, i) => {
        return (
          <TableCell key={i} {...cell.getCellProps()}>
            {cell.render('Cell', { ...cellProps })}
          </TableCell>
        )
      })}
    </HoverTableRow>
  )
}

function renderTableGroupRow(row) {
  const { prepareRow, cellProps } = this

  prepareRow(row)

  return (
    <GroupTableRow {...row.getRowProps()} isexpanded={row.isExpanded?.toString()} canexpand={row.canExpand?.toString()} >
      {row.cells.map((cell, i) => {
        return (
          <TableCell key={i} {...cell.getCellProps()}>
            {cell.isGrouped
              ? (
                <>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    {...row.getToggleRowExpandedProps()}
                  >
                    {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                  {cell.render('Cell', { ...cellProps })}
                </>
              )
              : cell.isAggregated ? cell.render('Aggregated', { ...cellProps }) : cell.render('Cell', { ...cellProps })
            }
          </TableCell>
        )
      })}
    </GroupTableRow>
  )
}

const Table = ({
  columns,
  data,
  title,
  enableFilters = false,
  handleSortChange = () => {},
  manualSortBy = false,
  manualFilters = false,
  cellProps,
  meta = {},
  loading = false,
  tableStyle = {},
  hiddenColumns = [],
  multiSelectFilters = true,
  autoSubmitFilters = true,
  initialState,
  tableRef,
  className,
  groupField,
  sortFns = {},
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortTypes = useMemo(() => ({ alphanumericFalsyLast, alphanumericCustom, signalSort, botErrorSort, momentSort, ...sortFns }), [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      meta,
      stateReducer: (newState) => handleSortChange(newState.sortBy, newState.filters),
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetFilters: false,
      manualSortBy,
      manualFilters,
      sortTypes,
      initialState: { groupBy: groupField ? [groupField] : [], expanded: false, hiddenColumns, ...initialState },
      loading,
      multiSelectFilters,
      autoSubmitFilters,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded
  )

  const customStyle = rows.length === 0 ? { minHeight: 'auto' } : {}
  const loadingStyle = loading ? { overflow: 'hidden' } : {}

  return (
    <div className={className ? `${s.tableContainer} ${className}` : s.tableContainer}>
      {title && <Typography className={s.tableTitle} variant="h5">{title}</Typography>}
      <TableContainer className={s.table} style={{ ...tableStyle, ...customStyle, ...loadingStyle }}>
        <MuiTable ref={tableRef} stickyHeader={!isMobile} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, i) => (
              <Fragment key={i}>
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => {
                    return (
                      <TableCell
                        key={i}
                        style={{
                          display: 'flex !important',
                        }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <div className={s.headCell}>
                          <span
                            style={{
                              userSelect: 'none',
                            }}
                            id='column_title'
                          >
                            {column.render('Header')}
                          </span>
                          {column.isSorted && (
                            <i
                              style={{
                                fontSize: '11px',
                                justifySelf: 'flex-start',
                              }}
                              className={`fas fa-chevron-${
                                column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'
                              }`}
                            />
                          )}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
                {enableFilters && (
                  <TableRow className={s.filters}>
                    {headerGroup.headers.map((column, i) => (
                      <TableCell key={i} {...column.getHeaderProps()}>
                        {column.canFilter ? column.render('Filter') : null}
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </Fragment>
            ))}
          </TableHead>
          {rows.length > 0 && (
            <TableBody {...getTableBodyProps()}>
              {rows.map(groupField ? renderTableGroupRow : renderTableRow, { prepareRow, cellProps, groupField })}
            </TableBody>
          )}
        </MuiTable>
        {loading && rows.length === 0 && (
          <div className={s.preloader}>
            <Preloader />
          </div>
        )}
        {!loading && rows.length === 0 && (
          <h2 style={{ margin: '8px', textAlign: 'center' }}>No results</h2>
        )}
        {loading && rows.length !== 0 && (
          <div className={`${s.preloader} ${s.preloaderAnimated}`} />
        )}
      </TableContainer>
    </div>
  )
}

export default Table
