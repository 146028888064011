import IconButton from '@mui/material/IconButton'
import QueryFilter from 'components/Table/components/QueryFilter'
import { getAddressPageByNetwork } from 'utilities/get-address-page'
import { CopyButton } from 'components/CopyButton'
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'

import { FORM_ACTION } from '../Dialog/Form'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

export const DEFAULT_VALUES = {
  enabled: false,
  address: '0x',
  decimals: 18,
}

export const getColumns = (network, setSelected, toggleToken) => ([
  {
    Header: 'Name',
    accessor: 'name',
    Filter: renderFilter,
    formProps: {
      disabled: false,
      hidden: false,
      type: 'string',
    },
  },
  {
    Header: 'Address',
    accessor: 'address',
    Filter: renderFilter,
    disableSortBy: true,
    Cell: ({ value }) => {
      const href = getAddressPageByNetwork(network, value)
      const shortAddr = `${value.slice(0, 4)}...${value.slice(-4)}`

      return (
        <div>
          <CopyButton value={value} />
          {shortAddr}
          <IconButton
            compinent="a"
            target="_blank"
            href={href}
            size={'small'}
          >
            <i className='fas fa-external-link-alt fa-xs' />
          </IconButton>
        </div>
      )
    },
    formProps: {
      disabled: false,
      hidden: false,
      type: 'string',
    },
  },
  {
    Header: 'Decimals',
    accessor: 'decimals',
    sortType: 'alphanumericCustom',
    Filter: renderFilter,
    formProps: {
      disabled: false,
      hidden: false,
      type: 'string',
      transform: (onChange) => (e) => {
        const num = +e.target.value

        if (!Number.isNaN(num) && num !== 0) {
          onChange(num)
        } else {
          onChange('')
        }
      },
    },
  },
  {
    Header: 'Buy Tax',
    accessor: 'buy_tax',
    sortType: 'alphanumericCustom',
    Filter: renderFilter,
    Cell: ({ value }) => <span>{value} %</span>,
    formProps: {
      disabled: false,
      hidden: false,
      type: 'string',
      rules: {
        min: 0,
        max: 100,
      },
      transform: (onChange) => (e) => {
        if (/^-?\d+\.?\d*$/.test(e.target.value)) {
          onChange(e.target.value)
        } else {
          onChange('')
        }
      },
      renderInput: ({
        errors,
        isRemoveForm,
        field,
        column,
      }) => {
        return (
          <FormControl fullWidth>
            <TextField
              disabled={isRemoveForm || column.formProps.disabled}
              label={column.Header}
              error={!!errors[column.accessor]}
              helperText={errors[column.accessor] && "invalid"}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              {...field}
              {...(column.formProps.transform ? { onChange: column.formProps.transform(field.onChange) } : {})}
            />
          </FormControl>
        )
      },
    },
  },
  {
    Header: 'Sale Tax',
    accessor: 'sale_tax',
    sortType: 'alphanumericCustom',
    Filter: renderFilter,
    Cell: ({ value }) => <span>{value} %</span>,
    formProps: {
      disabled: false,
      hidden: false,
      type: 'string',
      rules: {
        min: 0,
        max: 100,
      },
      transform: (onChange) => (e) => {
        if (/^-?\d+\.?\d*$/.test(e.target.value)) {
          onChange(e.target.value)
        } else {
          onChange('')
        }
      },
      renderInput: ({
        errors,
        isRemoveForm,
        field,
        column,
      }) => {
        return (
          <FormControl fullWidth>
            <TextField
              disabled={isRemoveForm || column.formProps.disabled}
              label={column.Header}
              error={!!errors[column.accessor]}
              helperText={errors[column.accessor] && "invalid"}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              {...field}
              {...(column.formProps.transform ? { onChange: column.formProps.transform(field.onChange) } : {})}
            />
          </FormControl>
        )
      },
    },
  },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    sortType: 'alphanumericCustom',
    Filter: renderFilter,
    disableFilters: true,
    Cell: ({ value, row }) => (
      <Switch
        checked={value}
        name={row.original.id.toString()}
        onChange={toggleToken}
      />
    ),
    formProps: {
      disabled: false,
      hidden: false,
      type: 'boolean',
    },
  },
  {
    Header: '',
    accessor: '_update-action',
    formProps: {
      hidden: true,
    },
    Cell: ({ row }) => {
      return (
        <>
          <IconButton
            onClick={() => {
              setSelected({ value: row.original, type: FORM_ACTION.UPDATE })
            }}
            size={'small'}
            style={{ margin: '0 8px', color: '#1976d2' }}
          >
            <i className='fas fa-edit' />
          </IconButton>
          <IconButton
            onClick={() => {
              setSelected({ value: row.original, type: FORM_ACTION.REMOVE })
            }}
            size={'small'}
            style={{ margin: '0 8px', color: 'red' }}
          >
            <i className='fas fa-trash' />
          </IconButton>
        </>
      )
    },
    disableSortBy: true,
    disableFilters: true,
  },
])
