import React, { useEffect } from 'react'
import { Route, Outlet } from 'react-router'
import { Routes } from 'react-router-dom'
import Login from 'components/Login/Login'
import { PrivateRoute } from 'hoc/PrivateRoute'
import withSuspense from 'hoc/withSuspense'
import { Dashboard } from 'pages/Dashboard'
import { MobileDashboard } from 'pages/Dashboard/mobile'

import AwsCallback from 'pages/AwsCallback/AwsCallback'
import TopDeals from 'pages/TopDeals/TopDeals'
import BotHistory from 'pages/BotHistory/BotHistory'
import BotSignals from 'pages/BotSignals/BotSignals'
import BotOpportunities from 'pages/BotOpportunities/BotOpportunities'
import Bookkeeping from 'pages/Bookkeeping/Bookkeeping'
import Nonce from 'pages/Nonce/Nonce'
import { Deployments } from 'pages/Deployments'
import { Exchanges } from 'pages/Exchanges'
import { MarketVolatility } from 'pages/MarketVolatility'
import { Balances } from 'pages/Balances'
import { Networks } from 'pages/Networks'
import { SeekerRules } from 'pages/SeekerRules'
import { DexTokens } from 'pages/DexTokens'
import { Allowances } from 'pages/Allowances'
import DailyProfit from 'pages/DailyProfit/DailyProfit'
import ArchivedBots from 'pages/ArchivedBots/ArchivedBots'
import BalanceLeveling from 'pages/BalanceLeveling/BalanceLeveling'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { useRefreshToken } from 'hooks/use-init'
import { useLocalStorage } from 'hooks/use-local-storage'
import Preloader from 'components/Preloader/Preloader'
import BotsList from 'pages/BotDetails/BotsList'
import BotDetails from 'pages/BotDetails/BotDetails'
import CexFirst from 'pages/CexFirst/CexFirst'
import { useExchangesApi } from 'pages/Exchanges/hooks'
import { useNetworksApi } from 'pages/Networks/hooks'
import { settingsActions } from 'redux/reducers/settings-reducer'

const BrokersTable = React.lazy(() => import('components/BrokersTable/BrokersTable'))
const SuspendedBrokersTable = withSuspense(BrokersTable)

const PreloadData = () => {
  const dispatch = useDispatch()
  const [exchanges] = useExchangesApi()
  const [networks] = useNetworksApi()

  useEffect(() => {
    if (exchanges.data && networks.data) {
      dispatch(settingsActions.setInitialized(true))
    }
  }, [exchanges.data, networks.data, dispatch])

  return null
}

const RefreshToken = () => {
  const initialized = useSelector((state) => state.settings.initialized)

  useRefreshToken()
  const [isAuth] = useLocalStorage('authenticated', false)

  if (isAuth && !initialized) {
    return <PreloadData />
  }

  if (!initialized) {
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <Preloader />
      </div>
    )
  }

  return <Outlet />
}

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path='/callback/*' element={<AwsCallback />} />
      <Route path='/token-callback/*' element={<AwsCallback />} />
      <Route path='login/*' element={isMobile ? <Login /> : <Login />} />
      <Route path='/' element={<PrivateRoute><RefreshToken /></PrivateRoute>}>
        <Route index element={isMobile ? <PrivateRoute><MobileDashboard /></PrivateRoute> : <PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path='settings/*' element={<PrivateRoute><Nonce /></PrivateRoute>} />
        <Route path='brokers/*' element={<PrivateRoute><SuspendedBrokersTable /></PrivateRoute>} />
        <Route path='top-deals/*' element={<PrivateRoute><TopDeals /></PrivateRoute>} />
        <Route path='bot-history/:botId' element={<PrivateRoute><BotHistory /></PrivateRoute>} />
        <Route path='bot-signals/*' element={<PrivateRoute><BotSignals /></PrivateRoute>} />
        <Route path='bot-opportunities' element={<PrivateRoute><BotOpportunities /></PrivateRoute>} />
        <Route path='daily-profit/*' element={<PrivateRoute><DailyProfit /></PrivateRoute>} />
        <Route path='bookkeeping/*' element={<PrivateRoute><Bookkeeping /></PrivateRoute>} />
        <Route path='archived-bots/*' element={<PrivateRoute><ArchivedBots /></PrivateRoute>} />
        <Route path='balance-leveling/*' element={<PrivateRoute><BalanceLeveling /></PrivateRoute>} />
        <Route path='balances' element={<PrivateRoute><Balances /></PrivateRoute>} />
        <Route path='deployments' element={<PrivateRoute><Deployments /></PrivateRoute>} />
        <Route path='exchanges' element={<PrivateRoute><Exchanges /></PrivateRoute>} />
        <Route path='networks' element={<PrivateRoute><Networks /></PrivateRoute>} />
        <Route path='seeker-rules' element={<PrivateRoute><SeekerRules /></PrivateRoute>} />
        <Route path='dex-tokens' element={<PrivateRoute><DexTokens /></PrivateRoute>} />
        <Route path='allowances' element={<PrivateRoute><Allowances /></PrivateRoute>} />
        <Route path='market-volatility' element={<PrivateRoute><MarketVolatility /></PrivateRoute>} />
        <Route path="bot-details/*">
          <Route index element={<PrivateRoute><BotsList /></PrivateRoute>} />
          <Route path=":botId" element={<PrivateRoute><BotDetails /></PrivateRoute>} />
        </Route>
        <Route path='cex-first' element={<PrivateRoute><CexFirst /></PrivateRoute>} />
      </Route>
      <Route path='*' element={<h1>404. Page not found.</h1>} />
    </Routes>
  )
}

export default RoutesComponent
