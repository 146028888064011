export const getAddressPage = (networks, network, address) => {
  const networkPage = networks?.[network]?.block_explorer_url

  if (!address) {
    return networkPage
  }

  return `${networkPage}/address/${address}`.replace(/([^:]\/)\/+/g, "$1")
}

export const getAddressPageByNetwork = (network, address) => {
  const networkPage = network?.block_explorer_url

  if (!address) {
    return networkPage
  }

  return `${networkPage}/address/${address}`.replace(/([^:]\/)\/+/g, "$1")
}
