import { useQuery } from '@tanstack/react-query'
import { redirectToCognitoPage } from 'api/auth-api'

import { fetch } from './'

export const balanceLevelingApi = {
  getLevelingRules: (page, pageSize, filters) => {
    return fetch
      .get(`leveling-rules`, { searchParams: { page, pageSize, ...filters } })
      .json()
      .catch((err) =>
        console.log(err)
      ) || []
  },
  updateRule: (rule) => {
    return fetch
      .post(`leveling-rule/${rule.id}`, { json: rule })
      .json()
  },
  createRule: (rule) => {
    return fetch
      .post(`leveling-rule`, { json: rule })
      .json()
  },
  enableRule: (ruleId) => {
    return fetch
      .post(`leveling-rule/${ruleId}/enable`)
      .json()
  },
  disableRule: (ruleId) => {
    return fetch
      .post(`leveling-rule/${ruleId}/disable`)
      .json()
  },
  enableMonitoring: (ruleId) => {
    return fetch
      .put(`monitoring-bl-status/${ruleId}/enable`)
      .json()
  },
  disableMonitoring: (ruleId) => {
    return fetch
      .put(`monitoring-bl-status/${ruleId}/disable`)
      .json()
  },
  listErrors: () => {
    return fetch
      .get(`list-bl-errors`)
      .json()
      .catch((err) =>
        console.log(err)
      ) || []
  },
}

export const useLevelingRules = () => {
  const query = useQuery({
    queryKey: ['leveling-rules'],
    queryFn: async () => {
      const data = await balanceLevelingApi.getLevelingRules(1, 5000)

      if (data.status === 401) {
        redirectToCognitoPage()

        return []
      }

      return Array.isArray(data) ? data : []
    },
    cacheTime: 60000 * 5, // 5 minutes
    staleTime: 60000 * 5, // 5 minutes
  })

  return query
}
