import ky from 'ky'

import * as qs from 'qs'
import { getIsAuth } from 'storage/user-storage'

import { generateCodeChallenge, generateCodeVerifier } from '../utilities/encoding'
import config from '../config/api'

const cognitoInstance = ky.extend({
  prefixUrl: config.cognito.provider,
})

const fetchToken = (data) => {
  return cognitoInstance
    .post('oauth2/token', {
      body: qs.stringify(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .catch(e => console.log(e))
}

export const redirectToCognitoPage = (action = 'login', skipRedirect = false) => {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = generateCodeChallenge(codeVerifier)

  sessionStorage.clear()
  localStorage.removeItem('token')
  localStorage.removeItem('refresh')
  localStorage.removeItem('lastAuth')
  localStorage.removeItem('expiresIn')
  localStorage.removeItem('username')
  localStorage.setItem('authenticated', 'false')
  localStorage.setItem('codeVerifier', codeVerifier)
  localStorage.setItem('codeChallenge', codeChallenge)

  const { provider, clientId, redirectUri } = config.cognito
  const host = `${provider}/${action}`
  const params = qs.stringify({
    response_type: 'code',
    client_id: clientId,
    redirect_uri: redirectUri,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  })
  const authURL = `${host}?${params}`

  if (!skipRedirect) {
    window.location.replace(authURL)
  }

  return authURL
}

export const Logout = () => {
  console.log('logout')
  redirectToCognitoPage('login')

  return null
}

export async function awsCallTokenEndpoint(grantType, accessToken, codeVerifier) {
  const data = {
    grant_type: grantType,
    client_id: config.cognito.clientId,
    client_secret: config.cognito.clientSecret,
    code: accessToken,
    scope: 'funbot/arbitrage.ui',
    redirect_uri: config.cognito.redirectUri,
    code_verifier: codeVerifier,
  }

  const awsResponse = await fetchToken(data)

  if (awsResponse.status === 200) {
    return awsResponse.json()
  }
}

export async function awsCallTokenRefresh(grantType, refreshToken) {
  const data = {
    grant_type: grantType,
    client_id: config.cognito.clientId,
    refresh_token: refreshToken,
  }

  const awsResponse = await fetchToken(data)

  if (awsResponse.status === 200) {
    return awsResponse.json()
  }
}

const getUserInfo = (token) => {
  return ky
    .post(
      'https://cognito-idp.eu-west-1.amazonaws.com/',
      {
        json: { AccessToken: token },
        headers: {
          'X-Amz-target': 'AWSCognitoIdentityProviderService.GetUser',
          'Content-Type': 'application/x-amz-json-1.1',
        },
      }
    )
    .json()
}

export const getAwsToken = async (requestCallback) => {
  const data = await requestCallback()
  const userInfo = await getUserInfo(data?.access_token)

  if (!data || !userInfo) {
    return awsLogout()
  }

  const username =
    userInfo?.UserAttributes?.find((attr) => attr.Name === 'name')?.Value ||
    userInfo?.UserAttributes?.find((attr) => attr.Name === 'email')?.Value

  localStorage.setItem('token', data.access_token)
  if (data && data.refresh_token) localStorage.setItem('refresh', data.refresh_token)
  localStorage.setItem('authenticated', 'true')
  localStorage.setItem('lastAuth', JSON.stringify(Number(new Date())))
  localStorage.setItem('expiresIn', JSON.stringify(data.expires_in * 1000))
  localStorage.setItem('username', username)
}

export const awsLogout = () => {
  redirectToCognitoPage('logout')
}

export const isAuthExpired = () => {
  const isAuth = getIsAuth()

  if (!isAuth) {
    return false
  }

  const lastAuth = localStorage.getItem('lastAuth')
  const expiresIn = localStorage.getItem('expiresIn')

  return new Date() - lastAuth > expiresIn - 300000
}
