import { fetch } from './'

export const dexExecutionApi = {
  getTokens: (networkId) => {
    return fetch
      .get(`dex-tokens?network_id=${networkId}`)
      .json()
      .then((data) => data || [])
  },
  saveToken: (tokenId, token) => {
    return fetch
      .post(tokenId ? `dex-tokens/${tokenId}` : 'dex-tokens', { json: token })
      .then(async response => {
        if (response.status !== 200) {
          throw new Error((await response.json()).error, { code: response.status })
        }

        return response
      })
  },
  enableToken: (tokenId) => {
    return fetch
      .post(`dex-tokens/${tokenId}/enable`)
      .then(async response => {
        console.log(response)
        if (response.status !== 200) {
          throw new Error((await response.json()).error, { code: response.status })
        }

        return response
      })
  },
  disableToken: (tokenId) => {
    return fetch
      .post(`dex-tokens/${tokenId}/disable`)
      .then(async response => {
        if (response.status !== 200) {
          throw new Error((await response.json()).error, { code: response.status })
        }

        return response
      })
  },
  removeToken: (tokenId) => {
    return fetch
      .delete(`dex-tokens/${tokenId}`)
      .then(async response => {
        if (response.status !== 200) {
          throw new Error((await response.json()).error, { code: response.status })
        }

        return response
      })
  },
  getPairs: (networkId) => {
    return fetch
      .get(`dex-pairs?network_id=${networkId}`)
      .json()
      .then((data) => data || [])
  },
  savePair: (pairId, pair) => {
    return fetch
      .post(pairId ? `dex-pairs/${pairId}` : 'dex-pairs', { json: pair })
      .then(async response => {
        if (response.status !== 200) {
          throw new Error((await response.json()).error, { code: response.status })
        }

        return response
      })
  },
  enablePair: (pairId) => {
    return fetch
      .post(`dex-pairs/${pairId}/enable`)
      .then(async response => {
        if (response.status !== 200) {
          throw new Error((await response.json()).error, { code: response.status })
        }

        return response
      })
  },
  disablePair: (pairId) => {
    return fetch
      .post(`dex-pairs/${pairId}/disable`)
      .then(response => {
        if (response.code !== 200) {
          return new Error(response.error, response.code)
        }

        return response
      })
  },
  removePair: (pairId) => {
    return fetch
      .delete(`dex-pairs/${pairId}`)
      .then(response => {
        if (response.code !== 200) {
          return new Error(response.error, response.code)
        }

        return response
      })
  },
  setNonce: (nonce) => {
    return fetch
      .post(`set-nonce`, { searchParams: nonce })
      .then(async response => {
        if (response.status !== 200) {
          throw new Error((await response.json()).error, { code: response.status })
        }

        return response
      })
  },
  allowances: (searchParams) => {
    return fetch
      .get(`allowances`, { searchParams })
      .json()
      .catch((err) => console.log(err))
  },
}

export default dexExecutionApi
