import { useCallback, useEffect, useState, useMemo } from 'react'
import { Paper, Typography } from '@mui/material'
import Button from 'components/Button/Button'
import moment from 'moment'
import Table from 'components/Table/Table'
import botAnalyticsApi from 'api/bot-analytics'
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'

import s from './DetailedDailyProfit.module.css'
import { COLUMNS } from './columns'

const parseData = (raw) => {
  const store = {}
  const storeN = {}

  raw?.flatMap((profit) =>
    profit.records.forEach((el) => {
      if (el.botId !== '-') {
        const item = {
          botId: el.botId,
          pair: el?.dexPair,
          profitUSD: el?.profit ? +el.profit : 0,
          profitPercent: el?.profitPct,
          directionProfit: el.directionProfit,
        }

        if (store[el.botId] && el.profit) {
          let n = storeN[el.botId]

          store[el.botId].profitUSD += +el.profit

          if (el?.profitPct) {
            store[el.botId].profitPercent = (store[el.botId].profitPercent * n + el.profitPct) / (n + 1)

            storeN[el.botId] += 1
          }
        } else {
          store[item.botId] = item
          storeN[el.botId] = 1
        }
      }
    })
  )

  return Object.values(store)
}

const DetailedDailyProfit = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [period, setPeriod] = useState({
    startDate: moment().subtract(1, 'M').startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    key: 'selection',
  })

  const openRangePicker = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeRangePicker = () => {
    setAnchorEl(null)
  }

  const handleRangeChange = (newValue) => {
    setPeriod(newValue.selection)
  }

  const handleConfirmRange = () => {
    fetchPeriod(period.startDate, period.endDate)
    closeRangePicker()
  }

  const fetchData = useCallback(async (startTime, endTime) => {
    setIsFetching(true)

    const response = await botAnalyticsApi.getDailyProfitByPeriod(startTime, endTime)

    if (response?.length > 0) {
      setData(parseData(response))
    } else {
      setData([])
    }

    setIsFetching(false)
  }, [])

  const fetchPeriod = useCallback(
    async (dirtyStartTime, dirtyEndTime) => {
      const startTime = moment(dirtyStartTime).format('YYYY-MM-DD')
      const endTime = moment(dirtyEndTime).format('YYYY-MM-DD')

      await fetchData(startTime, endTime)
    },
    [fetchData]
  )

  useEffect(() => {
    fetchPeriod(period.startDate, period.endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const datePickerLabel = useMemo(() => {
    const start = moment(period.startDate).format('DD/MM/yyyy')
    const end = moment(period.endDate).format('DD/MM/yyyy')

    return `from: ${start} to: ${end}`
  }, [period])

  return (
    <Paper className={s.paper + ' flex-column'} elevation={2}>
      <div className={s.heading}>
        <Typography variant={'h5'} className={s.rangeButtons}>
          Daily Profit Analytics
          <div className='flex-row flex-wrap'>
            <Button
              onClick={openRangePicker}
            >
              {datePickerLabel}
            </Button>
          </div>
          <DateRangePicker
            anchorEl={anchorEl}
            value={period}
            handleChange={handleRangeChange}
            handleConfirm={handleConfirmRange}
            handleClose={closeRangePicker}
          />
        </Typography>
      </div>
      <Table data={data} columns={columns} loading={isFetching} />
    </Paper>
  )
}

export default DetailedDailyProfit
