import { useState, useMemo } from 'react'
import Paper from '@mui/material/Paper'
import styled from '@emotion/styled'

import Table from 'components/Table/Table'
import { Tabs } from 'components/Tabs/Tabs'
import { Pagination, usePagination } from 'components/Pagination'

import { COLUMNS } from './columns'

import { useMarketVolatilityApiApi, PERIODS } from './hooks'

const Container = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
  width: 100%;
  max-width: 620px;
`;

export const MarketVolatility = () => {
  const {
    page,
    increment,
    decrement,
    pageSize,
    changePageSize,
    setStartPage,
    tableRef,
    isLastPage,
    setLastPage,
  } = usePagination({ name: 'blRulesPageSize', pages: [100, 500, 1000, 5000] })
  const [period, setPeriod] = useState(PERIODS[0].id)
  const tabs = useMemo(() => PERIODS.map(period => ({ ...period, onClick: () => {
    setPeriod(period.id)
    setStartPage()
  } })), [setPeriod, setStartPage])
  const { data, loading } = useMarketVolatilityApiApi({ page, pageSize, period }, { setLastPage })
  const columns = COLUMNS

  return (
    <Container elevation={2} id='marketVolatility_table'>
      <Tabs
        tabs={tabs}
        activeTab={period}
      />
      <Table
        data={data}
        columns={columns}
        loading={loading}
        initialState={{
          sortBy: [{ id: 'points', desc: true }],
        }}
        enableFilters
      />
      <Pagination
        page={page}
        pages={[100, 500, 1000, 5000]}
        increment={increment}
        decrement={decrement}
        pageSize={pageSize}
        changePageSize={changePageSize}
        tableRef={tableRef}
        isLastPage={isLastPage}
      />
    </Container>
  )
}
