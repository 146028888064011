import Select from 'react-select'
import { useEffect, useState, useRef } from 'react'
import Button from 'components/Button/Button'
import { Alert, Snackbar } from '@mui/material'
import settingsApi from 'api/settings-api'

const BotForm = () => {
  const [tag, setTag] = useState()
  const [value, setValue] = useState('')
  const [tagOptions, setTagOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const inputRef = useRef(null)

  useEffect(() => {
    ; (async () => {
      setIsLoading(true)

      const data = await settingsApi.getTagImages()

      setTagOptions(data)

      setIsLoading(false)
    })()
  }, [])

  const handleTagChange = (newTag) => {
    setTag(newTag)

    setValue(tagOptions.find((t) => t.Key === newTag.Key).Value)
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      await settingsApi.updateTagImage(tag.Key, value)

      setPopup((prev) => ({
        ...prev,
        open: true,
        message: 'Tag saved!',
        severity: 'success',
      }))
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `${err}`, severity: 'error' }))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleUpdateBotsVersion = async () => {
    setIsSubmitting(true)

    try {
      await settingsApi.updateBotsVersion()

      setPopup((prev) => ({
        ...prev,
        open: true,
        message: 'Bots version updated!',
        severity: 'success',
      }))
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `${err}`, severity: 'error' }))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handlePopupClose = () => setPopup((prev) => ({ ...prev, open: false }))

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto'
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [value])

  return (
    <div>
      <div className={'flex-row flex-wrap'} style={{ gap: 12 }}>
        <div style={{ width: '200px' }}>
          <Select
            autoSize
            menuPlacement='auto'
            menuPosition='fixed'
            name='Tag'
            options={tagOptions}
            value={tag}
            getOptionLabel={(x) => x.Key}
            getOptionValue={(x) => x.Key}
            onChange={handleTagChange}
            cacheOptions
            defaultOptions
            placeholder={'Choose tag'}
            styles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            id='tag_select'
            isLoading={isLoading}
          />
        </div>
      </div>
      <textarea
        style={{
          minHeight: '100px', // Minimum height
          minWidth: '400px', // Minimum width
          maxHeight: '60vh', // Maximum height
          maxWidth: '60vw', // Maximum width
          height: 'auto',
          width: '100%', // Adjust to 100% to fit the container
          overflow: 'auto',
          marginTop: '12px',
          fontFamily: 'inherit',
          fontSize: 'inherit',
          padding: '8px',
          borderRadius: '4px',
          borderColor: '#ccc',
        }}
        value={value}
        onChange={handleChange}
        ref={inputRef}
        id='value_input_field'
        disabled={isLoading}
      />
      <div style={{ display: 'flex', gap: '8px', marginTop: '12px' }}>
        <Button onClick={handleSubmit} disabled={isSubmitting} color='primary' id='update_bot_button'>
          Save
        </Button>
        <Button onClick={handleUpdateBotsVersion} disabled={isSubmitting} color='secondary' id='update_bot_version_button'>
          Update All Bots
        </Button>
      </div>
      <Snackbar
        open={popup.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handlePopupClose}
      >
        <Alert onClose={handlePopupClose} severity={popup.severity}>
          <div className='column' style={{ whiteSpace: 'pre-wrap' }}>
            {popup.message}
          </div>
        </Alert>
      </Snackbar>
    </div>
  )
}

export default BotForm