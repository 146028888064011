import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'

import ArbitrageTable from '../Home/components/ArbitrageTable/ArbitrageTable'
import topDealsAPI from '../../api/top-deals-api'
import Preloader from '../../components/Preloader/Preloader'

import s from './TopDeals.module.css'

const TopDeals = () => {
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)

      const data = await topDealsAPI.getTopDeals()

      if (data) setData(data)

      setIsFetching(false)
    })()
  }, [])

  if (isFetching) return <Preloader style={{ marginTop: 20 }} />

  return (
    <Paper className={s.paper + ' flex-column'} elevation={2}>
      <Typography variant={'h5'}>Top Deals</Typography>
      <ArbitrageTable data={data} hiddenColumns={['actions']} />
    </Paper>
  )
}

export default TopDeals
