import styled from '@emotion/styled'
import { ConfirmButton } from 'components/Button/ConfirmButton'

const Container = styled.div`
  height: ${({ active }) => active ? '100%' : 0};
  transition: all .3s ease;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 20px;
  padding: ${({ active }) => active ? '5px 20px' : '0px 20px'};
  background: rgba(25, 118, 210, 0.2);
  box-sizing: border-box;
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
`

export const SelectAllActions = ({
  selectedCount,
  archiveSelected,
  enableSelected,
  disableSelected,
}) => {
  return (
    <Container active={selectedCount !== 0}>
      <ButtonContainer>
        <ConfirmButton label={`Enable (${selectedCount})`} text={`Enable ${selectedCount} bots?`} onClick={enableSelected}>Enable</ConfirmButton>
        <ConfirmButton label={`Disable (${selectedCount})`} text={`Disable ${selectedCount} bots?`} onClick={disableSelected}>Disable</ConfirmButton>
        <ConfirmButton label={`Archive (${selectedCount})`} text={`Archive ${selectedCount} bots?`} onClick={archiveSelected}>Archive</ConfirmButton>
      </ButtonContainer>
      <div>Selected {selectedCount} items</div>
    </Container>
  )
}
