import { Tooltip, Popover, IconButton, Paper } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

const DayProfitSection = ({ value, row }) => {
  const [anchorEl, setAcnchorEl] = useState(null)

  const handleClose = () => {
    setAcnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'detailed-profit' : undefined

  return (
    <>
      {Number(value).toFixed(2)}
      <Tooltip title='Profit across bots'>
        <IconButton onClick={(e) => setAcnchorEl(e.currentTarget)} size={'small'} style={{ margin: '0 8px' }}>
          <i className='fas fa-angle-down' />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper className='column' style={{ padding: 8 }}>

          {row.original.records.sort(function (a, b) {
            return b.period_value - a.period_value
          }) && row.original.records.map((r) => (
            <div key={r.bot_id} id='profit_across_bots'>
              <b>Bot #{r.bot_id}: </b>
              {Number(r.period_value).toFixed(2)}
            </div>
          ))}
        </Paper>
      </Popover>
    </>
  )
}

export const COLUMNS = [
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ value }) => <span>{moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('YYYY-MM-DD')}</span>,
  },
  {
    Header: 'Profit',
    accessor: 'value',
    sortType: 'alphanumericCustom',
    Cell: DayProfitSection,
  },
]
