import { BotTable, ARCHIVED } from 'pages/Bots/BotTable'
import { useBotApi, usePagination } from 'pages/Bots/hooks'

const ArchivedBots = () => {
  const { page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize, tableRef } = usePagination({ name: 'archived-bot-table' })

  const {
    bots,
    isFetching,
    setBots,
    botsQuery,
    botsQueryKey,
    isLastPage,
  } = useBotApi(ARCHIVED, page, pageSize)

  return <BotTable
    botsType={ARCHIVED}
    data={bots}
    setData={setBots}
    botsQuery={botsQuery}
    botsQueryKey={botsQueryKey}
    isFetching={isFetching}
    pagination={{
      page,
      incrementPage,
      decrementPage,
      setStartPage,
      pageSize,
      changePageSize,
      tableRef,
      isLastPage,
    }}
  />
}

export default ArchivedBots
