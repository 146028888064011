import BigNumber from 'bignumber.js'
import { Tooltip } from '@mui/material'
import { getAddressPage } from 'utilities/get-address-page'

import { CoinCell, FlexCell } from './styles'

const WalletLink = ({ href, wallet }) => (
  <a href={href} target="_blank" rel="noreferrer">
    {wallet}
  </a>
)

export const getColumns = (brokers, availableNetworks, brokerWallets) => [
  {
    Header: '',
    accessor: 'name',
    sticky: 'left',
    Cell: ({ value }) => <CoinCell><strong>{value}</strong></CoinCell>,
  },
  ...brokers.map(broker => ({
    Header: () => {
      if (!brokerWallets[broker]) {
        return <CoinCell>{broker}</CoinCell>
      }

      return (

        <Tooltip
          title={<WalletLink wallet={brokerWallets[broker]} href={getAddressPage(availableNetworks, broker, brokerWallets[broker])} />}
          arrow
          interactive
          placement="bottom"
        >
          <CoinCell>
            {broker}
          </CoinCell>
        </Tooltip>
      )
    },
    accessor: broker,
    sortType: 'sortBalance',
    Cell: ({ value }) => {
      if (value?.balanceUsdtBigNumber) {
        return (
          <Tooltip title={value.balanceUsdtBigNumber.toFixed()} arrow placement="bottom-end">
            <CoinCell>{value.balanceUsdtBigNumber.toFixed(0)}</CoinCell>
          </Tooltip>
        )
      }

      if (value?.balanceUsdt) {
        return (
          <Tooltip title={value.balanceUsdt} arrow placement="bottom-end">
            <FlexCell>
              <div className='green'>{new BigNumber(value.balanceUsdt).toFixed(0)}</div>
              <div className='red'>{
                // eslint-disable-next-line eqeqeq
                value.reserved != 0 ? new BigNumber(value.reserved).toFixed(2) : 0
              }</div>
            </FlexCell>
          </Tooltip>
        )
      }

      return '-'
    },
  })),
  {
    Header: 'TOTAL',
    accessor: 'total',
    sortType: 'alphanumericFalsyLast',
    Cell: ({ value }) => {
      return (
        <Tooltip title={value?.toFixed()}>
          <CoinCell>{value?.toFixed(0)}</CoinCell>
        </Tooltip>
      )
    },
  },
]
