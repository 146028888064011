import { getIsAuth } from 'storage/user-storage'
import { Logout } from 'api/auth-api'

export const PrivateRoute = ({ children }) => {
  const isAuth = getIsAuth()

  if (isAuth) {
    return children
  }

  return <Logout />
}
