import { useCallback, useState } from 'react'
import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { toggleBotRule, useBotData, updateBotRule } from 'api/bot-api'

import { RuleDialog } from './components/RuleDialog'
import { useBotBalanceLevelingApi } from './hooks'

export const BalanceLeveling = ({ botId, botsQueryKey }) => {
  const botData = useBotData(botId, botsQueryKey)
  const leveling = botData?.leveling_rules || []

  const [rule, setRuleForm] = useState()
  const { updateRule, enableRule, disableRule } = useBotBalanceLevelingApi()

  const toggleRule = useCallback(
    async (event) => {
      const flag = event.target.checked

      if (flag) {
        await enableRule(event.target.name)
      } else {
        await disableRule(event.target.name)
      }

      toggleBotRule(botId, event.target.name, flag, botsQueryKey)
    },
    [botId, botsQueryKey, enableRule, disableRule]
  )

  const save = useCallback(async (data) => {
    const rule = await updateRule(data)

    if (rule) {
      updateBotRule(botId, rule, botsQueryKey)
    }
  }, [botId, botsQueryKey, updateRule])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Coin</TableCell>
            <TableCell align='right'>Direction</TableCell>
            <TableCell align='right'>Amount</TableCell>
            <TableCell align='right'>Min threshold</TableCell>
            <TableCell align='right'>Period</TableCell>
            <TableCell align='right'>Status</TableCell>
            <TableCell align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leveling.map((rule) => (
            <TableRow key={rule.id}>
              <TableCell>{rule.id}</TableCell>
              <TableCell>{rule.coin}</TableCell>
              <TableCell align='right'>From {rule.from}</TableCell>
              <TableCell align='right'>{rule.amount}</TableCell>
              <TableCell align='right'>{rule.min_threshold}</TableCell>
              <TableCell align='right'>{rule.period}</TableCell>
              <TableCell align='right'>
                <Switch name={`${rule.id}`} checked={rule.enabled} onChange={toggleRule} />
              </TableCell>
              <TableCell align='right'>
                <IconButton
                  onClick={() => setRuleForm(rule)}
                  size={'small'}
                  style={{ margin: '0 8px', color: '#1976d2' }}
                >
                  <i className='fas fa-edit' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {leveling.length === 0 && (
        <Box sx={{ width: '100%', textAlign: 'center', padding: 20 }}>No rules</Box>
      )}
      <RuleDialog value={rule} onClose={() => setRuleForm()} save={save} />
    </TableContainer>
  )
}
