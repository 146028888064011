import Dialog from '@mui/material/Dialog'

import { Seeker } from './Seeker'

export const RuleDialog = ({ onClose, value, create, remove, update }) => {
  const { _type, ...rule } = value || {}

  return (
    <Dialog onClose={onClose} open={!!value}>
      <Seeker
        type={_type}
        rule={rule}
        close={onClose}
        remove={remove}
        create={create}
        update={update}
      />
    </Dialog>
  )
}
