import { useQuery } from '@tanstack/react-query'
import { fetch } from './'

const botAnalyticsApi = {
  getDailyProfitByPeriod: (startTime, endTime) => {
    return fetch
      .get(`profit-period`, {
        searchParams: {
          start_time: startTime,
          end_time: endTime,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
}

export const useDailyProfitQuery = (period) => {
  return useQuery({
    queryKey: ['dailyProfit', period?.startTime, period?.endTime],
    queryFn: () => botAnalyticsApi.getDailyProfitByPeriod(period?.startTime, period?.endTime),
    enabled: !!period,
    placeholderData: [],
    select: (data) => data || [],
    cacheTime: 1000 * 60 * 60 * 4, // 4 hours
    staleTime: 1000 * 60 * 60 * 4, // 4 hours
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  })
}

export default botAnalyticsApi
