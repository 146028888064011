import Dialog from '@mui/material/Dialog'

import { Network } from './Network'

export const NetworkDialog = ({ onClose, value, save, remove }) => {
  const { _type, ...network } = value || {}

  return (
    <Dialog onClose={onClose} open={!!value}>
      <Network
        type={_type}
        network={network}
        close={onClose}
        remove={remove}
        save={save}
      />
    </Dialog>
  )
}
