import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import { useForm, Controller } from "react-hook-form"

import { useCallback } from 'react'

const FIELD_OPTIONS = {
  required: true,
  validate: (v) => typeof v === 'string' ? v.trim().length > 0 : v !== null && v !== undefined,
}

export const Rule = ({ type, rule, save, close }) => {
  const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: { ...rule } });
  const onSubmit = useCallback(async (data) => {
    await save(data)

    close()
  }, [save, close])

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
    >
      <Card sx={{ maxWidth: 310 }}>
        <CardContent>
          <Controller
            name="bot_id"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="Bot ID"
                error={!!errors.bot_id}
                helperText={errors.bot_id && "bot_id is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="amount"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="Amount"
                error={!!errors.amount}
                helperText={errors.amount && "amount is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="min_threshold"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="Min threshold"
                error={!!errors.min_threshold}
                helperText={errors.min_threshold && "min_threshold is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="period"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="Period"
                error={!!errors.period}
                helperText={errors.period && "period is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="max_fee"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="Max fee"
                error={!!errors.max_fee}
                helperText={errors.max_fee && "max_fee is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="from"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="From"
                error={!!errors.from}
                helperText={errors.from && "from is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="to"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="To"
                error={!!errors.to}
                helperText={errors.to && "to is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="coin"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="Token"
                error={!!errors.coin}
                helperText={errors.coin && "coin is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="dex_wallet"
            control={control}
            render={({ field }) =>
              <TextField
                label="Dex Wallet"
                {...field}
              />
            }
          />
          <Controller
            name="network"
            control={control}
            render={({ field }) =>
              <TextField
                label="Network"
                {...field}
              />
            }
          />
        </CardContent>
        <CardActions>
          <Button size="small" color='primary' type="submit" id='submit_button'>{type === 'create' ? 'Create' : 'Update'}</Button>
          <Button size="small" onClick={close} id='cancel_button'>Cancel</Button>
        </CardActions>
      </Card>
    </Box>
  )
}
