import { useQuery } from '@tanstack/react-query'
import { pickBy } from 'lodash'
import { fetch } from './'
import { REFETCH_INTERVAL } from 'config/constants'

export const balancesApi = {
  getBalances: (network) => {
    return fetch
      .get(`get-balances`, network ? { searchParams: { network } } : {})
      .json()
      .catch((err) => console.log(err))
  },
  getBalance: () => {
    return fetch
      .get(`balance`)
      .json()
      .catch((err) => console.log(err))
  },
  getExpectedBalance: () => {
    return fetch
      .get(`expected-balance`)
      .json()
      .catch((err) => console.log(err))
  },
  getBalanceLeveling: (page, pageSize, sortBy, filter, globalFilters) => {
    const sortedField = sortBy?.id
    const sortedDirection = sortBy?.desc == null ? undefined : sortBy?.desc ? 'desc' : 'asc'
    const filteredField = filter?.id
    const filteredValue = filter?.value
    const params = {
      sortedField,
      sortedDirection,
      filteredField,
      filteredValue,
    }

    return fetch
      .get(`balance-leveling`, {
        searchParams: {
          page,
          pageSize,
          ...globalFilters,
          ...pickBy(params),
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
  setExpectedBalance: ({ name, expected }) => {
    return fetch
      .post(`expected-balance`, { json: { name, expected } })
      .json()
      .catch((err) => console.log(err))
  },
}

export default balancesApi

export const useBalances = () => {
  return useQuery({
    queryKey: ['balances'],
    queryFn: async () => {
      const data = await balancesApi.getBalances()

      return data
    },
    staleTime: 10 * 1000,
    cacheTime: 10 * 1000,
    refetchInterval: 10 * 1000,
  })
}

export const useBalance = () => {
  return useQuery({
    queryKey: ['balance'],
    queryFn: async () => {
      const data = await balancesApi.getBalance()

      return data
    },
    staleTime: REFETCH_INTERVAL,
    cacheTime: REFETCH_INTERVAL,
  })
}
