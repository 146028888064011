const TOGGLE_FILTER = 'globalFilters/toggle'
const SAVE_FILTER = 'globalFilters/save'
const SAVE_FILTERS = 'globalFilters/save-all'

const initialState = {}

const globalFiltersReducer = (state = initialState, action) => {
  if (!action) return state

  const { type, filter, value, filters } = action

  switch (type) {
    case SAVE_FILTERS: {
      return { ...filters }
    }
    case SAVE_FILTER: {
      return {
        ...state,
        [filter]: {
          value,
          filter,
          enabled: true,
        },
      }
    }
    case TOGGLE_FILTER: {
      return {
        ...state,
        [filter]: {
          ...state[filter],
          filter,
          enabled: !state[filter]?.enabled,
        },
      }
    }
    default:
      return state
  }
}

export default globalFiltersReducer

export const filtersActions = {
  toggleFilter: (filter) => ({ type: TOGGLE_FILTER, filter }),
  saveFilter: (filter, value) => ({ type: SAVE_FILTER, filter, value }),
  saveFilters: (filters) => ({ type: SAVE_FILTERS, filters }),
}
