import { useMemo, useEffect } from 'react'
import { useNetworksSelector } from 'redux/selectors/settings'

import { getColumns } from './columns'
import { useBalanceData } from './useBalanceData'

import { Container, StyledTable } from './styles'

const sortFns = {
  sortBalance: (rowA, rowB, columnId, desc) => {
    const a = rowA.values[columnId]
    const b = rowB.values[columnId]

    if (!a && !b) {
      return 0
    }

    if (!a) {
      return desc ? -1 : 1
    }

    if (!b) {
      return desc ? 1 : -1
    }

    if (a.name === 'TOTAL') {
      return 1
    }

    if (b.name === 'TOTAL') {
      return 1
    }

    return a.balanceUsdt - b.balanceUsdt
  },
}

export const Balances = () => {
  const availableNetworks = useNetworksSelector()
  const [balances, brokers, brokerWallets, isFetching, refetch] = useBalanceData(true)

  const columns = useMemo(() => getColumns(brokers, availableNetworks, brokerWallets), [brokers, availableNetworks, brokerWallets])

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <Container>
      <StyledTable
        title="Balances in USDT"
        data={balances}
        columns={columns}
        loading={isFetching}
        sortFns={sortFns}
        tableStyle={{ height: 'calc(100vh - 300px)' }}
        initialState={{
          sortBy: [{ id: 'total', desc: true }],
        }}
      />
    </Container>
  )
}
