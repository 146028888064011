import { Paper } from '@mui/material'
import Button from 'components/Button/Button'
import DetailedBookkeepingTable from 'pages/Bookkeeping/components/DetailedBookkeepingTable'
import { BotTable, UNARCHIVED } from 'pages/Bots/BotTable'
import { usePagination } from 'pages/Bots/hooks'
import { useNavigate, useParams } from 'react-router'
import { useBotById  } from "../../api/bot-api";

const BotDetails = () => {
  const { page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize, tableRef } = usePagination({
    name: 'detailed-bot-table',
  })

  const { botId } = useParams()
  const  { bots, botsQuery, setBots, exchanges, isFetching, botsQueryKey, filterBots } = useBotById({
    botId,
    pagination: { page, pageSize },
  });
  const isBotExist = bots.length
  const navigate = useNavigate()

  return (
    <Paper
      sx={{
        '& .MuiPaper-root': { width: '99vw', maxWidth: '99vw' },
      }}
    >
      <BotTable
        tableRef={tableRef}
        botsType={UNARCHIVED}
        data={bots}
        setData={setBots}
        isFetching={isFetching}
        botsQuery={botsQuery}
        botsQueryKey={botsQueryKey}
        exchanges={exchanges}
        setIsFetching={isFetching}
        filterBots={filterBots}
        pagination={{
          page,
          incrementPage,
          decrementPage,
          setStartPage,
          pageSize,
          changePageSize,
          tableRef,
        }}
        isFullTable={!botId}
      />
      {isBotExist && <DetailedBookkeepingTable detailed filterTableBy={{ field: 'requestId', value: Number(botId) }} />}
      {!isBotExist && (
        <Button variant={'contained'} color={'primary'} onClick={() => navigate(`/bot-details`)}>
          {'Return to Bots list'}
        </Button>
      )}
    </Paper>
  )
}

export default BotDetails
