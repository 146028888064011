import { useCallback, useEffect, useState, useMemo } from 'react'
import { Paper, Typography } from '@mui/material'
import moment from 'moment'
import Table from 'components/Table/Table'
import useFullscreen from 'hooks/use-fullscreen'
import { useNetworksSelector } from 'redux/selectors/settings'
import { useSessionStorage } from 'hooks/use-session-storage'

import { useTransactionsApi, TODAY, YESTERDAY, RANGE } from '../hooks'

import s from './DetailedBookkeepingTable.module.css'
import { getColumns, COLUMNS } from './columns'
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'
import { Tabs } from 'components/Tabs/Tabs'
import { Profit } from './Profit'

const DetailedBookkeepingTable = ({ detailed, filterTableBy = null }) => {
  const availableNetworks = useNetworksSelector()
  const columns = useMemo(() => detailed ? getColumns(availableNetworks) : COLUMNS, [detailed, availableNetworks])
  const [data, profit, isFetching, fetchSelectedPeriod] = useTransactionsApi(availableNetworks, filterTableBy)
  const [datePickerAnchor, setDatePickerAnchor] = useState(null)
  const [currentRange, setCurrentRange] = useSessionStorage('detailedBookkeepingCurrentRange', 'Last30Mins')

  const [period, setPeriod] = useSessionStorage('detailedBookkeepingPeriod', {
    startDate: moment().subtract(30, 'm').toISOString(),
    endDate: moment().toISOString(),
    key: 'selection',
  })

  const changePeriod = useCallback(
    (newValue) => {
      const selection = newValue.selection || newValue.range1

      if (selection) {
        setPeriod({
          startDate: moment(selection.startDate).startOf('day').toISOString(),
          endDate: moment(selection.endDate).endOf('day').toISOString(),
          key: selection.key,
        })
      }
    },
    [setPeriod]
  )

  const openRangePicker = useCallback(
    (event) => {
      setDatePickerAnchor(event.currentTarget)
    },
    [setDatePickerAnchor]
  )

  const handleConfirmRange = useCallback(() => {
    setDatePickerAnchor(null)
    fetchSelectedPeriod(period)
    setCurrentRange(RANGE)
  }, [fetchSelectedPeriod, period, setCurrentRange, setDatePickerAnchor])

  useEffect(() => {
    if (currentRange === TODAY) {
      fetchSelectedPeriod({
        startTime: moment().startOf('day'),
        endTime: moment().endOf('day'),
      })

      return
    }

    if (currentRange === YESTERDAY) {
      fetchSelectedPeriod({
        startTime: moment().subtract(1, 'days').startOf('day'),
        endTime: moment().subtract(1, 'days').endOf('day'),
      })

      return
    }

    if (currentRange === RANGE) {
      fetchSelectedPeriod(period)

      return
    }

    fetchSelectedPeriod({
      startDate: moment().subtract(30, 'm'),
      endDate: moment(),
      key: 'selection',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useFullscreen()

  const datePickerLabel = useMemo(() => {
    if (currentRange !== RANGE) {
      return 'RANGE'
    }

    const start = moment(period.startDate).format('DD/MM/yyyy')
    const end = moment(period.endDate).format('DD/MM/yyyy')

    return `from: ${start} to: ${end}`
  }, [period, currentRange])

  const handleDateClick = useCallback(
    (e) => {
      setCurrentRange(e.currentTarget.name)

      if (e.currentTarget.name === TODAY) {
        fetchSelectedPeriod({
          startTime: moment().startOf('day'),
          endTime: moment().endOf('day'),
        })
      }

      if (e.currentTarget.name === YESTERDAY) {
        fetchSelectedPeriod({
          startTime: moment().subtract(1, 'days').startOf('day'),
          endTime: moment().subtract(1, 'days').endOf('day'),
        })
      }
    },
    [setCurrentRange, fetchSelectedPeriod]
  )

  const setLast30Mins = useCallback(() => {
    setPeriod({
      startDate: moment().subtract(30, 'm').toISOString(),
      endDate: moment().toISOString(),
      key: 'selection',
    })
    fetchSelectedPeriod({
      startDate: moment().subtract(30, 'm'),
      endDate: moment(),
    })
    setCurrentRange('Last30Mins')
  }, [setPeriod, fetchSelectedPeriod, setCurrentRange])

  const tabs = useMemo(() => {
    const arr = [
      {
        id: TODAY,
        label: TODAY,
        onClick: handleDateClick,
      },
      {
        id: YESTERDAY,
        label: YESTERDAY,
        onClick: handleDateClick,
      },
    ]

    if (detailed) {
      arr.push({
        id: RANGE,
        label: datePickerLabel,
        onClick: openRangePicker,
      })
    }

    arr.push({
      id: 'Last30Mins',
      label: 'Last 30\'',
      onClick: setLast30Mins,
    })

    if (!detailed) {
      arr.push({
        id: 'detailed',
        href: '/bookkeeping',
        label: 'Detailed View',
      })
    }

    return arr
  }, [handleDateClick, openRangePicker, datePickerLabel, detailed, setLast30Mins])

  return (
    <Paper className={s.paper + ' flex-column'} elevation={2} id='transactions_table'>
      <Typography variant={'h5'} className={s.heading}>
        Transactions
        <div className='flex-row justify-between' style={{ flex: 1 }}>
          <div>
            <Tabs tabs={tabs} activeTab={currentRange} />
          </div>
          <Profit profit={profit} />
        </div>
      </Typography>
      <Table
        initialState={{ sortBy: [{ id: 'transaction.createdAt', desc: true }] }}
        data={data}
        columns={columns}
        loading={isFetching}
        enableFilters
        groupField="groupId"
      />
      <DateRangePicker
        id='date-range'
        anchorEl={datePickerAnchor}
        handleConfirm={handleConfirmRange}
        value={period}
        handleChange={changePeriod}
      />
    </Paper>
  )
}

export default DetailedBookkeepingTable
