import { fetch } from '.'

export const seekerRulesApi = {
  list: (filters) => {
    return fetch
      .get(`seeker-rules`, { searchParams: filters })
      .json()
      .catch((err) => console.log(err))
  },
  create: (rule) => {
    return fetch
      .post('seeker-rules', { json: rule })
      .json()
  },
  update: ({ id, ...rule }) => {
    return fetch
      .post(`seeker-rules/${id}`, { json: rule })
      .json()
  },
  remove: (ruleId) => {
    return fetch
      .delete(`seeker-rules/${ruleId}`)
      .json()
  },
}

export default seekerRulesApi
