import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import SSwitch from '../Switch'
import { Box } from '@mui/material'

export const cellActions = (cellValue, handleActionsClick) => {
  var isOn = cellValue.row.enabled

  return (
    <Stack spacing={1} direction={{ sm: 'row', xs: 'row' }} justifyContent='center' alignItems='flex-start'>
      <Box sx={{ m: -10 }}>
        <Tooltip title={`Press to ${isOn ? 'Deactivate' : 'Activate'}`}>
          <span>
            <SSwitch actions={handleActionsClick} cell={cellValue} />
          </span>
        </Tooltip>
        <Tooltip title={'Archive'}>
          <span>
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                handleActionsClick.deleteRule(cellValue)
              }}
              size={'small'}
              style={{ margin: '0 8px' }}
              // disabled={cell.botsType === ARCHIVED}
            >
              <i className={`fas fa-archive blue`} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title='Edit'>
          <span>
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                handleActionsClick.editRuleOpen(cellValue)
              }}
              size={'small'}
              style={{ margin: '1px' }}
            >
              <i className='fas fa-pen' />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  )
}
