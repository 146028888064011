import { useState, useCallback, useEffect } from 'react'
import moment from 'moment'

import { marketVolatilityApi } from 'api/market-volatility-api'

export const PERIODS = [
  {
    id: 'today',
    label: 'TODAY',
    onClick: () => ({
      startTime: moment().utc().startOf('day').format('YYYY-MM-DD'),
      endTime: moment().utc().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    id: 'yesterday',
    label: 'YESTERDAY',
    onClick: () => ({
      startTime: moment().utc().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
      endTime: moment().utc().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    id: 'last3',
    label: 'LAST 3 DAYS',
    onClick: () => ({
      startTime: moment().utc().subtract(3, 'days').startOf('day').format('YYYY-MM-DD'),
      endTime: moment().utc().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    id: 'last7',
    label: 'LAST 7 DAYS',
    onClick: () => ({
      startTime: moment().utc().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
      endTime: moment().utc().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    id: 'last30',
    label: 'LAST 30 DAYS',
    onClick: () => ({
      startTime: moment().utc().subtract(30, 'days').startOf('day').format('YYYY-MM-DD'),
      endTime: moment().utc().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    id: 'last60',
    label: 'LAST 60 DAYS',
    onClick: () => ({
      startTime: moment().utc().subtract(60, 'days').startOf('day').format('YYYY-MM-DD'),
      endTime: moment().utc().endOf('day').format('YYYY-MM-DD'),
    }),
  },
]

const PERIODS_MAP = Object.fromEntries(PERIODS.map(period => ([period.id, period])))

export const useMarketVolatilityApiApi = (parameters, { setLastPage }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async (params) => {
    setLoading(true)
    const res = await marketVolatilityApi.list(params)

    setData(res)
    setLoading(false)
    setLastPage(res < params.pageSize)
  }, [setLastPage])

  useEffect(() => {
    const periodParams = PERIODS_MAP[parameters.period].onClick()

    fetch({ ...parameters, ...periodParams })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, parameters.page, parameters.pageSize, parameters.period])

  return { data, fetch, loading }
}
