import styled from '@emotion/styled'

export const TablesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  > div {
    width: calc(100% - 305px);
    flex-shrink: 1;
    flex-grow: 1;
  }
`

export const DailyProfitContainer = styled.div`
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  width: 300px !important;
  margin-left: 5px;
`
