import { useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import Table from 'components/Table/Table'
import { getColumns, DEFAULT_VALUES } from './columns'

import { Dialog } from '../Dialog'
import { CreateBtn } from '../Dialog/CreateBtn'

export const TokensTable = ({ tokens, selectedNetwork }) => {
  const [selected, setSelected] = useState(null)
  const columns = useMemo(() => getColumns(selectedNetwork, setSelected, tokens.toggle), [selectedNetwork, tokens.toggle])

  return (
    <>
      <Table
        title={<CreateBtn title="Tokens" openCreateForm={setSelected}  />}
        data={tokens.data}
        columns={columns}
        loading={tokens.loading}
        initialState={{
          sortBy: [{ id: 'name', desc: false }],
        }}
        enableFilters
      />
      <Dialog
        value={selected?.value}
        defaultValues={isEmpty(selected?.value) ? DEFAULT_VALUES : selected.value}
        formType={selected?.type}
        onClose={() => setSelected(null)}
        save={tokens.save}
        remove={tokens.remove}
        columns={columns}
      />
    </>
  )
}
