import BigNumber from 'bignumber.js'

export const formatDate = (d) => new Date(d).toLocaleDateString('en-GB')

export const formatDateTime = (timestamp, withSeconds = false, withMilliseconds = false) => {
  const date = new Date(timestamp)
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  const milliseconds = date.getMilliseconds()
  const time = `${hours}:${minutes}${withSeconds ? `:${seconds}` : ''}${withMilliseconds ? `.${milliseconds}` : ''}`

  return `${formatDate(timestamp)} ${time}`
}

export const buildGraphLine = (data, key) => {
  if (data.length < 1) return []

  let line = []

  for (let i = 0; i < data.length; i++) {
    const date = new Date(data[i]?.timestamp / 1000)
    const createdAt = formatDateTime(date, true)

    let newDataPoint = {
      x: createdAt,
      y: data[i][key],
    }

    line.push(newDataPoint)
  }

  return line
}

export const buildGraphData = (data) => {
  let directLine = buildGraphLine(data, 'percent')

  let possibleLine = buildGraphLine(data, 'percent_potential')

  return { directLine, possibleLine }
}

export const formatFloat = (value, decimals = 5) => {
  let newval = ''

  try {
    newval = new BigNumber(value).toFixed(decimals)
  } catch (e) {
    console.log(value)
  }

  return newval
}

export const formatLongNumbers = (number) => {
  const num = +number

  return num.toLocaleString('en').replaceAll(',', ' ')
}
