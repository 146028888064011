import Paper from '@mui/material/Paper'

import { useState, useMemo } from 'react'
import styled from '@emotion/styled'

import Table from 'components/Table/Table'
import { getColumns } from './columns'

import { useNetworksApi } from './hooks'

import { CreateNetworksBtn } from './components/CreateNetworksBtn'
import { NetworkDialog } from './components/NetworkDialog'

const NetworksContainer = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
`;

export const Networks = () => {
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [query, { save, remove }] = useNetworksApi()
  const columns = useMemo(() => getColumns(setSelectedNetwork), [setSelectedNetwork])

  return (
    <NetworksContainer elevation={2} id='networks_table'>
      <Table
        title={<CreateNetworksBtn setSelectedNetwork={setSelectedNetwork} />}
        data={query.data}
        columns={columns}
        loading={query.isFetching}
        initialState={{
          sortBy: [{ id: 'id', desc: true }],
        }}
        enableFilters
      />
      <NetworkDialog
        value={selectedNetwork}
        onClose={() => setSelectedNetwork(null)}
        save={save}
        remove={remove}
        networks={query.data}
      />
    </NetworksContainer>
  )
}
