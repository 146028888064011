import IconButton from '@mui/material/IconButton'

import { FORM_ACTION } from './Form'

export const CreateBtn = ({ title, openCreateForm }) => (
  <>
    {title}
    <IconButton
      onClick={() => {
        openCreateForm({ type: FORM_ACTION.CREATE, value: {} })
      }}
      size={'small'}
      style={{ margin: '0 8px', color: '#1976d2' }}
    >
      <i className='fas fa-plus' />
    </IconButton>
  </>
)
