import { DataGrid } from '@mui/x-data-grid'
import { useState, useRef, lazy, Suspense } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { getColumns } from './columns'
import { TopToolbar } from './topToolbar'
import {
  useDeleteRule,
  useDisableRule,
  useEnableRule,
  useRuleLists,
  useCreateRule,
  useUpdateRule
} from 'pages/CexFirst/hooks'

const LazyEditRule = lazy(() => import('../EditRule'));

const RulesTable = ({ snackbar }) => {
  const queryClient = useQueryClient()
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  })
  const [filterModel, setFilterModel] = useState({ items: [] })
  const [sortModel, setSortModel] = useState([])
  const [modalOn, setModalOn] = useState(false)
  const ruleToEdit = useRef(null)
  const { isPending, data } = useRuleLists(paginationModel, filterModel, sortModel)

  const createRule = useCreateRule(setModalOn, snackbar)
  const updateRule = useUpdateRule(setModalOn, snackbar)
  const deleteRule = useDeleteRule(snackbar, queryClient)
  const enableRule = useEnableRule(snackbar, queryClient)
  const disableRule = useDisableRule(snackbar, queryClient)

  const handleActionClick = {
    deleteRule: (rule) => {
      deleteRule.reset()
      deleteRule.mutate(rule.id)
    },
    enableDisableRule: (cellValues) => {
      if (cellValues.row.enabled) {
        disableRule.reset()
        disableRule.mutate(cellValues)
      } else {
        enableRule.reset()
        enableRule.mutate(cellValues)
      }
    },
    editRule: (values) => {
      updateRule.reset()
      updateRule.mutate(values)
    },
    editRuleOpen: (values) => {
      ruleToEdit.current = values.row
      setModalOn(true)
    },
    newRule: (values) => {
      createRule.reset()
      createRule.mutate(values)
    },
    createRule: () => {
      ruleToEdit.current = null
      setModalOn(true)
    },
    setModalOnTop: () => setModalOn(true),
    setModalHide: () => setModalOn(false),
  }

  const columns = getColumns(handleActionClick)

  return (
    <>
      <TopToolbar actions={handleActionClick} />
      <DataGrid
        loading={isPending}
        rows={data !== undefined ? data : []}
        columns={columns}
        autoHeight={true}
        columnHeaderHeight={90}
        setPaginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        setFilterModel={filterModel}
        onFilterModelChange={setFilterModel}
        setSortModel={sortModel}
        onSortModelChange={setSortModel}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnSelector
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
            margin: 'auto',
            textAlign: 'center',
          },
        }}
      />
      <Suspense fallback={<div>Loading...</div>}>
        {modalOn && (
          <LazyEditRule
            open={modalOn}
            onClose={handleActionClick.setModalHide}
            onEdit={handleActionClick.editRule}
            onNew={handleActionClick.newRule}
            dataToEdit={ruleToEdit.current}
          />
        )}
      </Suspense>
    </>
  )
}

export default RulesTable
