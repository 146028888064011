import { useState } from 'react';

export const CopyToClipboard = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = async () => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopyClick = () => {
    copyToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <button onClick={handleCopyClick} style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
      {isCopied ? <i className="fas fa-solid fa-clipboard" /> : <i className="far fa-solid fa-clipboard" />}
    </button>
  );
};