const SET_EXCHANGES = 'filters/SET_EXCHANGES'
const SET_PAIRS = 'filters/SET_PAIRS'

const initialState = {
  exchanges: [],
  pairs: [],
}

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXCHANGES: {
      return {
        ...state,
        exchanges: action.exchanges,
      }
    }
    case SET_PAIRS: {
      return {
        ...state,
        pairs: action.pairs,
      }
    }
    default:
      return state
  }
}

export default filtersReducer

export const filtersActions = {
  setExchanges: (exchanges) => ({ type: SET_EXCHANGES, exchanges }),
  setPairs: (pairs) => ({ type: SET_PAIRS, pairs }),
}
