import { IconButton, Typography, Tooltip } from '@mui/material'

import { formatDateTime } from '../../../../utilities/number-helpers'

const ExchangeCell = ({ brokerName, value, row, type, brokers }) => {
  const broker = brokers.find((el) => el.name === brokerName)

  return (
    <div className='flex-column'>
      <strong style={{ color: broker?.response_time > 50000 ? 'red' : '' }}>
        {brokerName}
        {broker && ` (${broker?.response_time / 1000}s)`}
      </strong>
      <div>
        <i
          className={row.buy === type ? 'fas fa-angle-up green' : 'fas fa-angle-down red'}
          style={{ marginRight: 4 }}
        />
        {value}
      </div>
    </div>
  )
}

export const COLUMNS = [
  {
    Header: '',
    id: 'actions',
    accessor: 'key',
    disableSortBy: true,
    Cell: ({ value, ...cell }) => (
      <>
        <IconButton
          onClick={() =>
            cell.openGraph(
              cell.row.original.pair,
              cell.row.original.bid_from_exchange,
              cell.row.original.ask_from_exchange
            )
          }
          size={'small'}
          style={{ margin: '0 12px' }}
        >
          <i className='fas fa-chart-line' />
        </IconButton>
        <IconButton
          onClick={() =>
            cell.loadCalculation(
              cell.row.original.text + '\n' + cell.row.original.text_potential,
              cell.row.original.pair,
              cell.row.original.time
            )
          }
          size={'small'}
          style={{ margin: '0 12px' }}
        >
          <i className='fas fa-download' />
        </IconButton>
      </>
    ),
  },
  {
    Header: () => <strong style={{ color: 'white' }}>Info</strong>,
    id: 'info',
    columns: [
      {
        Header: 'Pair',
        accessor: 'pair',
        Cell: ({ value }) => <strong>{value}</strong>,
      },
      {
        Header: 'Date',
        accessor: 'time',
        Cell: ({ value }) => formatDateTime(value * 1000, true),
      },
      {
        Header: 'Percent',
        accessor: 'percent',
        Cell: ({ value, cell }) => (
          <>
            <Tooltip title='Direct arbitrage'>
              <Typography className={value >= 0 ? 'green' : 'red'}>{value}</Typography>
            </Tooltip>
            <Tooltip title='Potential arbitrage'>
              <Typography>
                <small>{cell.row.original.percent_potential}</small>
              </Typography>
            </Tooltip>
          </>
        ),
      },
    ],
  },
  {
    Header: () => <strong style={{ color: 'white' }}>Exchanges</strong>,
    id: 'exchanges',
    columns: [
      {
        Header: 'BID',
        accessor: 'bid_price',
        disableSortBy: true,
        Cell: ({ value, ...cellInfo }) => {
          const row = cellInfo.row.original
          const brokerName = row.bid_from_exchange
          
          return (
            <ExchangeCell brokers={cellInfo.brokers} value={value} row={row} type={'bid'} brokerName={brokerName} />
          )
        },
      },
      {
        Header: 'ASK',
        accessor: 'ask_price',
        disableSortBy: true,
        Cell: ({ value, ...cellInfo }) => {
          const row = cellInfo.row.original
          const brokerName = row.ask_from_exchange
          
          return (
            <ExchangeCell brokers={cellInfo.brokers} value={value} row={row} type={'ask'} brokerName={brokerName} />
          )
        },
      },
    ],
  },
]
