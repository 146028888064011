import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Backdrop,
  Fade,
  Modal,
  Paper,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead
} from '@mui/material'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import { useSelector } from 'react-redux'
import { useExchangesSelector } from 'redux/selectors/settings'

import Graph from '../../../../components/Graph/Graph'

import s from './Table.module.css'
import { COLUMNS } from './columns'

const ArbitrageTable = ({ data, hiddenColumns = [] }) => {
  const columns = useMemo(() => COLUMNS, [])
  const availableExchanges = useExchangesSelector()
  const [showGraph, setShowGraph] = useState(false)
  const [current, setCurrent] = useState(null)
  const exchanges = useSelector((state) => state.filters.exchanges)
  const pairs = useSelector((state) => state.filters.pairs)

  const exchangesFilter = useCallback((rows, ids, { exchanges, pairs }) => {
    if (pairs.length > 0) return rows.filter((row) => pairs.map((p) => p.name).includes(row.original['pair']))

    if (exchanges.length < 1) return rows

    return rows.filter(
      (row) =>
        exchanges.map((ex) => ex.name).includes(row.original['bid_from_exchange']) ||
        exchanges.map((ex) => ex.name).includes(row.original['ask_from_exchange'])
    )
  }, [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    {
      columns,
      data,
      globalFilter: exchangesFilter,
      disableMultiSort: true,
      initialState: { hiddenColumns },
    },
    useGlobalFilter,
    useSortBy
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setGlobalFilter({ exchanges, pairs }), [exchanges, pairs])

  const openGraph = (pair, bid, ask) => {
    setCurrent({ pair: pair, bid: bid, ask: ask })
    setShowGraph(true)
  }

  const closeGraph = () => setShowGraph(false)

  const loadCalculation = (text, pair, time) => {
    const element = document.createElement('a')

    const file = new Blob([text], { type: 'text/plain' })

    element.href = URL.createObjectURL(file)
    element.download = `${pair}_${time}.txt`
    document.body.appendChild(element)
    element.click()
  }

  return (
    <>
      <TableContainer className={s.table}>
        {rows.length > 0 && (
          <MuiTable stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (column.id === 'bid_price' || column.id === 'ask_price')
                      column.sortType = (a, b) => parseFloat(a.values[column.id]) - parseFloat(b.values[column.id])

                    if (column.id === 'percent')
                      column.sortType = (a, b) => {
                        const maxA = Math.max.apply(
                          null,
                          [parseFloat(a.values[column.id]), parseFloat(a.original.percent_potential)].filter((v) =>
                            Number.isFinite(v)
                          )
                        )
                        const maxB = Math.max.apply(
                          null,
                          [parseFloat(b.values[column.id]), parseFloat(b.original.percent_potential)].filter((v) =>
                            Number.isFinite(v)
                          )
                        )

                        return maxA - maxB
                      }

                    return (
                      <TableCell
                        style={{
                          display: 'flex !important',
                        }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <span
                          style={{
                            userSelect: 'none',
                          }}
                        >
                          {column.render('Header')}
                        </span>
                        <i
                          style={{
                            fontSize: '11px',
                            marginLeft: '2px',
                            color: !column.isSorted && 'transparent',
                          }}
                          className={`fas fa-chevron-${column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'}`}
                        />
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)

                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell', {
                            openGraph,
                            loadCalculation,
                            brokers: availableExchanges,
                          })}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        )}
        {rows.length <= 0 && <h2 style={{ margin: '8px' }}>No results</h2>}
      </TableContainer>
      <Modal
        aria-labelledby='modal-graph'
        aria-describedby='modal-graph'
        className={s.modal}
        open={showGraph}
        onClose={closeGraph}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showGraph}>
          <Paper className={s.graph}>
            <Graph current={current} handleClose={closeGraph} />
          </Paper>
        </Fade>
      </Modal>
    </>
  )
}

export default ArbitrageTable
