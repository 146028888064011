import { Select, MenuItem } from '@mui/material'
import Button from 'components/Button/Button'
import styled from '@emotion/styled'

import { useState, useCallback, useRef } from 'react'
import { useSessionStorage } from 'hooks/use-session-storage'

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  align-items: center;
  width: 100%;
`

export const PAGE_SIZES = [20, 50, 100, 200, 1000, 2000, 5000, 10000]

export const usePagination = (params = {}) => {
  const start = params.start ?? 1
  const name = params.name ?? 'genericPageSize'
  const pages = params.pages ?? PAGE_SIZES
  const [isLastPage, setLastPage] = useState(false)
  const [page, setPage] = useState(start)
  const [pageSize, setPageSize] = useSessionStorage(name, pages[0])
  const tableRef = useRef(null)
  const decrement = useCallback(() => {
    setPage((prevPage) => {
      if (prevPage > 1) {
        return prevPage - 1
      }

      return prevPage
    })
    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
  }, [])
  const increment = useCallback(() => {
    setPage((prevPage) => prevPage + 1)
    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
  }, [])
  const setStartPage = useCallback(() => {
    setPage(start)
  }, [start])
  const changePageSize = useCallback(
    (e) => {
      setPageSize(e.target.value)
      setStartPage()
    },
    [setPageSize, setStartPage]
  )

  return { page, increment, decrement, setStartPage, pageSize, changePageSize, tableRef, isLastPage, setLastPage }
}

export const Pagination = ({
  decrement,
  isFetching,
  page,
  pageSize,
  changePageSize,
  increment,
  pages,
  isLastPage,
}) => (
  <PaginationContainer>
    <Button onClick={decrement} disabled={isFetching || page === 1} id='prev_page'>
      Prev
    </Button>
    <div>{page}</div>
    <div>
      <Select value={pageSize} onChange={changePageSize}>
        {(pages || PAGE_SIZES).map((size) => (
          <MenuItem key={size} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
      <Button onClick={increment} disabled={isFetching || isLastPage} id='next_page'>
        Next
      </Button>
    </div>
  </PaginationContainer>
)
