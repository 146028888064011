import { Button as MUIButton } from '@mui/material'

export const Button = ({ color, children, ...props }) => {
  return (
    <MUIButton {...props} style={!color ? { color: '#696969' } : {}} color={color}>
      {children}
    </MUIButton>
  )
}

export default Button
