import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import { useForm, Controller } from 'react-hook-form'
import { useCallback } from 'react'
import { networks, dexExchanges, cexExchanges } from './constants'

import { ACTION } from '../../hooks'

const FIELD_OPTIONS = {
  required: true,
  validate: (v) => (typeof v === 'string' ? v.trim().length >= 1 : v !== null && v !== undefined),
}

const defaultValues = {
  enabled: false,
  name: '',
  bot_type: 'ANY',
  networks: [],
  dex_exchanges: [],
  cex_exchanges: [],
  min_liquidity: '',
  max_tax: '',
  balance_leveling_only: false,
  ignore_if_on_binance: false,
}

export const Seeker = ({ type, rule, close, remove, create, update }) => {
  const isRemoveForm = type === ACTION.REMOVE

  const onSubmit = useCallback(
    async (data) => {
      if (data.networks.length === 0) {
        data.networks = undefined
      }

      if (data.dex_exchanges.length === 0) {
        data.dex_exchanges = undefined
      }

      if (data.cex_exchanges.length === 0) {
        data.cex_exchanges = undefined
      }

      if (data.id) {
        await update(data)
      } else {
        await create(data)
      }

      close()
    },
    [create, close, update]
  )

  const removeAndClose = useCallback(async () => {
    await remove(rule.id, rule.name)
    close()
  }, [rule.id, close, remove, rule.name])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      ...rule,
      networks: rule.networks || [],
      dex_exchanges: rule.dex_exchanges || [],
      cex_exchanges: rule.cex_exchanges || [],
    },
  })

  return (
    <Box
      component='form'
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiTextField-root': { marginBottom: '10px' },
      }}
    >
      <Card sx={{ maxWidth: 310 }}>
        <CardContent>
          <Typography variant='h5' component='div' mb={2} id={'edit_popup_title'}>
            Seeker {rule.id}
          </Typography>
          <Controller
            name='name'
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) => (
              <TextField
                label='Name'
                fullWidth
                disabled={isRemoveForm}
                error={!!errors.name}
                helperText={errors.name && 'name is invalid'}
                {...field}
              />
            )}
          />
          <Controller
            name='min_liquidity'
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) => (
              <TextField
                fullWidth
                label='Min Liquidity'
                disabled={isRemoveForm}
                error={!!errors.min_liquidity}
                helperText={errors.min_liquidity && 'min_liquidity is invalid'}
                {...field}
              />
            )}
          />
          <Controller
            name='max_tax'
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) => (
              <TextField
                fullWidth
                label='Max Tax'
                disabled={isRemoveForm}
                error={!!errors.max_tax}
                helperText={errors.max_tax && 'max_tax is invalid'}
                InputProps={{
                  endAdornment: <InputAdornment position='start'>%</InputAdornment>,
                }}
                {...field}
              />
            )}
          />
          <Controller
            name='networks'
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel>Networks</InputLabel>
                  <Select
                    multiple
                    disabled={isRemoveForm}
                    input={<OutlinedInput label='Networks' />}
                    error={!!errors.networks}
                    helperText={errors.networks && 'Networks is required'}
                    {...field}
                    onChange={(e) => {
                      if (e.target.value.includes('none')) {
                        field.onChange([])
                      } else if (e.target.value.includes('all')) {
                        field.onChange(networks.map(({ id }) => id))
                      } else {
                        field.onChange(e.target.value)
                      }
                    }}
                  >
                    <MenuItem value='none'>None</MenuItem>
                    <MenuItem value='all'>All</MenuItem>
                    {networks.map(({ id, name }) => (
                      <MenuItem key={name} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }}
          />
          <Controller
            name='dex_exchanges'
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel>Dex Exchanges</InputLabel>
                  <Select
                    multiple
                    disabled={isRemoveForm}
                    input={<OutlinedInput label='Dex Exchanges' />}
                    error={!!errors.dex_exchanges}
                    helperText={errors.dex_exchanges && 'Dex Exchanges is required'}
                    {...field}
                    onChange={(e) => {
                      if (e.target.value.includes('none')) {
                        field.onChange([])
                      } else if (e.target.value.includes('all')) {
                        field.onChange(dexExchanges.map(({ id }) => id))
                      } else {
                        field.onChange(e.target.value)
                      }
                    }}
                  >
                    <MenuItem value='none'>None</MenuItem>
                    <MenuItem value='all'>All</MenuItem>
                    {dexExchanges.map(({ id, name }) => (
                      <MenuItem key={name} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }}
          />
          <Controller
            name='cex_exchanges'
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel>Cex Exchanges</InputLabel>
                  <Select
                    multiple
                    disabled={isRemoveForm}
                    input={<OutlinedInput label='Cex Exchanges' />}
                    error={!!errors.cex_exchanges}
                    helperText={errors.cex_exchanges && 'Cex Exchange is required'}
                    {...field}
                    onChange={(e) => {
                      if (e.target.value.includes('none')) {
                        field.onChange([])
                      } else if (e.target.value.includes('all')) {
                        field.onChange(cexExchanges.map(({ id }) => id))
                      } else {
                        field.onChange(e.target.value)
                      }
                    }}
                  >
                    <MenuItem value='none'>None</MenuItem>
                    <MenuItem value='all'>All</MenuItem>
                    {cexExchanges.map(({ id, name }) => (
                      <MenuItem key={name} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }}
          />
          <Controller
            name='bot_type'
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel>Bot type</InputLabel>
                  <Select
                    label='Bot type'
                    disabled={isRemoveForm}
                    input={<OutlinedInput label='Bot type' />}
                    error={!!errors.bot_type}
                    helperText={errors.bot_type && 'Bot type is required'}
                    {...field}
                  >
                    <MenuItem value='ANY'>ANY</MenuItem>
                    <MenuItem value='CLASSIC'>CLASSIC</MenuItem>
                    <MenuItem value='TRIPLE'>TRIPLE</MenuItem>
                  </Select>
                </FormControl>
              )
            }}
          />
          <Controller
            name='balance_leveling_only'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch disabled={isRemoveForm} checked={field.value} {...field} />}
                label='Leveling'
              />
            )}
          />
          <Controller
            name='ignore_if_on_binance'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch disabled={isRemoveForm} checked={field.value} {...field} />}
                label='Ignore Binance listed coins'
              />
            )}
          />
          <Controller
            name='enabled'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch disabled={isRemoveForm} checked={field.value} {...field} />}
                label='Enabled'
              />
            )}
          />
        </CardContent>
        <CardActions>
          {isRemoveForm ? (
            <Button size='small' color='error' id='confirm_button' onClick={removeAndClose}>
              Confirm
            </Button>
          ) : (
            <Button size='small' color='primary' type='submit' id='submit_button'>
              Submit
            </Button>
          )}
          <Button size='small' onClick={close}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}
