import { useState, useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

const INIT_TITLE = 'Copy to clipboard'

export const CopyButton = ({ value }) => {
  const [title, setTitle] = useState(INIT_TITLE)
  const [open, setOpen] = useState(false)

  const copy = useCallback(() => {
    navigator.clipboard.writeText(value)
    setTitle('Copied!')
  }, [value])

  const handleTooltipClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setTitle(INIT_TITLE)
    setOpen(true)
  }, [])

  return (
    <Tooltip
      title={title}
      open={open}
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      onOpen={handleOpen}
      placement="top"
      arrow
    >
      <IconButton
        size="small"
        onClick={() => copy(value)}
      >
        <i className='fas fa-copy fa-xs' />
      </IconButton>
    </Tooltip>
  )
}
