import { CircularProgress } from '@mui/material'

const Preloader = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        filter: 'grayscale(100%)',
      }}
    >
      <CircularProgress size={100} thickness={3} />
    </div>
  )
}

export default Preloader
