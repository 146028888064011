import QueryFilter from 'components/Table/components/QueryFilter'
import IconButton from '@mui/material/IconButton'
import styled from '@emotion/styled'
import moment from 'moment'

import { ACTION } from './hooks'
import { networks, dexExchanges, cexExchanges } from './components/Seeker/constants'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

const List = styled.div`
  max-width: 150px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 200px;
  overflow: auto;
`

const timeFormat = (time) => {
  return moment(time).format('YYYY-MM-DD HH:mm')
}

export const getColumns = (setSelectedRule) => [
  {
    Header: '',
    accessor: '_update-action',
    Cell: ({ row }) => {
      return (
        <IconButton
          onClick={() => {
            setSelectedRule({ ...row.original, _type: ACTION.UPDATE })
          }}
          size={'small'}
          style={{ margin: '0 8px', color: '#1976d2' }}
        >
          <i className='fas fa-edit' />
        </IconButton>
      )
    },
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    sortType: 'alphanumericCustom',
    Filter: renderFilter,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: renderFilter,
  },
  {
    Header: 'Bot type',
    accessor: 'bot_type',
    Filter: renderFilter,
  },
  {
    Header: 'Networks',
    accessor: 'networks',
    Cell: ({ value }) => {
      if (value === null) {
        return '-'
      }

      if (value.length === networks.length) {
        return 'all'
      }

      return (
        <List>
          {value.map((v) => (
            <span key={v}>{v}</span>
          ))}
        </List>
      )
    },
    Filter: renderFilter,
  },
  {
    Header: 'DEX',
    accessor: 'dex_exchanges',
    Cell: ({ value }) => {
      if (value === null) {
        return '-'
      }

      if (value.length === dexExchanges.length) {
        return 'all'
      }

      return (
        <List>
          {value.map((v) => (
            <span key={v}>{v}</span>
          ))}
        </List>
      )
    },
    Filter: renderFilter,
  },
  {
    Header: 'CEX',
    accessor: 'cex_exchanges',
    Cell: ({ value }) => {
      if (value === null) {
        return '-'
      }

      if (value.length === cexExchanges.length) {
        return 'all'
      }

      return (
        <List>
          {value.map((v) => (
            <span key={v}>{v}</span>
          ))}
        </List>
      )
    },
    Filter: renderFilter,
  },
  {
    Header: 'Min Liquidity',
    accessor: 'min_liquidity',
    Filter: renderFilter,
  },
  {
    Header: 'Max tax',
    accessor: 'max_tax',
    Filter: renderFilter,
  },
  {
    Header: 'Balance Leveling Only',
    accessor: 'balance_leveling_only',
    Cell: ({ value }) => value.toString(),
    Filter: renderFilter,
  },
  {
    Header: 'Ignore If On Binance',
    accessor: 'ignore_if_on_binance',
    Cell: ({ value }) => value.toString(),
    Filter: renderFilter,
  },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    Cell: ({ value }) => value.toString(),
    Filter: renderFilter,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Filter: renderFilter,
    Cell: ({ value }) => timeFormat(value),
  },
  {
    Header: 'Updated At',
    accessor: 'updated_at',
    Filter: renderFilter,
    Cell: ({ value }) => timeFormat(value),
  },
  {
    Header: '',
    accessor: '_remove-action',
    Cell: ({ row }) => {
      return (
        <IconButton
          onClick={() => {
            setSelectedRule({ ...row.original, _type: ACTION.REMOVE })
          }}
          size={'small'}
          style={{ margin: '0 8px', color: 'red' }}
        >
          <i className='fas fa-trash' />
        </IconButton>
      )
    },
    disableFilters: true,
  },
]
