import styled from '@emotion/styled'
import { useEffect, useState, useCallback } from 'react'
import { balanceLevelingApi } from 'api/balance-leveling-api'

const useBLErrorsApi = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)
    const res = await balanceLevelingApi.listErrors()

    setData(res)
    setLoading(false)
  }, [])

  useEffect(() => {
    fetch()
  }, [fetch])

  return { data, loading }
}

const Container = styled.div`
  padding: 10px;
  background: #fff5e5;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: -12px;
`

const ErrorItem = styled.div`
  span {
    color: #7f592a;
  }

  b {
    color: #5a5958;
  }
`

const renderErrItem = ({
  botId,
  coin,
  minThreshold,
  ruleAmount,
  exchangeFrom,
  exchangeTo,
  balanceFrom,
  balanceTo,
  errorCategory,
  maxFee,
  actualFee,
}) => {
  if (errorCategory === 'HIGH_FEE') {
    return (
      <ErrorItem key={botId}>
        <span><i>{coin}</i> - high fee for leveling </span>
        <b>max fee - {maxFee}, actual fee - {actualFee} ({((actualFee - maxFee) / maxFee).toFixed(1)}%)</b>
      </ErrorItem>
    )
  }

  if (errorCategory === 'NOT_ENOUGH_BALANCE') {
    return (
      <ErrorItem key={botId}>
        <span><i>{coin}</i> - not enough balance for leveling </span>
        <b>{exchangeFrom} balance - {balanceFrom} || {exchangeTo} balance - {balanceTo} || </b>
        <b>leveling amount - {ruleAmount} || min threshold - {minThreshold}</b>
      </ErrorItem>
    )
  }

  return (
    <ErrorItem key={botId}>
      <span><i>{coin}</i> - {errorCategory}</span>
      <b>{exchangeFrom} balance - {balanceFrom} || {exchangeTo} balance - {balanceTo} || </b>
      <b>leveling amount - {ruleAmount} || min threshold - {minThreshold}</b>
      <b>max fee - {maxFee}, actual fee - {actualFee}</b>
    </ErrorItem>
  )
}

export const BalanceLevelingBanner = () => {
  const { data } = useBLErrorsApi()

  if (!data?.length) {
    return null
  }

  return <Container>{data.map(renderErrItem)}</Container>
}
