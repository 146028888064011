import { useLocalStorage } from 'hooks/use-local-storage'
import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import get from 'lodash/get'
import { useSessionStorage } from 'hooks/use-session-storage'
import { useSnackbar } from 'notistack'
import { useExchangesSelector } from 'redux/selectors/settings'
import botAPI, {
  useBotList,
  mutateBotStatus,
  switchArchiveBotStatus,
  mutateStatusesByIds,
  mutateAllStatuses
} from 'api/bot-api'
import { useBotErrorsCount } from 'api/errors-api'
import { useOpportunities } from 'api/opportunities-api'
import { useOrders } from 'api/orders-api'
import { useSelector } from 'react-redux'
import { globalFiltersQuerySelector } from '../../redux/selectors/globalFilters'

export const UNARCHIVED = 'unarchived'
export const ARCHIVED = 'archived'

export const SUMMARY_BOTS_STATUS = null // true | false | null

export const useBotEditApi = ({
  data,
  botsType,
  botsQuery,
  botsQueryKey,
}) => {
  const [cachedBotsStatuses, cacheBotsStatuses] = useLocalStorage('cache-bots', [], 15)
  const [summaryBotsStatus, setSummaryBotsStatus] = useState(SUMMARY_BOTS_STATUS)
  const [editing, setEditing] = useState(false)
  const [editBot, setEditBot] = useState(null)
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const [confirmArchivationAnchor, setConfirmAnchor] = useState(null)

  const openConfirmArchivation = (event, id) => {
    setConfirmAnchor(event.currentTarget)
    setEditBot({ id, fill: data.find((bot) => bot.id === id), show: false })
  }

  const closeConfirmArchivation = () => {
    setConfirmAnchor(null)
  }

  const openBotEditor = ({ id }) => setEditBot({ id, fill: data.find((bot) => bot.id === id), show: true })
  const closeBotEditor = () => setEditBot((prev) => ({ ...prev, show: false }))

  const saveBotsStatuses = useCallback(() => {
    cacheBotsStatuses(data.map(({ id, status }) => ({ id, status })))
    setPopup((prev) => ({
      ...prev,
      open: true,
      message: 'Bots statuses saved!',
      severity: 'success',
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const clearBotsStatuses = useCallback((msg) => {
    cacheBotsStatuses([], { skipHistory: true })
    setPopup((prev) => ({
      ...prev,
      open: true,
      message: typeof msg === 'string' ? msg : 'Bots statuses cleared!',
      severity: 'success',
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rollbackBotsStatuses = useCallback(async () => {
    setEditing(true)
    const enableBots = []
    const disableBots = []

    for (const bot of cachedBotsStatuses) {
      if (bot.status) {
        enableBots.push(bot.id)
      } else {
        disableBots.push(bot.id)
      }
    }

    try {
      await Promise.all([
        enableBots.length && botAPI.enableBots(enableBots),
        disableBots.length && botAPI.disableBots(disableBots),
      ])
      await botsQuery.refetch()
      cacheBotsStatuses([], { skipHistory: true }) // clear cache only after success update
      setPopup((prev) => ({
        ...prev,
        open: true,
        message: 'Bots statuses returned!',
        severity: 'success',
      }))
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
    }

    setEditing(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, cachedBotsStatuses, setPopup, setEditing, botsQuery])

  const switchBotStatus = useCallback(
    async (id, botId, currentStatus) => {
      setEditing(true)

      try {
        const action = currentStatus
          ? botAPI.disableBot
          : botAPI.enableBot

        const response = await action([id])

        if (response.status === 200) {
          mutateBotStatus(botId, botsQueryKey)
          setPopup((prev) => ({
            ...prev,
            open: true,
            message: 'Bot status changed!',
            severity: 'success',
          }))
        } else {
          let error = ''

          if (response?.data) {
            for (let key of Object.keys(response.data)) {
              if (response.data[key] !== undefined) {
                error += `\n${response.data[key][0]}`
              }
            }
          }

          throw Error(error)
        }
      } catch (err) {
        setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
      } finally {
        setEditing(false)
      }
    },
    [setPopup, setEditing, botsQueryKey]
  )

  const changeBotArchive = useCallback(async () => {
    setConfirmAnchor(null)
    const botId = editBot?.id

    try {
      const response = botsType === UNARCHIVED ? await botAPI.archiveBot(botId) : await botAPI.unarchiveBot(botId)

      if (response.status === 200) {
        switchArchiveBotStatus(editBot?.fill?.botId, botsQueryKey)

        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'Bot archivation status changed!',
          severity: 'success',
        }))
      } else {
        let error = ''

        if (response?.data) {
          for (let key of Object.keys(response.data)) {
            if (response.data[key] !== undefined) {
              error += `\n${response.data[key][0]}`
            }
          }
        }

        throw Error(error)
      }
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
    }
  }, [editBot?.id, editBot?.fill?.botId, botsType, botsQueryKey])

  const switchAllStatuses = useCallback(
    async (allEnabled) => {
      setEditing(true)
      const action = allEnabled
        ? botAPI.enableBots
        : botAPI.disableBots

      try {
        await action(data.map(({ id }) => id))
        mutateAllStatuses(allEnabled, botsQueryKey)
      } catch (err) {
        setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
      }

      setEditing(false)
      setPopup((prev) => ({
        ...prev,
        open: true,
        message: 'Bots statuses changed!',
        severity: 'success',
      }))
    },
    [botsQueryKey, data]
  )

  const disableAll = useCallback(() => {
    switchAllStatuses(false)
  }, [switchAllStatuses])

  const enableAll = useCallback(() => {
    switchAllStatuses(true)
  }, [switchAllStatuses])

  useEffect(() => {
    let enabledSummaryStatusesLength = 0

    let disabledSummaryStatusesLength = 0

    for (const bot of data) {
      if (bot.status) {
        enabledSummaryStatusesLength += 1
      } else {
        disabledSummaryStatusesLength += 1
      }
    }

    if (enabledSummaryStatusesLength === data.length) {
      setSummaryBotsStatus(true)
    } else if (disabledSummaryStatusesLength === data.length) {
      setSummaryBotsStatus(false)
    } else {
      setSummaryBotsStatus(null)
    }
  }, [data, setSummaryBotsStatus])

  return [
    editBot,
    { changeBotArchive, confirmArchivationAnchor, openConfirmArchivation, closeConfirmArchivation },
    { switchBotStatus, editing, disableAll, enableAll, openBotEditor, closeBotEditor },
    { popup, setPopup },
    summaryBotsStatus,
    { saveBotsStatuses, clearBotsStatuses, rollbackBotsStatuses, enabled: cachedBotsStatuses.length !== 0 },
  ]
}

export const PAGE_SIZES = [50, 100, 500, 1000, 2000, 5000, 10000]

export const usePagination = (params = {}) => {
  const start = params.start ?? 1
  const name = params.name ?? 'bot-table'

  const [page, setPage] = useState(start)
  const [pageSize, setPageSize] = useSessionStorage(`botsPageSize${name}`, PAGE_SIZES[0])
  const tableRef = useRef(null)

  const decrementPage = useCallback(() => {
    setPage((prevPage) => {
      if (prevPage > 1) {
        return prevPage - 1
      }

      return prevPage
    })

    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
  }, [])

  const incrementPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1)
    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
  }, [])

  const setStartPage = useCallback(() => {
    setPage(start)
  }, [start])

  const changePageSize = useCallback(
    (e) => {
      setPageSize(e.target.value)
      setStartPage()
    },
    [setPageSize, setStartPage]
  )

  return { page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize, tableRef }
}

export const processBotList = (bots, { filter, exchangesList, botErrors, opportunities, orders }) => {
  const filteredBots = []
  const cexExchanges = exchangesList?.filter(({ is_dex }) => !is_dex) || []
  const cexExchangesMap = Object.fromEntries(cexExchanges.map(({ internal_name }) => [internal_name, new Set()]))

  const add = (bot) => {
    const botId = bot.botId

    filteredBots.push({
      ...bot,
      opportunities: opportunities ? opportunities[botId] : [],
      orders: orders && orders[botId] ? orders[botId] : [],
      bot_not_enough_balance: botErrors.data ? botErrors.data[botId] : [],
    })

    if (bot.type === 'TRIPLE') {
      cexExchangesMap[get(bot, 'triple_arbitrage[0].step2.exchange')]?.add(botId)
    } else {
      if (bot.exchange_from?.name) {
        cexExchangesMap[bot.exchange_from.name]?.add(botId)
      }
      if (bot.exchange_to?.name) {
        cexExchangesMap[bot.exchange_to.name]?.add(botId)
      }
    }
  }

  // filter bots and fill data for each filtered bot by add fn
  for (const bot of bots) {
    if (!filter?.field) {
      add(bot)
    } else {
      if (filter.field === 'exchange_to.name' && bot.type === 'TRIPLE') {
        if (get(bot, 'triple_arbitrage[0].step2.exchange') === filter.value) {
          add(bot)
        }
      } else if (get(bot, filter.field) === filter.value) {
        add(bot)
      }
    }
  }

  return { filteredBots, exchanges: Object.keys(cexExchangesMap).map(exch => ({ id: exch, label: `${exch} (${cexExchangesMap[exch].size})` })) }
}

export const useBotApi = (botsType = UNARCHIVED, page, pageSize, filterBotBy = null) => {
  const globalFilters = useSelector(globalFiltersQuerySelector)
  const [botsQuery, botsQueryKey] = useBotList({ botType: botsType, pagination: { page, pageSize }, globalFilters })
  const { data, isFetching } = botsQuery
  const [isProcessing, setIsProcessing] = useState(false)

  const isLastPage = data?.isLastPage
  const bots = Object.values(data?.bots || {})

  const exchangesList = useExchangesSelector()
  const opportunitiesQuery = useOpportunities()
  const ordersQuery = useOrders()
  const botErrors = useBotErrorsCount()

  const opportunities = opportunitiesQuery.data
  const orders = ordersQuery.data

  const [filter, filterBots] = useState(filterBotBy) // { field, value } { "field" : "id", "value" : 6254 }

  const { filteredBots, exchanges } = useMemo(() => {
    return processBotList(bots, { filter, exchangesList, botErrors, opportunities, orders })
  }, [bots, filter, exchangesList, botErrors, opportunities, orders])

  return {
    bots: filteredBots,
    botsQuery,
    botsQueryKey,
    isFetching: isProcessing || isFetching,
    setIsFetching: setIsProcessing,
    filterBots,
    exchanges,
    isLastPage,
  }
}

export const useBotGraph = () => {
  const [graph, setGraph] = useState({ show: false, botId: null, operationId: null })

  const openGraph = (externalId, internalId, operationId, side) => {
    setGraph({
      show: true,
      internalId,
      externalId,
      operationId,
      side,
    })
  }

  const closeGraph = () => setGraph((prev) => ({ ...prev, show: false }))

  return [graph, openGraph, closeGraph]
}

export const useBotBulkActions = ({ data, botsQueryKey }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isProcessing, setProcessing] = useState(false)
  const [selectedBots, setSelectedBots] = useState([]) // list of botId

  const selectBot = useCallback((bot, checked) => {
    setSelectedBots(prev => {
      if (!checked) {
        return prev.filter(b => b !== bot)
      }

      return [...prev, bot]
    })
  }, [])

  const selectAllBots = useCallback((checked) => {
    setSelectedBots(checked ? data.map(({ botId }) => botId) : [])
  }, [setSelectedBots, data])
  const selectedAll = selectedBots.length !== 0 && selectedBots.length === data.length

  const archiveSelected = useCallback(async () => {
    if (selectedBots.length !== 0) {
      try {
        setProcessing(true)
        setSelectedBots([])
        await botAPI.archiveBots(selectedBots)
        switchArchiveBotStatus(selectedBots, botsQueryKey)
        enqueueSnackbar(`Archived ${selectedBots.length} bots`, { variant: 'success' })
      } catch (e) {
        enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
      } finally {
        setProcessing(false)
      }
    }
  }, [selectedBots, botsQueryKey, enqueueSnackbar])

  const enableSelected = useCallback(async () => {
    if (selectedBots.length !== 0) {
      try {
        setProcessing(true)
        setSelectedBots([])
        await botAPI.enableBots(selectedBots)
        mutateStatusesByIds(selectedBots, true, botsQueryKey)
        enqueueSnackbar(`Enabled ${selectedBots.length} bots`, { variant: 'success' })
      } catch (e) {
        enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
      } finally {
        setProcessing(false)
      }
    }
  }, [selectedBots, botsQueryKey, enqueueSnackbar])

  const disableSelected = useCallback(async () => {
    if (selectedBots.length !== 0) {
      try {
        setProcessing(true)
        setSelectedBots([])
        await botAPI.disableBots(selectedBots)
        mutateStatusesByIds(selectedBots, false, botsQueryKey)
        enqueueSnackbar(`Disabled ${selectedBots.length} bots`, { variant: 'success' })
      } catch (e) {
        enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
      } finally {
        setProcessing(false)
      }
    }
  }, [selectedBots, botsQueryKey, enqueueSnackbar])

  return {
    selectBot,
    selectedAll,
    selectAllBots,
    selectedBots,
    archiveSelected,
    enableSelected,
    disableSelected,
    isProcessing,
  }
}
