import { useQuery } from '@tanstack/react-query'
import { fetch } from '.'

const errorsApi = {
  errorsCount: () => {
    return fetch
      .get(`errors-count`)
      .json()
      .catch((err) => console.log(err))
  },
}

export const useBotErrorsCount = () => {
  const query = useQuery({
    queryKey: ['errors-count'],
    queryFn: errorsApi.errorsCount,
    cacheTime: 0, // 0 minutes
    staleTime: 0, // 0 minutes
  })

  return query
}
