export const menu = [
  {
    id: 'bot_tab_main_link',
    name: 'Dashboard',
    path: '/',
  },
  {
    id: 'cex_first_tab_main_link',
    name: 'Cex First',
    path: '/cex-first',
  },
  {
    id: 'bot_details_tab_main_link',
    name: 'Bots',
    path: '/bot-details',
  },
  {
    id: 'signals_tab_main_link',
    name: 'Signals',
    path: '/bot-signals',
  },
  {
    id: 'opportunities_tab_main_link',
    name: 'Opportunities',
    path: '/bot-opportunities',
  },
  {
    id: 'balances_tab_main_link',
    name: 'Balances',
    path: '/balances',
  },
  {
    id: 'seeker_tab_main_link',
    name: 'Seeker',
    path: '/seeker-rules',
  },
  {
    id: 'dex_tokens_tab_main_link',
    name: 'Tokens',
    path: '/dex-tokens',
  },
  {
    id: 'allowances_tab_main_link',
    name: 'Allowances',
    path: '/allowances',
  },
  {
    id: 'settings_tab_main_link',
    name: 'Settings',
    path: '/settings',
  },
  {
    id: 'balance_leveling_tab_main_link',
    name: 'Balance leveling',
    path: '/balance-leveling',
  },
  {
    id: 'MarketVolatility_tab_main_link',
    name: 'Market Volatility',
    path: '/market-volatility',
  },
  {
    id: 'deployments_tab_main_link',
    name: 'Deployments',
    path: '/deployments',
  },
  {
    id: 'exchanges_tab_main_link',
    name: 'Exchanges',
    path: '/exchanges',
  },
  {
    id: 'Networks_tab_main_link',
    name: 'Networks',
    path: '/networks',
  },
]
