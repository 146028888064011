import { fetch } from './'

const cexFirstAPI = {
  getRulesList: (filters) => {
    return fetch
      .get(`cex-first`, { searchParams: filters }) // To GET active rules
      .json()
  },
  getRulesListAll: () => {
    return fetch
      .get(`cex-first/all`) // To GET all rules archived and active
      .json()
  },
  getRulesListArchived: () => {
    return fetch
      .get(`cex-first/archived`) // To GET all archived rules
      .json()
  },
  createRule: (postData) => {
    return fetch
      .post(`cex-first`, postData)
  },
  updateRule: (ruleId, putData) => {
    return fetch
      .put(`cex-first/${ruleId}`, putData)   // Change to Method.PUT because of updating information
      .json()
  },
  deleteRule: (ruleId) => {
    return fetch
      .delete(`cex-first/${ruleId}`)
  },
  enableRule: (ruleId) => {
    return fetch
      .put(`cex-first/${ruleId}/enable`)  // Change to Method.PUT because of updating state
  },
  disableRule: (ruleId) => {
    return fetch
      .put(`cex-first/${ruleId}/disable`)  // Change to Method.PUT because of updating state
  },
  archiveRule: (ruleId) => {
    return fetch
      .put(`cex-first/${ruleId}/archive`)  // Change to Method.PUT because of updating state
  },
  unarchiveRule: (ruleId) => {
    return fetch
      .put(`cex-first/${ruleId}/unarchive`)  // Change to Method.PUT because of updating state
  },
}

export default cexFirstAPI
