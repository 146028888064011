import { MenuItem, Select } from '@mui/material'

const SelectColumnFilter = ({ column: { id, filterValue, setFilter }, data }) => {
  const uniqueOptions = [...new Set(data.map((row) => row[id]))]

  return (
    <Select
      size='small'
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value)}
      variant='standard'
      style={{ marginBottom: 8, fontSize: 14, minWidth: 50 }}
    >
      <MenuItem value='' style={{ fontSize: 14 }}>
        <em>None</em>
      </MenuItem>
      {uniqueOptions.map((b) => (
        <MenuItem key={b} value={b} style={{ fontSize: 14 }}>
          {b}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectColumnFilter
