import { IconButton } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import SyncIcon from '@mui/icons-material/Sync'

export const RefetchButton = ({ isRefetching, refetch }) => {
  return (
    <IconButton onClick={refetch} size="small" disabled={isRefetching}>
      {isRefetching ? <CircularProgress style={{ width: 20, height: 20 }} /> : <SyncIcon fontSize="small" />}
    </IconButton>
  )
}
