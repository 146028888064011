import { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { ButtonGroup } from '@mui/material'

import { Button } from './Button'

const Container = styled.div`
  /* position: relative; */
`

const CardBox = styled.div`
  position: absolute;
  transform: ${({ opened }) => opened ? 'scale(1)' : 'scale(0)'};
  transition: all .2s ease-in;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  padding: 0 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`

const CardContent = styled.div`
  font-weight: bold;
`

export const ConfirmButton = ({ children, label, text, onClick }) => {
  const [opened, setOpened] = useState(false)

  const confirm = useCallback(() => {
    setOpened(false)
    onClick()
  }, [onClick])

  return (
    <Container>
      <Button onClick={() => setOpened(true)}>{children}</Button>
      <CardBox opened={opened}>
        <ButtonGroup variant="outlined">
          <Button color="success" onClick={confirm}>{label || 'Confirm'}</Button>
          <Button color="error" onClick={() => setOpened(false)}>Cancel</Button>
        </ButtonGroup>
        <CardContent>{text}</CardContent>
      </CardBox>
    </Container>
  )
}
