import { FormControl, InputAdornment, InputLabel, TextField, IconButton, Divider } from '@mui/material'
import { NumericFormat } from 'react-number-format'

import s from '../BotEditor.module.css'

export const Directions = ({ values, archived, createAmountFields, changeAmountState, setValues }) => {
  return (
    <>
      <div className='flex-row align-center' id='buy_direction'>
        <h5 style={{ marginRight: 8 }}>Buy direction:</h5>
        <IconButton size='small' onClick={() => createAmountFields('buy')}>
          <i className='fas fa-plus' />
        </IconButton>
      </div>
      {Object.keys(values.amount).map(
        (key, i) =>
          values.amount[key].direction.toLowerCase() === 'buy' && (
            <FormControl key={key} style={{ marginBottom: 8 }}>
              <div className='flex-row align-center'>
                <div className='column'>
                  <InputLabel className={s.inputLabel} htmlFor={'amount'}>
                    Amount #{i + 1}
                  </InputLabel>
                  <NumericFormat
                    id='amount'
                    name='amount'
                    customInput={TextField}
                    value={values.amount[key]?.amount}
                    size='small'
                    decimalSeparator={'.'}
                    onChange={(e) => changeAmountState(e, key, 'amount')}
                    autoComplete='off'
                    style={{ marginRight: 8 }}
                    disabled={archived}
                    inputProps={{ min: 1 }}
                  />
                </div>
                <div className='column'>
                  <InputLabel className={s.inputLabel} htmlFor={'profit'}>
                    Profit #{i + 1}
                  </InputLabel>
                  <NumericFormat
                    id='profit'
                    name='profit'
                    customInput={TextField}
                    value={values.amount[key]?.profit}
                    size='small'
                    decimalSeparator={'.'}
                    onChange={(e) => changeAmountState(e, key, 'profit')}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    autoComplete='off'
                    style={{ marginRight: 8 }}
                    disabled={archived}
                    inputProps={{ min: 1 }}
                  />
                </div>
                <IconButton
                  size='small'
                  onClick={() => {
                    let newAmount = { ...values.amount }

                    delete newAmount[key]
                    setValues((prev) => ({ ...prev, amount: newAmount }))
                  }}
                  style={{ marginTop: 12 }}
                >
                  <i className='fas fa-minus' />
                </IconButton>
              </div>
            </FormControl>
          )
      )}
      <Divider style={{ marginTop: 10 }} />
      <div className='flex-row align-center' id='sell_direction'>
        <h5 style={{ marginRight: 8 }}>Sell direction:</h5>
        <IconButton size='small' onClick={() => createAmountFields('sell')}>
          <i className='fas fa-plus' />
        </IconButton>
      </div>
      {Object.keys(values.amount).map(
        (key, i) =>
          values.amount[key].direction.toLowerCase() === 'sell' && (
            <FormControl key={key} style={{ marginBottom: 8 }}>
              <div className='flex-row align-center'>
                <div className='column'>
                  <InputLabel className={s.inputLabel} htmlFor={'amount'}>
                    Amount #{i + 1}
                  </InputLabel>
                  <NumericFormat
                    id='amount'
                    name='amount'
                    customInput={TextField}
                    value={values.amount[key]?.amount}
                    size='small'
                    decimalSeparator={'.'}
                    onChange={(e) => changeAmountState(e, key, 'amount')}
                    autoComplete='off'
                    style={{ marginRight: 8 }}
                    inputProps={{ min: 1 }}
                    disabled={archived}
                  />
                </div>
                <div className='column'>
                  <InputLabel className={s.inputLabel} htmlFor={'profit'}>
                    Profit #{i + 1}
                  </InputLabel>
                  <NumericFormat
                    id='profit'
                    name='profit'
                    customInput={TextField}
                    value={values.amount[key]?.profit}
                    size='small'
                    decimalSeparator={'.'}
                    onChange={(e) => changeAmountState(e, key, 'profit')}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    autoComplete='off'
                    style={{ marginRight: 8 }}
                    disabled={archived}
                    inputProps={{ min: 1 }}
                  />
                </div>
                <IconButton
                  size='small'
                  onClick={() => {
                    let newAmount = { ...values.amount }

                    delete newAmount[key]
                    setValues((prev) => ({ ...prev, amount: newAmount }))
                  }}
                  style={{ marginTop: 12 }}
                >
                  <i className='fas fa-minus' />
                </IconButton>
              </div>
            </FormControl>
          )
      )}
    </>
  )
}
