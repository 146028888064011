import { TextField } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

const QueryFilter = ({ column, loading, setAllFilters, multiSelectFilters, autoSubmitFilters }) => {
  const [value, setValue] = useState(column.filterValue || '')

  const submit = useCallback(
    (callbackValue) => {
      const filterValue = callbackValue === undefined ? value : callbackValue

      if (multiSelectFilters) {
        column.setFilter(filterValue || undefined)
      } else {
        setAllFilters(filterValue ? [{ id: column.id, value }] : [])
      }
    },
    [setAllFilters, value, column, multiSelectFilters]
  )

  const submitOnEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        submit()
      }
    },
    [submit]
  )

  const updateFieldValue = useCallback(
    (e) => {
      const value = e.target.value

      setValue(value)

      if (autoSubmitFilters) {
        submit(value)
      }
    },
    [setValue, submit, autoSubmitFilters]
  )

  useEffect(() => {
    setValue(column.filterValue || '')
  }, [setValue, column.filterValue])

  const submitOnBlur = useCallback(() => {
    if ((column.filterValue !== undefined || value !== '') && column.filterValue !== value) {
      submit()
    }
  }, [submit, column.filterValue, value])

  return (
    <>
      <TextField
        size='small'
        value={value}
        disabled={loading}
        onChange={updateFieldValue}
        onKeyDown={submitOnEnter}
        onBlur={submitOnBlur}
        placeholder={`Filter...`}
        inputProps={{ style: { fontSize: 14, minWidth: 62, maxWidth: 125 } }}
        variant='standard'
      />
    </>
  )
}

export default QueryFilter
