import Select from 'react-select'
import { useEffect, useState, useMemo } from 'react'
import Link from '@mui/material/Link'

import Button from 'components/Button/Button'
import { Alert, Snackbar, TextField } from '@mui/material'
import { useBalance } from 'api/balances-api'
import nonceApi from 'api/nonce-api'
import { useNetworksListSelector } from 'redux/selectors/settings'
import { getAddressPage } from 'utilities/get-address-page'

const NonceForm = () => {
  const { data: balance } = useBalance()
  const availableNetworks = useNetworksListSelector()
  const availableNetworksMap = useMemo(() => {
    return availableNetworks.reduce((acc, network) => {
      acc[network.id] = network

      return acc
    }, {})
  }, [availableNetworks])

  const [network, setNetwork] = useState()
  const [address, setAddress] = useState()
  const [nonce, setNonce] = useState()

  const [addressOptions, setAddressOptions] = useState([])
  const [isAddressLoading, setIsAddressLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  useEffect(() => {
    setIsAddressLoading(true)

    if (balance) {
      const addresses = availableNetworks.reduce(
        (o, { name, id }) => ({
          ...o,
          [id]: [
            ...new Set(
              balance.dexAssets.filter((value) => value.network === name).map((value) => value.dexWallet)
            ),
          ],
        }),
        {}
      )

      setAddressOptions(addresses)
    }

    setIsAddressLoading(false)
  }, [availableNetworks, balance])

  const handleNetworkChange = (value) => {
    setNetwork(value)
    setAddress(null)
  }

  const handleAddressChange = (value) => {
    setAddress(value.id)
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      await nonceApi.setNonce(network.name, address, nonce)

      setPopup((prev) => ({
        ...prev,
        open: true,
        message: 'Nonce saved!',
        severity: 'success',
      }))
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `${err}`, severity: 'error' }))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handlePopupClose = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <div>
      <div className={'flex-row flex-wrap'} style={{ gap: 12 }}>
        <div style={{ width: '200px' }}>
          <Select
            autoSize
            menuPlacement='auto'
            menuPosition='fixed'
            name='Network'
            options={availableNetworks}
            value={network}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            onChange={handleNetworkChange}
            cacheOptions
            defaultOptions
            placeholder={'Choose network'}
            styles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            id='network_select'
          />
        </div>
        <div style={{ width: '200px' }}>
          <Select
            autoSize
            menuPlacement='auto'
            menuPosition='fixed'
            name='Address'
            options={addressOptions[network?.id]?.map((id) => ({ id })) || []}
            value={address ? [{ id: address }] : []}
            getOptionLabel={(x) => x.id}
            getOptionValue={(x) => x.id}
            onChange={handleAddressChange}
            cacheOptions
            defaultOptions
            placeholder={'Choose address'}
            isLoading={isAddressLoading}
            styles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999, wordBreak: 'break-all' }),
            }}
            id='address_select'
          />
        </div>
        <Link
          target="_blank"
          component={Button}
          href={address && getAddressPage(availableNetworksMap, network.id, address)}
          disabled={!address}
        >
          Open
        </Link>
        <TextField
          type='number'
          size='small'
          label='nonce'
          style={{ height: 36, width: 120 }}
          value={nonce}
          onChange={(e) => setNonce(e.target.value)}
          id='nonce_input_field'
        />
        <Button onClick={handleSubmit} disabled={isSubmitting} color='primary' id='set_nonce_button'>
          Set
        </Button>
      </div>
      <Snackbar
        open={popup.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handlePopupClose}
      >
        <Alert onClose={handlePopupClose} severity={popup.severity}>
          <div className='column' style={{ whiteSpace: 'pre-wrap' }}>
            {popup.message}
          </div>
        </Alert>
      </Snackbar>
    </div>
  )
}

export default NonceForm
