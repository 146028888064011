import moment from 'moment'
import QueryFilter from 'components/Table/components/QueryFilter'
import { IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import { sumBy } from 'lodash'

import { getTxPage } from 'utilities/get-tx-page'

import { TIME_PLACEHOLDER } from '../hooks'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

const timeFormat = (time, format) => {
  if (time !== TIME_PLACEHOLDER) {
    if (moment.isMoment(time)) {
      return time.local().format(format)
    }

    return moment.utc(time, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').local().format(format)
  }

  return TIME_PLACEHOLDER
}

export const getColumns = (networks) => [
  {
    Header: '',
    accessor: 'groupId',
    disableFilters: true,
    Cell: () => null,
  },
  {
    Header: 'Request ID',
    accessor: 'requestId',
    Filter: renderFilter,
    aggregate: 'sum',
    Cell: ({ row, value }) => {
      if (row.isGrouped) {
        const str = row.leafRows[0].original.groupId

        // return str?.substring(0, str.indexOf('_'))
        return str
      }

      return <span>{value?.replace(row.original.groupId, '').slice(1)}</span>
    },
  },
  {
    Header: 'Asset',
    accessor: 'pair',
    Filter: renderFilter,
    filter: (rows, id, filterValue) => {
      const rowsIncludeSameGroupId = new Set()

      for (const row of rows) {
        if (row.values[id] && row.values[id].toLowerCase().includes(filterValue.toLowerCase())) {
          rowsIncludeSameGroupId.add(row.original.groupId)
        }
      }

      return rows.filter(({ original }) => rowsIncludeSameGroupId.has(original.groupId))
    },
    aggregate: (leafRows) => {
      const coin = leafRows.find((leaf) => !/.*-[1,2]$/.test(leaf))
      const coin1 = leafRows.find((leaf) => /.*-1$/.test(leaf))?.slice(0, -2)
      const coin2 = leafRows.find((leaf) => /.*-2$/.test(leaf))?.slice(0, -2)

      if (!coin && coin1 && coin2) {
        try {
          const [c1] = coin1.split(':')
          const [c2] = coin2.split(':')

          return `${c1}:${c2}`
        } catch (e) {
          return '-'
        }
      }

      return coin
    },
    Cell: ({ value }) => {
      if (/.*-[1,2]$/.test(value)) {
        return <span>{value.slice(0, -2).split(':')[0]}</span>
      }

      return <span>{value?.split(':')?.[0]}</span>
    },
  },
  {
    Header: 'Direction',
    accessor: 'transaction.requestData.direction',
    disableFilters: true,
    aggregate: (leafRows) => {
      if (leafRows.includes('BL')) {
        return 'BL'
      }

      const buyCount = leafRows.filter((direction) => direction === 'BUY')
      const sellCount = leafRows.filter((direction) => direction === 'SELL')

      return sellCount.length > buyCount.length ? 'SELL' : 'BUY'
    },
  },
  {
    Header: 'Network',
    accessor: 'transaction.requestData.network',
    Filter: renderFilter,
    filter: (rows, id, filterValue) => {
      const rowsIncludeSameGroupId = new Set()

      for (const row of rows) {
        if (row.values[id]?.toLowerCase().includes(filterValue.toLowerCase())) {
          rowsIncludeSameGroupId.add(row.original.groupId)
        }
      }

      return rows.filter(({ original }) => rowsIncludeSameGroupId.has(original.groupId))
    },
    aggregate: (leaf) => {
      return leaf.find((v) => v !== '-') || '-'
    },
  },
  {
    Header: 'Exchange',
    accessor: 'transaction.requestData.exchangeFrom',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== '-') || '-'
    },
    filter: (rows, id, filterValue) => {
      const rowsIncludeSameGroupId = new Set()

      for (const row of rows) {
        if (row.values[id]?.toLowerCase().includes(filterValue.toLowerCase())) {
          rowsIncludeSameGroupId.add(row.original.groupId)
        }
      }

      return rows.filter(({ original }) => rowsIncludeSameGroupId.has(original.groupId))
    },
    Filter: renderFilter,
  },
  {
    Header: 'CEX',
    accessor: 'transaction.requestData.exchangeTo',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== '-') || '-'
    },
    filter: (rows, id, filterValue) => {
      const rowsIncludeSameGroupId = new Set()

      for (const row of rows) {
        const lowerCaseValue = row.values?.[id] && row.values?.[id]?.toLowerCase();

        if (lowerCaseValue?.includes(filterValue?.toLowerCase())) {
          rowsIncludeSameGroupId.add(row.original.groupId)
        }
      }

      return rows.filter(({ original }) => rowsIncludeSameGroupId.has(original.groupId))
    },
    Filter: renderFilter,
    Cell: ({ value, row }) => {
      if (row?.original?.cexFee === 0) {
        return <span>-</span>
      }

      return <span>{value}</span>
    },
  },
  {
    Header: 'Sum bought',
    accessor: 'usdtSpent',
    aggregate: 'sum',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(3)}</span> : ''),
    Aggregated: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
    disableFilters: true,
  },
  {
    Header: 'Sum sold',
    accessor: 'usdtReceived',
    aggregate: 'sum',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(3)}</span> : ''),
    Aggregated: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
    disableFilters: true,
  },
  {
    Header: 'DEX fee',
    accessor: 'networkFeeUsd',
    aggregate: 'sum',
    disableFilters: true,
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(5)}</span> : ''),
    Aggregated: ({ value }) => (value ? <span>{(value * 1).toFixed(3)}</span> : ''),
    // aggregate: (leaf) => {
    //   return leaf.find((v) => v !== null) || null
    // },
  },
  {
    Header: 'CEX fee',
    accessor: 'cexFee',
    aggregate: 'sum',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(3)}</span> : ''),
    Aggregated: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
    disableFilters: true,
  },
  {
    Header: 'Profit $',
    accessor: 'total',
    aggregate: 'sum',
    Cell: ({ value }) => <span style={{ color: value < 0 ? 'red' : 'green' }}>{(value * 1).toFixed(3)}</span>,
    Aggregated: ({ value }) => <span style={{ color: value < 0 ? 'red' : 'green' }}>{(value * 1).toFixed(2)}</span>,
    disableFilters: true,
    sortType: 'alphanumericCustom',
  },
  {
    Header: 'Profit %',
    accessor: 'profitRaw',
    aggregate: 'sum',
    Cell: ({ row }) => {
      if (row.isGrouped) {
        const totalProfitUsd = sumBy(row.leafRows, 'original.profitUsd')
        const totalBaseUsd = sumBy(row.leafRows, 'original.baseUsd')
        const num = (totalProfitUsd / totalBaseUsd) * 100

        return <span>{isNaN(num) || !isFinite(num) ? '-' : num.toFixed(3) + '%'}</span>
      }

      const profitUsd = row.original.profitUsd
      const baseUsd = row.original.baseUsd
      const num = (profitUsd / baseUsd) * 100

      return <span>{isNaN(num) || !isFinite(num) ? '-' : num.toFixed(3) + '%'}</span>
    },
    disableFilters: true,
  },
  {
    Header: 'Date',
    accessor: 'transaction.createdAt',
    sortType: 'momentSort',
    aggregate: (leaf) => {
      return leaf[0]
    },
    Cell: ({ value }) => {
      return <span className='nowrap'>{timeFormat(value, 'YYYY-MM-DD HH:mm.ss.SSS')}</span>
    },
    disableFilters: true,
  },
  {
    Header: 'DEX Request Time',
    accessor: 'transaction.dexRequestTime',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== TIME_PLACEHOLDER) || TIME_PLACEHOLDER
    },
    Cell: ({ value }) => <span>{timeFormat(value, 'HH:mm.ss.SSS')}</span>,
    disableFilters: true,
  },
  {
    Header: 'DEX Response Time',
    accessor: 'transaction.dexResponseTime',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== TIME_PLACEHOLDER) || TIME_PLACEHOLDER
    },
    Cell: ({ value }) => <span>{timeFormat(value, 'HH:mm.ss.SSS')}</span>,
    disableFilters: true,
  },
  {
    Header: 'DEX Duration',
    accessor: 'dexDuration',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== TIME_PLACEHOLDER) || TIME_PLACEHOLDER
    },
    disableFilters: true,
  },
  {
    Header: 'CEX Request Time',
    accessor: 'transaction.cexRequestTime',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== TIME_PLACEHOLDER) || TIME_PLACEHOLDER
    },
    Cell: ({ value, row }) => {
      if (row?.original?.cexFee === 0) {
        return <span>-</span>
      }

      return <span>{timeFormat(value, 'HH:mm.ss.SSS')}</span>
    },
    disableFilters: true,
  },
  {
    Header: 'CEX Response Time',
    accessor: 'transaction.cexResponseTime',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== TIME_PLACEHOLDER) || TIME_PLACEHOLDER
    },
    Cell: ({ value }) => <span>{timeFormat(value, 'HH:mm.ss.SSS')}</span>,
    disableFilters: true,
  },
  {
    Header: 'CEX Duration',
    accessor: 'cexDuration',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== TIME_PLACEHOLDER) || TIME_PLACEHOLDER
    },
    disableFilters: true,
  },
  {
    Header: 'Total Duration',
    accessor: 'totalDuration',
    aggregate: (leaf) => {
      return leaf.find((v) => v !== TIME_PLACEHOLDER) || TIME_PLACEHOLDER
    },
    disableFilters: true,
  },
  {
    Header: 'Tx Hash',
    accessor: 'transaction.dexResponse.txHash',
    aggregate: (leaf) => {
      return leaf.find((v) => v)
    },
    Cell: ({ value, row }) => {
      const [isCopied, setIsCopied] = useState(false)

      const handleCopy = () => {
        navigator.clipboard.writeText(value)
        setIsCopied(true)
      }

      // if (!value?.startsWith('0x')) {
      //   return (
      //     <div className='flex-row align-center'>
      //       <div className='ellipsis' style={{ maxWidth: 120 }}>
      //         {value}
      //       </div>
      //       <Tooltip arrow title={isCopied ? 'Copied!' : 'Copy Hash to clipboard'}>
      //         <IconButton size='small' onClick={handleCopy}>
      //           <i className='fas fa-clipboard fa-sm' />
      //         </IconButton>
      //       </Tooltip>
      //     </div>
      //   )
      // }

      const page = getTxPage(networks, row.values['transaction.requestData.network'], value)

      if (!page || page.includes('undefined')) {
        return <span>{value}</span>
      }

      return (
        <div className='flex-row align-center'>
          <a href={page} target={'_blank'} rel='noreferrer'>
            <div className='ellipsis' style={{ maxWidth: 120 }}>
              {value}
            </div>
          </a>
          <Tooltip arrow title={isCopied ? 'Copied!' : 'Copy Hash to clipboard'}>
            <IconButton size='small' onClick={handleCopy}>
              <i className='fas fa-clipboard fa-sm' />
            </IconButton>
          </Tooltip>
        </div>
      )
    },
    disableFilters: true,
  },
]

const baseColumns = getColumns({})
const columnsMap = baseColumns.reduce((acc, column) => {
  acc[column.accessor] = column

  return acc
}, {})

export const COLUMNS = [
  columnsMap.groupId,
  {
    ...columnsMap.requestId,
    disableFilters: true,
  },
  {
    ...columnsMap['transaction.createdAt'],
    disableFilters: true,
  },
  {
    ...columnsMap.pair,
    disableFilters: true,
  },
  columnsMap['transaction.requestData.direction'],
  columnsMap['transaction.requestData.network'],
  columnsMap.usdtSpent,
  columnsMap.cexFee,
  columnsMap.usdtReceived,
  columnsMap.total,
  columnsMap.profitRaw,
]
