import styled from '@emotion/styled'
import { Paper } from '@mui/material'
import Table from 'components/Table/Table'

export const Container = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
`;

export const StyledTable = styled(Table)`
  td {
    padding: 0;
  }

  th {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  tbody tr:first-of-type {
    background: #83b5e7;
  }

  tbody tr:hover {
    background: #d6eaff;

    td:last-of-type {
      background: #83b5e7;
    }
  }
`

export const CoinCell = styled.div`
  padding: 4px 6px;
`

export const FlexCell = styled.div`
  padding: 2px;
  display: flex;
  line-height: 14px;
  flex-direction: column;
`
