import { getIsAuth } from 'storage/user-storage'

import { awsCallTokenRefresh, getAwsToken } from '../api/auth-api'

import { useInterval } from './use-interval'
import { useLocalStorage } from './use-local-storage'

async function refreshMe() {
  const refreshToken = localStorage.getItem('refresh')

  await getAwsToken(() => awsCallTokenRefresh('refresh_token', refreshToken).catch((err) => console.error(err)))
  console.log('refresh token success')
}

export const useRefreshToken = () => {
  const [expiresIn] = useLocalStorage('expires_in', 3600000)

  useInterval(() => {
    if (getIsAuth()) {
      refreshMe()
    }
  }, expiresIn - 300000) // sends refresh request 5 minutes earlier before token expires
}
