import { useQuery } from '@tanstack/react-query'
import { fetch } from '.'

export const opportunitiesApi = {
  getCountList: () => {
    return fetch
      .get(`opportunities-count`)
      .json()
      .catch((err) => console.log(err))
  },
}

export const useOpportunities = () => {
  const query = useQuery({
    queryKey: ['opportunities'],
    queryFn: () => opportunitiesApi.getCountList(),
    cacheTime: 60000 * 5, // 5 minutes
    staleTime: 60000 * 5, // 5 minutes
  })

  return query
}
