import IconButton from '@mui/material/IconButton'

import { ACTION } from '../hooks'

export const CreateRuleBtn = ({ setSelectedRule }) => (
  <>
    Seeker Rules
    <IconButton
      onClick={() => {
        setSelectedRule({ _type: ACTION.CREATE })
      }}
      size={'small'}
      style={{ margin: '0 8px', color: '#1976d2' }}
    >
      <i className='fas fa-plus' />
    </IconButton>
  </>
)
