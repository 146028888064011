import Dialog from '@mui/material/Dialog'

import { Rule } from './Rule'

export const RuleDialog = ({ onClose, value, save }) => {
  return (
    <Dialog onClose={onClose} open={!!value}>
      <Rule
        rule={value}
        close={onClose}
        save={save}
      />
    </Dialog>
  )
}
