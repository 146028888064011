import moment from 'moment'
import { IconButton, Switch, Tooltip, FormControlLabel, Checkbox } from '@mui/material'
import styled from '@emotion/styled'

import { ARCHIVED, UNARCHIVED } from './BotTable'
import { WhiteTooltip } from 'components/Tooltip'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'components/Button/Button'
import { formatLongNumbers } from 'utilities/number-helpers'
import s from './BotTable.module.css'
import { NotesCell } from './NotesCell'
import { toggleBotRule } from 'api/bot-api'

const NETWORK_LABELS = {
  'binanceSmartChain': 'bsc',
}

const CellRows = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    flex: 1 1 0px;
    padding-top: 5px;

    &:not(:last-child) {
      border-bottom: 1px dashed lightgrey;
      padding-bottom: 5px;
      padding-top: 0;
    }
  }

  a {
    text-decoration: none;
  }
`

const SSwitch = styled(Switch)`
  display: flex;
  margin-left: 10px;
`

const GRAFANA_BASE_URL =
  'https://h-monitoring.internal-fun-chegonibudj.com/d/c5b63f4c-b99a-49c6-b9d2-6ad8893b6109/arbitrage-windows'

const SELECT_COLUMN = () => ({
  Header: ({ meta }) => {
    return <Checkbox checked={meta.selectedAll} onChange={(e) => meta.selectAllBots(e.target.checked)} />
  },
  id: 'select-action',
  accessor: 'select-action',
  disableSortBy: true,
  Cell: ({ row, selectBot, selectedBots }) => {
    return (
      <Checkbox
        checked={selectedBots.includes(row.original.id)}
        onChange={(e) => selectBot(row.original.id, e.target.checked)}
      />
    )
  },
})

const ACTIONS_COLUMN = {
  Header: 'Actions',
  id: 'actions',
  accessor: 'key',
  disableSortBy: true,
  Cell: ({ value, row, ...cell }) => {
    const isOn = row.original.status

    return (
      <>
        <Tooltip title={`Press to ${isOn ? 'Deactivate' : 'Activate'}`}>
          <SSwitch
            size='small'
            color='primary'
            checked={isOn}
            onChange={() => {
              cell.switchBotStatus(row.original.id, row.original.botId, isOn)
            }}
          />
        </Tooltip>
        <Tooltip title={'Archive'}>
          <span>
            <IconButton
              onClick={(e) => {
                cell.openConfirmArchivation(e, row.original.id)
              }}
              size={'small'}
              style={{ margin: '0 8px' }}
              disabled={cell.botsType === ARCHIVED}
            >
              <i className={`fas fa-archive blue`} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title='Edit'>
          <IconButton
            onClick={() => {
              cell.openBotEditor({ id: row.original.id })
            }}
            size={'small'}
            style={{ margin: '0 8px' }}
          >
            <i className='fas fa-pen' />
          </IconButton>
        </Tooltip>
      </>
    )
  },
}

const BOT_LEVELS_COLUMN = {
  Header: 'Leveling Rules',
  id: 'leveling_rules',
  accessor: 'leveling_rules',
  disableSortBy: true,
  Cell: ({ value = [], row, ...cell }) => {
    const times = Object.keys(row.original.opportunity || {})?.length || Object.keys(value)?.length

    return (
      <CellRows>
        {[...Array(times)].map((_, i) => {
          const rule = value[i]

          if (!rule) {
            return <div key={i}>&nbsp;</div>
          }

          const isCex = /_spot/gi.test(rule.from)

          return (
            <Tooltip key={rule.from} title={rule.from}>
              <div style={{ transform: 'scale(0.8)' }}>
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      color='primary'
                      checked={rule.enabled}
                      onChange={async () => {
                        if (rule.enabled) {
                          await cell.disableBotRule(rule.id)
                        } else {
                          await cell.enableBotRule(rule.id)
                        }

                        await toggleBotRule(row.original.botId, rule.id, !rule.enabled, cell.botsQueryKey)
                      }}
                    />
                  }
                  label={isCex ? 'CEX' : 'DEX'}
                />
              </div>
            </Tooltip>
          )
        })}
      </CellRows>
    )
  },
}

const columns = (now, unArchived) => [
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => {
      const navigate = useNavigate()
      const location = useLocation();

      const handleClick = (value) => {
        if (location.pathname.includes('/bot-details')) {
          navigate(`/bot-details/${value}`);
        } else {
          window.open(`/bot-details/${value}`, '_blank');
        }
      };

      return (
        <Button onClick={() => handleClick(value)}>{value}</Button>
      )
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => {
      return value.slice(0, 1)
    },
  },
  {
    Header: 'DEX pair',
    accessor: 'dex_symbol_left',
    Cell: ({ value, row }) => {
      if (row.original.type === 'CLASSIC') {
        return value && row.original.dex_symbol_right ? `${value}-${row.original.dex_symbol_right}` : ''
      }
      const arb = row.original.triple_arbitrage[0]

      return arb ? `${arb.step1?.left}-${arb.step1?.right}` : null
    },
  },
  {
    Header: 'CEX pair',
    accessor: 'cex_symbol_left',
    Cell: ({ value, row }) => {
      if (row.original.type === 'CLASSIC') {
        return value && row.original.cex_symbol_right ? `${value}-${row.original.cex_symbol_right}` : ''
      }
      const arb = row.original.triple_arbitrage[0]

      return arb ? (
        <div>
          <div>
            {arb.step2?.left}-{arb.step2?.right}
          </div>
          <div>
            {arb.step3?.left}-{arb.step3?.right}
          </div>
        </div>
      ) : null
    },
  },
  {
    Header: 'Network',
    accessor: 'network_to',
    Cell: ({ row }) => {
      let network = ''

      if (row.original.type === 'CLASSIC') {
        network = row.original.classic_arbitrage?.[0]?.dex?.network
      } else {
        network = row.original.triple_arbitrage?.[0]?.step1?.network
      }

      return <span className="break-all width-50">{NETWORK_LABELS[network] || network}</span>
    },
  },
  {
    Header: 'Exchange',
    accessor: 'exchange_from.name',
    Cell: ({ value, row }) => {
      if (row.original.type === 'CLASSIC') {
        return <span className="break-all width-40">{value}</span> || null
      }
      const arb = row.original.triple_arbitrage[0]

      return arb?.step1?.exchange ? <span className="break-all width-40">{arb.step1.exchange}</span> : null
    },
  },
  {
    Header: 'CEX',
    accessor: 'exchange_to.name',
    Cell: ({ value, row }) => {
      if (row.original.type === 'CLASSIC') {
        return value?.replace('_SPOT', '') || null
      }
      const arb = row.original.triple_arbitrage[0]

      return (
        <div>
          <div>{arb?.step2?.exchange?.replace('_SPOT', '') || null}</div>
          <div>{arb?.step3?.exchange?.replace('_SPOT', '') || null}</div>
        </div>
      )
    },
  },
  {
    Header: 'PMin',
    accessor: 'pmin',
  },
  {
    Header: 'CEX mltp',
    accessor: 'cex_multiplier',
  },
  {
    Header: 'Profit',
    accessor: 'profit',
    Cell: ({ value, row }) => {
      return (
        <CellRows>
          {value?.map((_value, key) => (
            <div key={_value?.profit + key}>{_value?.profit}</div>
          ))}
        </CellRows>
      )
    },
  },
  {
    Header: 'Amount',
    accessor: 'qn',
    Cell: ({ value, row }) => (
      <CellRows>
        {value?.map((_value, key) => (
          <div className={s.longNum} key={_value?.amount + key}>{formatLongNumbers(_value?.amount)}</div>
        ))}
      </CellRows>
    ),
  },
  {
    Header: 'Current arbitrage',
    accessor: 'opportunity',
    Cell: ({ row, value = {}, ...cell }) => (
      <CellRows>
        {Object.keys(value).map((key) => (
          <div onClick={() => {
            cell.openGraph(row.original.external_id, row.original.id, value[key].id, value[key].side)
          }} key={key} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
            <span className='dashed'>
              {value[key]?.side?.toLowerCase().slice(0, 1)}
              &nbsp;
              {parseFloat(value[key]?.profit).toFixed(3)}
            </span>
          </div>
        ))}
      </CellRows>
    ),
  },
  {
    Header: 'DEX price',
    accessor: 'dexPrice',
    Cell: ({ row }) => (
      <CellRows>
        {row.original.opportunity
          ? Object.keys(row.original.opportunity).map((key) => (
            <div key={key}>{parseFloat(row.original.opportunity[key].dexPrice).toFixed(4)}</div>
          ))
          : ''}
      </CellRows>
    ),
  },
  {
    Header: 'DEX total',
    accessor: 'dexTotal',
    Cell: ({ row }) => (
      <CellRows>
        {row.original.opportunity
          ? Object.keys(row.original.opportunity).map((key) => (
            <div key={key}>{parseFloat(row.original.opportunity[key].dexTotal).toFixed(4)}</div>
          ))
          : ''}
      </CellRows>
    ),
  },
  {
    Header: 'Signals',
    accessor: 'opportunities',
    sortType: 'signalSort',
    // disableSortBy: true,
    Cell: ({ value = {}, row }) => {
      const times = Object.keys(row.original.opportunity || {})?.length || Object.keys(value)?.length
      const link1 = `${GRAFANA_BASE_URL}?orgId=1&from=now-24h&to=now&var-bot_id=${row.original.id}`
      const link2 = `${GRAFANA_BASE_URL}?orgId=1&from=now-7d&to=now&var-bot_id=${row.original.id}`

      return (
        <CellRows>
          {[...Array(times)].map((_, i) => {
            const val = value[i]

            if (!val) {
              return <div key={i}>&nbsp;</div>
            }

            return (
              <Tooltip title='Signals for this rule for today+previous 6 days' key={i}>
                <div>
                  <a href={link1} target='_blank' rel='noreferrer'>
                    {val.today}
                  </a>
                  &nbsp;+&nbsp;
                  <a href={link2} target='_blank' rel='noreferrer'>
                    {val.previous_days}
                  </a>
                </div>
              </Tooltip>
            )
          })}
        </CellRows>
      )
    },
  },
  {
    Header: 'Orders',
    accessor: 'orders',
    sortType: 'signalSort',
    // disableSortBy: true,
    Cell: ({ value = {}, row }) => {
      const times = Object.keys(row.original.opportunity || {})?.length || Object.keys(value)?.length

      return (
        <CellRows>
          {[...Array(times)].map((_, i) => {
            const val = value[i]

            if (!val) {
              return <div key={i}>&nbsp;</div>
            }

            return (
              <Tooltip title='Orders for this rule for today+previous 6 days' key={i}>
                <div>
                  {val.today}&nbsp;+&nbsp;{val.previous_days}
                </div>
              </Tooltip>
            )
          })}
        </CellRows>
      )
    },
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    disableSortBy: true,
    Cell: ({ value, row, botsQueryKey }) => {
      return <NotesCell value={value} botId={row.original.botId} id={row.original.id} type={row.original.type} botsQueryKey={botsQueryKey} />
    },
  },
  ...(unArchived
    ? [
      {
        Header: 'NSF',
        accessor: 'bot_not_enough_balance',
        Cell: ({ value }) => {
          return (
            <CellRows>
              <div>{value?.[0] ? `${value[0].today} + ${value[0]?.previous_days}` : ''}</div>
              <div>{value?.[1] ? `${value[1].today} + ${value[1]?.previous_days}` : ''}</div>
            </CellRows>
          )
        },
      },
      {
        Header: 'Error',
        accessor: 'bot_error',
        sortType: 'botErrorSort',
        Cell: ({ value }) => {
          if (value && value.error) {
            return (
              <WhiteTooltip title={value.error}>
                <div>
                  {value.error.includes('balance') && (
                    <i className='fab fa-creative-commons-nc fa-2x fa-pull-left'></i>
                  )}
                  {value.error.includes('failed to get output amount') && (
                    <i className='fas fa-blind fa-2x fa-pull-left'></i>
                  )}
                  {value.error.includes('Orderbook is not so deep') && (
                    <i className='fas fa-sort-amount-down fa-2x fa-pull-left'></i>
                  )}
                  {value.error.includes('execution reverted') && (
                    <i className='fas fa-arrows-alt-h fa-2x fa-pull-left'></i>
                  )}
                  {value.error.includes('Invalid arguments') && (
                    <i className='fas fa-exclamation-triangle fa-2x fa-pull-left'></i>
                  )}
                  {value.error.includes('amountOut is zero') && (
                    <i className='fab fa-creative-commons-zero fa-2x fa-pull-left'></i>
                  )}
                  {value.error.includes('downstream duration timeout') && (
                    <i className='far fa-clock fa-2x fa-pull-left'></i>
                  )}
                  {value.error.includes('context deadline exceeded') && (
                    <i className='fas fa-book-dead fa-2x fa-pull-left'></i>
                  )}
                  {value.moment.from(now)}
                </div>
              </WhiteTooltip>
            )
          }

          return <span />
        },
      },
    ]
    : []),
]

export const getColumns = (type, selectAllBots, selectedAll, skipColumns) => {
  const now = moment()

  if (type === UNARCHIVED) {
    return [
      SELECT_COLUMN(selectAllBots, selectedAll),
      ACTIONS_COLUMN,
      BOT_LEVELS_COLUMN,
      ...columns(now, type === UNARCHIVED),
    ].filter((column) => !skipColumns.includes(column.accessor))
  }

  return columns(now, type === UNARCHIVED).filter((column) => !skipColumns.includes(column.accessor))
}
