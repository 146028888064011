import { fetch } from '.'

export const marketVolatilityApi = {
  // startTime, endTime, page, pageSize
  list: (searchParams) => {
    return fetch
      .get(`market-volatility`, { searchParams })
      .json()
      .catch((err) => console.log(err))
  },
}

export default marketVolatilityApi
