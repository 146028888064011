import React from 'react'
import { useFullScreenHandle } from 'react-full-screen'

export const FullscreenContext = React.createContext()

const FullscreenProvider = ({ children }) => {
  const container = useFullScreenHandle()

  return <FullscreenContext.Provider value={{ container }}>{children}</FullscreenContext.Provider>
}

export default FullscreenProvider
