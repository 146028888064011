import { useMemo } from 'react'
import Button from 'components/Button/Button'
import { Paper, Typography, Select, MenuItem } from '@mui/material'
import useFullscreen from 'hooks/use-fullscreen'
import { useNetworksSelector } from 'redux/selectors/settings'

import s from '../../BotSignals.module.css'
import Table from '../../../../components/Table/Table'

import { getColumns } from './columns'
import { useHistoryApi, usePagination, useSort, PAGE_SIZES } from './hooks'

const HistoryTable = () => {
  useFullscreen()

  const availableNetworks = useNetworksSelector()
  const columns = useMemo(() => getColumns(availableNetworks), [availableNetworks])

  const [page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize, tableRef] = usePagination()
  const [params, handleSortChange] = useSort(setStartPage)

  const [data, isFetching, isLastPage] = useHistoryApi(page, pageSize, params)

  return (
    <Paper className={s.paper} elevation={2} id='bots_history_table'>
      <Typography variant={'h5'} style={{ padding: '0 10px' }}>
        Bots History
      </Typography>
      <Table
        tableRef={tableRef}
        columns={columns}
        data={data}
        loading={isFetching}
        tableStyle={{ maxHeight: '600px', minHeight: '500px' }}
        handleSortChange={handleSortChange}
        enableFilters
        manualSortBy
        manualFilters
        multiSelectFilters={false}
        autoSubmitFilters={false}
      />
      <div className={s.pagination}>
        <Button onClick={decrementPage} disabled={isFetching || page === 1} id='prev_page'>
          Prev
        </Button>
        <div>{page}</div>
        <div>
          <Select value={pageSize} onChange={changePageSize}>
            {PAGE_SIZES.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={incrementPage} disabled={isFetching || isLastPage} id='next_page'>
            Next
          </Button>
        </div>
      </div>
    </Paper>
  )
}

export default HistoryTable
