import { useCallback, useEffect, useState, useMemo } from 'react'
import { Paper, Typography } from '@mui/material'
import Button from 'components/Button/Button'
import moment from 'moment'
import cx from 'classnames'
import Table from 'components/Table/Table'
import { useDailyProfitQuery } from 'api/bot-analytics'
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'

import s from './DailyProfit.module.css'
import { COLUMNS } from './columns'

export const DailyProfitToggle = ({ active, onClick }) => {
  return (
    <button className={cx(s.toggleBtn, active && s.toggleBtnActive)} onClick={onClick}>
      daily profit <i className='fas fa-chevron-right' />
    </button>
  )
}

export const DailyProfitTable = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [periodToFetch, setFetchPeriod] = useState(null)
  const { data, isFetching } = useDailyProfitQuery(periodToFetch)
  const [anchorEl, setAnchorEl] = useState(null)
  const [period, setPeriod] = useState({
    startDate: moment().subtract(1, 'M').startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    key: 'selection',
  })

  const openRangePicker = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeRangePicker = () => {
    setAnchorEl(null)
  }

  const handleRangeChange = (newValue) => {
    setPeriod(newValue.selection)
  }

  const handleConfirmRange = () => {
    fetchPeriod(period.startDate, period.endDate)
    closeRangePicker()
  }

  const fetchPeriod = useCallback(async (dirtyStartTime, dirtyEndTime) => {
    const startTime = moment(new Date(dirtyStartTime)).format('YYYY-MM-DD')
    const endTime = moment(new Date(dirtyEndTime)).format('YYYY-MM-DD')

    await setFetchPeriod({ startTime, endTime })
  }, [])

  useEffect(() => {
    fetchPeriod(period.startDate, period.endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const datePickerLabel = useMemo(() => {
    const start = moment(period.startDate).format('DD/MM/yyyy')
    const end = moment(period.endDate).format('DD/MM/yyyy')

    return `from: ${start} to: ${end}`
  }, [period])

  return (
    <Paper className={s.paper + ' flex-column'} elevation={2}>
      <div className={s.heading}>
        <Typography variant={'h5'} className={s.rangeButtons}>
          Daily Profit
          <div className='flex-row flex-wrap'>
            <Button
              onClick={openRangePicker}
            >
              {datePickerLabel}
            </Button>
          </div>
          <DateRangePicker
            anchorEl={anchorEl}
            value={period}
            handleChange={handleRangeChange}
            handleConfirm={handleConfirmRange}
            handleClose={closeRangePicker}
          />
        </Typography>
      </div>
      <Table data={data} columns={columns} loading={isFetching} />
    </Paper>
  )
}

export default DailyProfitTable
