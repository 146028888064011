import { Adjust } from '@mui/icons-material'
import { Alert, Grid } from '@mui/material'

export const ExpectedBalanceWarning = ({ isFetching, data }) => {
  // convert data array to object
  const factual = data.find((item) => item.name === 'Factual')?.values
  const warningObject = []

  if (!factual) {
    return null
  }

  Object.keys(factual).forEach((key) => {
    if (factual[key].percentage >= 5 || factual[key].percentage <= -5) {
      warningObject.push({
        token: key,
        diff: factual[key].percentage,
      })
    }
  })

  warningObject.sort((a, b) => b.diff - a.diff);

  return (
    <>
      {!isFetching && (
        <Grid container columns={{ sm: 1, md: 2, lg: 3 }}>
          {warningObject.map((item) => (
            <Grid item sm={1} md={1} lg={1} key={item.token}>
              <Alert style={{ width: '100%' }} icon={<Adjust fontSize='inherit' />} severity='info'>
                <strong>{item.token}</strong> balance is different from expected: ({item.diff}%)
              </Alert>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}
