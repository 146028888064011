import { FormControl, InputAdornment, InputLabel, TextField } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import Select from 'react-select'

import s from './BotEditor.module.css'
import { Directions } from './components/Directions'

export const BotEditorSimple = ({
  values,
  handleChange,
  archived,
  handlePairQueryChange,
  changeAmountState,
  createAmountFields,
  setValues,
  dexExchanges,
  cexExchanges,
  networks,
}) => {
  return (
    <div className='flex-row' style={{ gap: 10 }}>
      <div className='column'>
        <FormControl style={{ marginBottom: 8 }}>
          <InputLabel className={s.inputLabel} htmlFor={'dex_symbol_left'}>
            DEX left
          </InputLabel>
          <TextField
            className={s.input}
            name='dex_symbol_left'
            value={values.dex_symbol_left}
            onChange={handleChange}
            size='small'
            id='dex_symbol_left'
            inputProps={{ min: 0 }}
            disabled={archived}
          />
        </FormControl>
        <FormControl style={{ marginBottom: 8 }}>
          <InputLabel className={s.inputLabel} htmlFor={'dex_symbol_right'}>
            DEX right
          </InputLabel>
          <TextField
            className={s.input}
            name='dex_symbol_right'
            value={values.dex_symbol_right}
            onChange={handleChange}
            size='small'
            id='dex_symbol_right'
            inputProps={{ min: 0 }}
            disabled={archived}
          />
        </FormControl>
        <FormControl style={{ marginBottom: 8 }}>
          <InputLabel className={s.inputLabel} htmlFor={'cex_symbol_left'}>
            CEX left
          </InputLabel>
          <TextField
            className={s.input}
            name='cex_symbol_left'
            value={values.cex_symbol_left}
            onChange={handleChange}
            size='small'
            id='cex_symbol_left'
            inputProps={{ min: 0 }}
            disabled={archived}
          />
        </FormControl>
        <FormControl style={{ marginBottom: 8 }}>
          <InputLabel className={s.inputLabel} htmlFor={'cex_symbol_right'}>
            CEX right
          </InputLabel>
          <TextField
            className={s.input}
            name='cex_symbol_right'
            value={values.cex_symbol_right}
            onChange={handleChange}
            size='small'
            id='cex_symbol_right'
            inputProps={{ min: 0 }}
            disabled={archived}
          />
        </FormControl>
        <FormControl>
          <InputLabel className={s.inputLabel} htmlFor={`network`}>
            Network
          </InputLabel>
          <Select
            className={s.input}
            inputIt={`network`}
            styles={{
              control: (base) => ({ ...base, maxWidth: 150, minWidth: 120, marginBottom: 10 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            options={Object.values(networks)}
            value={values?.network}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
            onChange={(value) => {
              handleChange({ target: { name: `network`, value } })
              handleChange({ target: { name: `exchange_from`, value: undefined } })
            }}
            isDisabled={archived}
            id={`network_select`}
          />
        </FormControl>
        <FormControl>
          <InputLabel className={s.inputLabel} htmlFor={'from'}>
            Exchange
          </InputLabel>
          <Select
            className={s.input}
            inputIt='From'
            styles={{
              control: (base) => ({ ...base, maxWidth: 150, minWidth: 120, marginBottom: 10 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            options={
              values?.network?.id
                ? dexExchanges.filter(({ network_id }) => network_id === values.network.id)
                : dexExchanges
            }
            value={[values.exchange_from]}
            getOptionLabel={(x) => x.internal_name}
            getOptionValue={(x) => x.id}
            onChange={(value) => handleChange({ target: { name: 'exchange_from', value } })}
            onInputChange={handlePairQueryChange}
            isDisabled={archived || !values.network}
            id='from_select'
          />
        </FormControl>
        <FormControl style={{ marginBottom: 8 }}>
          <InputLabel className={s.inputLabel} htmlFor={'pool'}>
            Pool Address
          </InputLabel>
          <TextField
            className={s.input}
            name='pool'
            value={values.pool}
            onChange={handleChange}
            size='small'
            id='pool'
            inputProps={{ min: 0 }}
            disabled={archived}
          />
        </FormControl>
        <FormControl>
          <InputLabel style={{ position: 'static', transform: 'none', fontSize: 12 }} htmlFor={'to'}>
            CEX
          </InputLabel>
          <Select
            inputIt='to'
            styles={{
              control: (base) => ({ ...base, maxWidth: 150, minWidth: 120, marginBottom: 10 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            options={cexExchanges}
            value={[values.exchange_to]}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            onChange={(value) => handleChange({ target: { name: 'exchange_to', value } })}
            onInputChange={handlePairQueryChange}
            isDisabled={archived}
            id='to_select'
          />
        </FormControl>
      </div>
      <div className='column'>
        <div className={s.tripleBaseParamsColumn}>
          <FormControl style={{ marginBottom: 8 }}>
            <InputLabel className={s.inputLabel} htmlFor={'pmin'}>
              Pmin
            </InputLabel>
            <NumericFormat
              id='pmin'
              name='pmin'
              customInput={TextField}
              value={values.pmin}
              size='small'
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              decimalSeparator={'.'}
              onChange={handleChange}
              autoComplete='off'
              disabled={archived}
            />
          </FormControl>
          <FormControl style={{ marginBottom: 8 }}>
            <InputLabel className={s.inputLabel} htmlFor={'pmin'}>
              CEX Multiplier
            </InputLabel>
            <NumericFormat
              id='cex_multiplier'
              name='cex_multiplier'
              customInput={TextField}
              value={values.cex_multiplier}
              size='small'
              decimalSeparator={'.'}
              onChange={handleChange}
              autoComplete='off'
              disabled={archived}
            />
          </FormControl>
        </div>
        <Directions
          values={values}
          archived={archived}
          createAmountFields={createAmountFields}
          changeAmountState={changeAmountState}
          setValues={setValues}
        />
      </div>
    </div>
  )
}
