import { Popover } from '@mui/material'
import React, { useState } from 'react'

import QueryFilter from 'components/Table/components/QueryFilter'
import { formatDateTime } from 'utilities/number-helpers'
import { getTxPage } from 'utilities/get-tx-page'

const PopoverColumn = React.memo(({ value }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  if (!value) {
    return '-'
  }

  // const obj = JSON.parse(value)
  const obj = value
  const keys = Object.keys(obj)

  if (keys.length === 0) {
    return '-'
  }

  return (
    <div>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ whiteSpace: 'nowrap' }}
      >
        Hover here
      </div>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        container={document?.querySelector('.app')}
      >
        <div style={{ padding: '4px 10px' }}>
          {keys.map(
            (key) =>
              obj[key] && (
                <div key={key}>
                  <b>{key}:</b> {String(obj[key])};
                </div>
              )
          )}
        </div>
      </Popover>
    </div>
  )
})

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

export const getColumns = (availableNetworks) => ([
  {
    Header: '',
    id: 'txHash',
    accessor: 'DEX_CB_RESPONSE',
    Cell: ({ row }) => {
      const page = getTxPage(availableNetworks, row.original.REQUEST_DATA?.network, row.original.TX_INFO?.txHash)

      if (!page) {
        return <span>-</span>
      }

      return (
        <a href={page} target={'_blank'} rel='noreferrer'>
          Link
        </a>
      )
    },
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'REQUEST_ID',
    Filter: renderFilter,
  },
  {
    Header: 'Status',
    accessor: 'STATUS',
    Filter: renderFilter,
  },
  {
    Header: 'Err reason',
    accessor: 'ERROR_REASON',
    Cell: ({ value }) => <p style={value ? { minWidth: 150 } : {}}>{value ? value : '-'}</p>,
    Filter: renderFilter,
  },
  {
    Header: 'Request data',
    accessor: 'REQUEST_DATA',
    Cell: PopoverColumn,
    Filter: renderFilter,
  },
  {
    Header: 'Calculated params',
    accessor: 'CALCULATED_PARAMETERS',
    Cell: PopoverColumn,
    Filter: renderFilter,
  },
  {
    Header: 'DEX request',
    accessor: 'DEX_REQUEST',
    Cell: PopoverColumn,
    Filter: renderFilter,
  },
  {
    Header: 'DEX response',
    accessor: 'DEX_CB_RESPONSE',
    Cell: PopoverColumn,
    Filter: renderFilter,
  },
  {
    Header: 'CEX request',
    accessor: 'CEX_REQUEST',
    Cell: PopoverColumn,
    Filter: renderFilter,
  },
  {
    Header: 'CEX response',
    accessor: 'CEX_RESPONSE',
    Cell: PopoverColumn,
    Filter: renderFilter,
  },
  {
    Header: 'TX info',
    accessor: 'TX_INFO',
    Cell: PopoverColumn,
    Filter: renderFilter,
  },
  {
    Header: 'Date',
    accessor: 'CREATED_AT',
    Cell: ({ value }) => (value !== '1970-01-01T00:00:00Z' ? formatDateTime(value, true, true) : ''),
    Filter: renderFilter,
  },
  {
    Header: 'DEX request time',
    accessor: 'DEX_REQUEST_TIME',
    Cell: ({ value }) => (value !== '1970-01-01T00:00:00Z' ? formatDateTime(value, true, true) : ''),
    Filter: renderFilter,
  },
  {
    Header: 'DEX response time',
    accessor: 'DEX_CB_RESPONSE_TIME',
    Cell: ({ value }) => (value !== '1970-01-01T00:00:00Z' ? formatDateTime(value, true, true) : ''),
    Filter: renderFilter,
  },
  {
    Header: 'CEX request time',
    accessor: 'CEX_REQUEST_TIME',
    Cell: ({ value }) => (value !== '1970-01-01T00:00:00Z' ? formatDateTime(value, true, true) : ''),
    Filter: renderFilter,
  },
  {
    Header: 'CEX response time',
    accessor: 'CEX_RESPONSE_TIME',
    Cell: ({ value }) => (value !== '1970-01-01T00:00:00Z' ? formatDateTime(value, true, true) : ''),
    Filter: renderFilter,
  },
  // {
  //   Header: 'Duration',
  //   Cell: ({row}) => (value !== "1970-01-01T00:00:00Z" ? formatDateTime(value, true, true) : ''),
  //   Filter: renderFilter,
  // },
])
