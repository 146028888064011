import { Controller } from "react-hook-form"

import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const FIELD_OPTIONS = {
  validate: (v) => {
    if (typeof v === 'string') {
      return v.trim().length >= 1
    }

    return v !== null && v !== undefined
  },
  required: false,
}

function renderInput({ column, errors, watch, setValue, isRemoveForm }, { field }) {
  if (column.formProps.renderInput) {
    return column.formProps.renderInput({
      column,
      errors,
      field,
      isRemoveForm,
      watch,
      setValue,
    })
  }

  if (column.formProps.type === 'boolean') {
    return (
      <FormControlLabel
        sx={{ marginLeft: '10px' }}
        labelPlacement="start"
        control={<Checkbox
          {...field}
          disabled={isRemoveForm}
          checked={field.value}
          onChange={(e) => { field.onChange(e.target.checked) }}
        />}
        label={column.Header}
      />
    )
  }

  return (
    <TextField
      disabled={isRemoveForm || column.formProps.disabled}
      label={column.Header}
      error={!!errors[column.accessor]}
      helperText={errors[column.accessor] && "invalid"}
      fullWidth
      {...field}
      {...(column.formProps.transform ? { onChange: column.formProps.transform(field.onChange) } : {})}
    />
  )
}

export const ControllerField = ({ errors, isRemoveForm, setValue, watch, control, column }) => {
  if (column.formProps.hidden) {
    return null
  }

  return (
    <Controller
      key={column.accessor}
      name={column.accessor}
      control={control}
      rules={column.formProps.disabled ? {} : { ...FIELD_OPTIONS, ...(column.formProps.rules || {}) }}
      render={renderInput.bind(null, { errors, column, setValue, watch, isRemoveForm, control })}
    />
  )
}
