import { useEffect, useState, useCallback, useRef } from 'react'
import { isEqual } from 'lodash'
import { useSessionStorage } from 'hooks/use-session-storage'
import { globalFiltersQuerySelector } from 'redux/selectors/globalFilters'
import { useSelector } from 'react-redux'

import botAPI from '../../../../api/bot-api'

export const useHistoryApi = (page, pageSize, params) => {
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [isLastPage, setLastPage] = useState(false)
  const filters = useSelector(globalFiltersQuerySelector)

  const fetchData = useCallback(async () => {
    try {
      setIsFetching(true)
      const response = await botAPI.getHistory(page, pageSize, params.sortBy, params.filters, filters)

      setData(response?.History || [])
      setLastPage(response?.TotalRecords < pageSize)
    } catch (e) {
      console.error(e)
      setData([])
      setLastPage(true)
    } finally {
      setIsFetching(false)
    }
  }, [page, params.sortBy, params.filters, pageSize, filters])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return [data, isFetching, isLastPage]
}

export const PAGE_SIZES = [7, 20, 50, 100, 1000, 2000, 5000, 10000]

export const usePagination = (start = 1) => {
  const [page, setPage] = useState(start)
  const [pageSize, setPageSize] = useSessionStorage('botSignalsPageSize', PAGE_SIZES[0])
  const tableRef = useRef(null)

  const decrement = useCallback(() => {
    setPage((prevPage) => {
      if (prevPage > 1) {
        return prevPage - 1
      }

      return prevPage
    })

    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
  }, [])

  const increment = useCallback(() => {
    setPage((prevPage) => prevPage + 1)
    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
  }, [])

  const setStartPage = useCallback(() => {
    setPage(start)
  }, [start])

  const changePageSize = useCallback(
    (e) => {
      setPageSize(e.target.value)
      setStartPage()
    },
    [setPageSize, setStartPage]
  )

  return [page, increment, decrement, setStartPage, pageSize, changePageSize, tableRef]
}

export const useSort = (setStartPage) => {
  const [params, setParams] = useState({
    sortBy: undefined,
    filters: undefined,
  })

  const handleSortChange = useCallback(
    ([sortBy], [filters]) => {
      if (!isEqual(params.sortBy, sortBy) || !isEqual(params.filters, filters)) {
        setStartPage()
        setParams({ sortBy, filters })
      }
    },
    [params.sortBy, params.filters, setStartPage]
  )

  return [params, handleSortChange]
}
