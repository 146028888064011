export const getTxPage = (networks, network, txHash) => {
  const networkPage = networks?.[network]?.block_explorer_url

  if (!txHash) {
    return networkPage
  }

  if (network === "ton") {
    return `${networkPage}/transaction/${txHash}`.replace(/([^:])\/\//g, "$1/");
  }

  return `${networkPage}/tx/${txHash}`.replace(/([^:]\/)\/+/g, "$1")
}
