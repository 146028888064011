import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import { useForm, Controller } from "react-hook-form"

import { useCallback, useEffect, useState } from 'react'

import { EXCHANGE_ACTION } from '../hooks'

const Exchange_Titles = {
  [EXCHANGE_ACTION.CREATE]: 'Create',
  [EXCHANGE_ACTION.UPDATE]: 'Update',
  [EXCHANGE_ACTION.REMOVE]: 'Remove',
}

const DEFAULT_VALUE = {
  name: '',
  internal_name: '',
  network_id: '',
  is_dex: false,
  available: false,
}

const FIELD_OPTIONS = {
  required: true,
  validate: (v) => typeof v === 'string' ? v.trim().length > 1 : v !== null && v !== undefined,
}

export const Exchange = ({ id, type, exchange, update, create, remove, close, networks }) => {
  const [mainError, setMainError] = useState('')
  const isRemoveForm = type === EXCHANGE_ACTION.REMOVE

  if (exchange.network_id === 0) {
    exchange.network_id = ''
  }

  const { control, handleSubmit, watch, getValues, clearErrors, formState: { errors } } = useForm({
    defaultValues: { ...DEFAULT_VALUE, ...exchange },
  });
  const onSubmit = useCallback(async (data) => {
    try {
      if (data.network_id === '') {
        delete data.network_id
      }

      if (type === EXCHANGE_ACTION.UPDATE) {
        await update({ id, data })
      }

      if (type === EXCHANGE_ACTION.CREATE) {
        await create(data)
      }

      close()
    } catch (err) {
      setMainError('Something went wrong.')
    }
  }, [type, update, create, close, id])

  const removeAndClose = useCallback(async () => {
    await remove({ exchangeId: id, exchangeName: exchange.name })
    close()
  }, [id, close, remove, exchange.name])

  const isDex = watch('is_dex')
  const networkId = watch('network_id')

  useEffect(() => {
    if (!isDex) {
      clearErrors('network_id')
    }
  }, [isDex, clearErrors])

  useEffect(() => {
    clearErrors('network_id')
  }, [networkId, clearErrors])

  const networkValidate = useCallback((value) => {
    const isDexValue = getValues('is_dex');

    if (!isDexValue) {
      return true
    }

    return !!value
  }, [getValues])

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={isRemoveForm ? () => {} : handleSubmit(onSubmit)}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
    >
      <Card sx={{ maxWidth: 310 }}>
        <CardContent>
          <Typography variant="h5" component="div" mb={2} id={'edit_popup_title'}>
            {Exchange_Titles[type]} exchange
          </Typography>
          {isRemoveForm && (
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              You're about to remove <b>{exchange.name}</b> exchange. Are you sure?
            </Typography>
          )}
          <Controller
            name="name"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                disabled={isRemoveForm}
                label="name"
                error={!!errors.name}
                helperText={errors.name && "name is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="internal_name"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                disabled={isRemoveForm}
                label="internal_name"
                error={!!errors.internal_name}
                helperText={errors.internal_name && "internal name is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="network_id"
            control={control}
            rules={{ validate: networkValidate }}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    select
                    label="network"
                    disabled={isRemoveForm}
                    error={!!errors.network_id}
                    helperText={errors.network_id && "Network is required for DEX"}
                    {...field}
                  >
                    <MenuItem value="">None</MenuItem>
                    {networks.map(({ id, name }) => <MenuItem key={name} value={id}>{name}</MenuItem>)}
                  </TextField>
                </FormControl>
              )
            }}
          />
          <Controller
            name="is_dex"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    disabled={isRemoveForm}
                  />
                }
                label="is_dex"
              />
            )}
          />
          <Controller
            name="available"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={field.value}
                    disabled={isRemoveForm}
                    {...field}
                  />
                }
                label="available"
              />
            )}
          />
          <Typography variant="caption" color="error">{mainError}</Typography>
        </CardContent>
        <CardActions>
          {isRemoveForm
            ? <Button size="small" color='error' id='confirm_button' onClick={removeAndClose}>Confirm</Button>
            : <Button size="small" color='primary' type="submit" id='submit_button' disabled={mainError !== ''}>Submit</Button>
          }
          <Button size="small" onClick={close}>Cancel</Button>
        </CardActions>
      </Card>
    </Box>
  )
}
