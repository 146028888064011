import MUIDialog from '@mui/material/Dialog'

import { Form } from './Form'

export const Dialog = ({ onClose, defaultValues, formType, value, save, remove, columns }) => {
  return (
    <MUIDialog onClose={onClose} open={!!value}>
      <Form
        formType={formType}
        close={onClose}
        remove={remove}
        save={save}
        defaultValues={defaultValues}
        columns={columns}
        value={value}
      />
    </MUIDialog>
  )
}
