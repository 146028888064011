import moment from 'moment'
import QueryFilter from 'components/Table/components/QueryFilter'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

export const COLUMNS = [
  {
    Header: 'Deployment name',
    accessor: 'name',
    Filter: renderFilter,
  },
  {
    Header: 'Version',
    accessor: 'version',
    Filter: renderFilter,
  },
  {
    Header: 'Deployment status',
    accessor: 'kubernetes_available',
    Cell: ({ value }) => <span className={value ? 'green' : 'red'}>{value.toString()}</span>,
    Filter: renderFilter,
  },
  {
    Header: 'Env enabled',
    accessor: 'env_enabled',
    Cell: ({ value }) => value.toString(),
    Filter: renderFilter,
    sortType: 'alphanumericCustom',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ value }) => (
      <span>{moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('YYYY-MM-DD HH:mm')}</span>
    ),
    Filter: renderFilter,
  },
  {
    Header: 'Archived status',
    accessor: 'db_status',
    Filter: renderFilter,
  },
]
