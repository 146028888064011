import { fetch } from './'

export const networksApi = {
  get: () => {
    return fetch
      .get(`networks`)
      .json()
      .catch((err) => console.log(err))
  },
  save: (network) => {
    return fetch
      .post('networks', { json: network })
      .json()
  },
  remove: (networkId) => {
    return fetch
      .delete(`networks/${networkId}`)
      .json()
  },
}

export default networksApi
