import { fetch } from './'

const blocksApi = {
  getBlocks: () => {
    return fetch
      .get(`block-status`)
      .json()
      .catch((err) => console.log(err))
  },
  blockExecution: (name, exchange) => {
    return fetch
      .post(`block-execution`, { json: { name, exchange } })
      .catch((err) => console.log(err))
  },
  unblockExecution: (name, exchange) => {
    return fetch
      .post(`unblock-execution`, { json: { name, exchange } })
      .catch((err) => console.log(err))
  },
}

export default blocksApi
