import Paper from '@mui/material/Paper'

import { useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSnackbar } from 'notistack';

import { NetworksSelector } from './components/NetworksSelector'
import { TokensTable } from './components/TokensTable'
import { PairsTable } from './components/PairsTable'

import { usePairsApi, useTokensApi } from './hooks'
import { useLocalStorage } from 'hooks/use-local-storage'

const TokensContainer = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
  width: 100%;
  max-width: 1024px;
`;

const Divider = styled.div`
  height: 20px;
  background: #696969;
  margin: 15px 0;
  opacity: .3;
`

export const DexTokens = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedNetwork, setSelectedNetwork] = useLocalStorage('dex-tokens-network', null)

  const tokens = useTokensApi(selectedNetwork, enqueueSnackbar)
  const pairs = usePairsApi(selectedNetwork, enqueueSnackbar)

  const selectAndFetch = useCallback((async network => {
    setSelectedNetwork(network)

    if (network) {
      try {
        await Promise.all([
          tokens.fetch(network.id),
          pairs.fetch(network.id),
        ])
      } catch (err) {
        console.log(err)
      }
    } else {
      tokens.reset()
      pairs.reset()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])

  useEffect(() => {
    if (selectedNetwork) {
      Promise.all([
        tokens.fetch(selectedNetwork.id),
        pairs.fetch(selectedNetwork.id),
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TokensContainer elevation={2} id='tokens_table'>
      <NetworksSelector selectedNetwork={selectedNetwork} onSelectNetwork={selectAndFetch} />
      <TokensTable selectedNetwork={selectedNetwork} tokens={tokens} />
      <Divider />
      <PairsTable selectedNetwork={selectedNetwork} tokens={tokens.data} pairs={pairs} />
    </TokensContainer>
  )
}
