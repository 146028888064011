import { Paper } from '@mui/material'
import { BotTable, UNARCHIVED } from 'pages/Bots/BotTable'
import { useBotApi, usePagination } from 'pages/Bots/hooks'

const skipColumns = ['orders', 'bot_error', 'opportunities']

const BotsList = () => {
  const { page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize, tableRef } = usePagination({
    name: 'all-bot-table',
  })

  const { bots, botsQuery, isFetching, setBots, botsQueryKey, setIsFetching, filterBots, exchanges, isLastPage } = useBotApi(
    UNARCHIVED,
    page,
    pageSize,
    null
  )

  return (
    <Paper
      sx={{
        '& .MuiPaper-root': { width: '99vw', maxWidth: '99vw' },
      }}
    >
      <BotTable
        tableRef={tableRef}
        botsQuery={botsQuery}
        botsQueryKey={botsQueryKey}
        botsType={UNARCHIVED}
        data={bots}
        setData={setBots}
        isFetching={isFetching}
        exchanges={exchanges}
        setIsFetching={setIsFetching}
        filterBots={filterBots}
        skipColumns={skipColumns}
        pagination={{
          page,
          incrementPage,
          decrementPage,
          setStartPage,
          pageSize,
          changePageSize,
          tableRef,
          isLastPage,
        }}
        isFullTable={true}
      />
    </Paper>
  )
}

export default BotsList
