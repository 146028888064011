import { useSearchParams, Navigate } from 'react-router-dom'
import { useLocalStorage } from 'hooks/use-local-storage'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import {
  awsCallTokenEndpoint,
  getAwsToken,
  redirectToCognitoPage
} from 'api/auth-api'

const AwsCallback = () => {
  const [sp] = useSearchParams()
  const [isAuth] = useLocalStorage('authenticated', false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuth) {
      const codeVerifier = localStorage.getItem('codeVerifier')
      const code = sp.get('code')

      console.log(codeVerifier, code)

      if (code && codeVerifier) {
        getAwsToken(() => awsCallTokenEndpoint('authorization_code', code, codeVerifier))
          .then(() => {
            console.log('auth success')
            navigate('/')
          })
      } else {
        console.log('auth fail redirect')
        redirectToCognitoPage()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isAuth) {
    console.log('redirect by auth')

    return <Navigate to="/" />
  }

  return (
    <div className='column'>
      <div>
        <h2>Authorizing</h2>
      </div>
      <div>
        <h3>
          If nothing happens, press{' '}
          <b onClick={redirectToCognitoPage} style={{ cursor: 'pointer' }}>
            HERE
          </b>
        </h3>
      </div>
    </div>
  )
}

export default AwsCallback
