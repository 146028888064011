import { Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material'

import s from './ReservesTable.module.css'

const CELL_CLASSES = {
  Expected: s.expectedCell,
  Factual: s.factualCell,
}

export const ReservesTableBody = ({ isFetching, getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }) => {
  if (isFetching) {
    return null
  }

  if (rows.length === 0) {
    return <h2 style={{ margin: '8px' }}>No results</h2>
  }

  return (
    <Table stickyHeader {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className={s.tableHeadCell}>
                  <span
                    style={{
                      userSelect: 'none',
                    }}
                    id='column_title'
                  >
                    {column.render('Header')}
                  </span>
                  {column.isSorted && (
                    <i
                      style={{
                        fontSize: '11px',
                        justifySelf: 'flex-start',
                      }}
                      className={`fas fa-chevron-${column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'}`}
                    />
                  )}
                </div>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)

          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <TableCell className={CELL_CLASSES[cell.value] || ''} {...cell.getCellProps()}>
                  {cell.render('Cell', {})}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
