import { fetch } from './'

const topDealsAPI = {
  savePairs: (pairs) => {
    return fetch
      .post(`set-top-deals-pairs`, { json: { id: pairs } })
      .json()
      .catch((err) => console.log(err))
  },
  getSavedPairs: () => {
    return fetch
      .get(`saved-top-deals-pairs`)
      .json()
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  getTopDeals: () => {
    return fetch
      .get(`get-top-deals`)
      .json()
      .catch((err) => console.log(err))
  },
}

export default topDealsAPI
