import { useState } from 'react'
import { TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import { Lock, LockOpen } from '@mui/icons-material'

import balancesApi from 'api/balances-api'
import { formatLongNumbers } from 'utilities/number-helpers'

const ExpectedField = styled(TextField)`
  min-width: 100px;
`

export const ExpectedForm = ({ currency, updateFactualCell, expected = 0 }) => {
  const [newExpected, setNewExpected] = useState(expected)
  const [locked, lock] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const updateExpected = (e) => {
    setNewExpected(e.target.value)
    const valid = !e.target.value || /^\d+(\.\d+)*$/.test(e.target.value)

    setError(!valid)
  }

  const toggleLock = () => {
    lock(!locked)

    if (!locked) {
      setNewExpected(expected)
    }
  }

  const submit = async (e) => {
    if (e.keyCode === 13) {
      if (!error) {
        lock(true)
        setLoading(true)
        await balancesApi.setExpectedBalance({ name: currency, expected: newExpected || 0 })
        setLoading(false)
        updateFactualCell(newExpected || 0, currency)
      }
    }
  }

  return (
    <ExpectedField
      value={newExpected}
      onChange={updateExpected}
      label={formatLongNumbers(newExpected)}
      size='small'
      onKeyDown={submit}
      disabled={locked}
      margin='none'
      error={error}
      id='expected_input'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={toggleLock} id='lock_icon'>
              {loading ? <CircularProgress style={{ width: 10, height: 10 }} /> : locked ? <Lock /> : <LockOpen />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
