import AuthButton from './AuthButton/AuthButton'

import s from './Header.module.css'

export const User = () => {
  const name = localStorage.getItem('username')

  return (
    <div className={s.user}>
      <span className={s.greeting}>
        Welcome, <strong>{name}</strong>
      </span>
      <AuthButton />
    </div>
  )
}
