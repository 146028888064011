import Paper from '@mui/material/Paper'

import { useState, useMemo } from 'react'
import styled from '@emotion/styled'

import Table from 'components/Table/Table'
import { getColumns } from './columns'

import { useSeekerRuleApi } from './hooks'

import { CreateRuleBtn } from './components/CreateRuleBtn'
import { RuleDialog } from './components/RuleDialog'

const RuleContainer = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
`;

export const SeekerRules = () => {
  const [selectedRule, setSelectedRule] = useState(null)
  const [{ data, create, remove, update }, loading] = useSeekerRuleApi()
  const columns = useMemo(() => getColumns(setSelectedRule), [setSelectedRule])

  return (
    <RuleContainer elevation={2} id='seeker_rules_table'>
      <Table
        title={<CreateRuleBtn setSelectedRule={setSelectedRule} />}
        data={data}
        columns={columns}
        loading={loading}
        initialState={{
          sortBy: [{ id: 'created_at', desc: true }],
        }}
        enableFilters
      />
      <RuleDialog
        value={selectedRule}
        onClose={() => setSelectedRule(null)}
        create={create}
        remove={remove}
        update={update}
      />
    </RuleContainer>
  )
}
