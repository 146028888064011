import Container from '@mui/material/Container'
import { getIsAuth } from 'storage/user-storage'

import s from './Header.module.css'
import { Nav } from './Nav'
import { User } from './User'
import { Filter } from './Filter'

import styled from '@emotion/styled'

const HeaderBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`

const Header = () => {
  const isAuth = getIsAuth()

  return (
    <div className={s.headerContainer}>
      <Container maxWidth='false'>
        <HeaderBody>
          {isAuth && <Filter />}
          <Nav />
          <User />
        </HeaderBody>
      </Container>
    </div>
  )
}

export default Header
