import { fetch } from './'

const settingsApi = {
  getTagImages: () => {
    return fetch
      .get(`get-tag-image-name`)
      .json()
      .catch((err) => console.log(err))
  },
  updateTagImage: (key, value) => {
    return fetch
      .post(`update-tag-image-name`, { json: { key, value } })
      .json()
      .catch((err) => console.log(err))
  },
  updateBotsVersion: () => {
    return fetch
      .post(`update-bots-version`)
      .json()
      .catch((err) => console.log(err))
  },
}

export default settingsApi
