import Expandable from 'components/Expandable/Expandable'
import DetailedBookkeepingTable from 'pages/Bookkeeping/components/DetailedBookkeepingTable'

import ReservesTable from './components/ReservesTable/ReservesTable'
import BlocksTable from './components/BlocksTable/BlocksTable'
import DailyProfitTable from './components/DailyProfit/DailyProfit'

import { MobileBotTable } from 'pages/Bots/MobileBotTable'
import { UNARCHIVED, ARCHIVED } from 'pages/Bots/BotTable'
import { usePagination, useBotApi } from 'pages/Bots/hooks'

export const MobileDashboard = () => {
  const pagination = usePagination({ name: 'mobile-bots' })
  const {
    bots,
    botsQueryKey,
    isFetching,
    setBots,
    fetchBots,
    setIsFetching,
    filterBots,
    exchanges,
    isLastPage,
  } = useBotApi(UNARCHIVED, pagination.page, pagination.pageSize)

  pagination.isLastPage = isLastPage

  const archivedPagination = usePagination({ name: 'mobile-archived-bots' })
  const archived = useBotApi(ARCHIVED, archivedPagination.page, archivedPagination.pageSize)

  archivedPagination.isLastPage = archived.isLastPage

  return (
    <div className='column' style={{ overflow: 'auto' }}>
      <BlocksTable />
      <Expandable title='Daily Profit'>
        <DailyProfitTable />
      </Expandable>
      <Expandable title='Balances'>
        <ReservesTable />
      </Expandable>
      <Expandable title='Bot List' onExpand={fetchBots} onClose={() => setBots({})}>
        <MobileBotTable
          botsType={UNARCHIVED}
          tableRef={pagination.tableRef}
          data={bots}
          isFetching={isFetching}
          botsQueryKey={botsQueryKey}
          setIsFetching={setIsFetching}
          filterBots={filterBots}
          exchanges={exchanges}
          pagination={pagination}
        />
      </Expandable>
      <Expandable title='Transactions'>
        <DetailedBookkeepingTable />
      </Expandable>
      <Expandable title='Archived bots' onExpand={archived.fetchBots} onClose={() => archived.setBots({})}>
        <MobileBotTable
          botsType={ARCHIVED}
          botsQueryKey={botsQueryKey}
          tableRef={archivedPagination.tableRef}
          data={archived.bots}
          setData={archived.setBots}
          isFetching={archived.isFetching}
          filterBots={archived.filterBots}
          pagination={archivedPagination}
        />
      </Expandable>
    </div>
  )
}
