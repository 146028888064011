import { fetch } from './'

const deploymentsApi = {
  list: (page, pageSize) => {
    return fetch
      .get(`bots-deployments`, { searchParams: { page, pageSize } })
      .json()
      .catch((err) => console.log(err))
  },
}

export default deploymentsApi
