import { useEffect, useMemo, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { IconButton, Typography } from '@mui/material'

import { buildGraphData } from '../../utilities/number-helpers'
import arbitrageAPI from '../../api/arbitrage-api'
import Preloader from '../Preloader/Preloader'

import s from './Graph.module.css'

const options = {
  responsive: true,
  maintainAspectRatio: false,
}

const Graph = ({ current, handleClose }) => {
  const chartRef = useRef(null)
  const [data, setData] = useState([])
  const [key, setKey] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const { directLine, possibleLine } = useMemo(() => buildGraphData(data), [data])

  useEffect(() => {
    setIsLoading(true)

    arbitrageAPI.getChartData(current.pair, current.bid, current.ask).then((data) => {
      setData(data)
      setIsLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setKey((key) => key + 1)
  }, [directLine, possibleLine])

  if (isLoading) {
    return (
      <div style={{ padding: 20 }}>
        <Preloader />
      </div>
    )
  }

  if (!data || data?.length < 1) {
    return <h2 style={{ textAlign: 'center' }}>No data</h2>
  }

  return (
    <div className='flex-column'>
      <IconButton size='small' className={s.closeButton} onClick={handleClose}>
        <i className='fas fa-times' />
      </IconButton>
      <Typography variant='h4'>{current.pair}</Typography>
      <Typography variant='h5'>
        {current.bid} - {current.ask}
      </Typography>
      <div style={{ position: 'relative', height: 380, width: '100%' }}>
        <Line
          key={key}
          ref={chartRef}
          data={{
            datasets: [
              {
                label: 'Direct, %',
                data: directLine,
                pointHitRadius: 3.5,
                pointRadius: 0,
                borderColor: 'rgb(81, 81, 255)',
                backgroundColor: 'rgba(81, 81, 255, .35)',
                borderWidth: 1.5,
              },
              {
                label: 'Possible, %',
                data: possibleLine,
                pointHitRadius: 3,
                pointRadius: 0,
                borderColor: 'black',
                borderWidth: 1.5,
              },
            ],
          }}
          options={options}
          redraw={false}
        />
      </div>
    </div>
  )
}

export default Graph
