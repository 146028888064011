import { Button } from '@mui/material';

export function TopToolbar({ actions }) {
  return (
    <div>
      <Button
        variant="contained"
        onClick={actions.createRule}
        id='create_bot_icon'
      >
        <i className='fas fa-plus' /> Create a rule
      </Button>
    </div>
  );
}
