import Button from 'components/Button/Button'

import { awsLogout, redirectToCognitoPage } from '../../../../api/auth-api'
import { getIsAuth } from '../../../../storage/user-storage'

const AuthButton = () => {
  const isAuth = getIsAuth()

  const handleLogout = () => {
    awsLogout()
    // setError('')

    // const logout = async () => {
    //   try {
    //     setIsFetching(true)
    //     await function() {} //logout request

    //     localStorage.setItem(
    //       'user',
    //       JSON.stringify({
    //         accessToken: '',
    //         email: '',
    //         password: '',
    //         isAuth: false,
    //       })
    //     )
    //     setIsFetching(false)
    //     history.push('/login')
    //   } catch (error) {
    //     setIsFetching(false)
    //     setError(`Error: ${error.message}`)
    //   }
    // }
    // logout()
  }

  return (
    <>
      {/* <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => {}}
        message={error?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      /> */}
      <Button onClick={isAuth ? handleLogout : () => redirectToCognitoPage()}>{isAuth ? 'Log out' : 'Log in'}</Button>
    </>
  )
}

export default AuthButton
