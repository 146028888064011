import moment from 'moment'

export function alphanumericFalsyLast(rowA, rowB, columnId, desc) {
  const a = rowA.values[columnId]
  const b = rowB.values[columnId]

  if (!a && !b) {
    return 0
  }
  if (!a) {
    return desc ? -1 : 1
  }
  if (!b) {
    return desc ? 1 : -1
  }

  return a - b
}

export function alphanumericCustom(rowA, rowB, columnId, desc) {
  const a = rowA.values[columnId]
  const b = rowB.values[columnId]

  return a - b
}

export function botErrorSort(rowA, rowB, columnId, desc) {
  const a = rowA.values[columnId]?.momentUnix
  const b = rowB.values[columnId]?.momentUnix

  if (!a && !b) {
    return 0
  }

  if (!a) {
    return desc ? -1 : 1
  }

  if (!b) {
    return desc ? 1 : -1
  }

  return a - b
}

// sort by total amount of signals
export function signalSort(rowA, rowB, columnId, desc) {
  var TotalA = 0, TotalB = 0

  const a = Object.values(rowA.values[columnId] || {})
  const b = Object.values(rowB.values[columnId] || {})

  a.forEach((row) => {
    TotalA += row.today
    TotalA += row.previous_days
  })

  b.forEach((row) => {
    TotalB += row.today
    TotalB += row.previous_days
  })

  // console.log(TotalA, TotalB)

  return TotalA - TotalB
}

export function momentSort(rowA, rowB, columnId, desc) {
  const a = moment.isMoment(rowA.values[columnId]) ? rowA.values[columnId].valueOf() : 0
  const b = moment.isMoment(rowB.values[columnId]) ? rowB.values[columnId].valueOf() : 0

  if (!a && !b) {
    return 0
  }

  if (!a) {
    return desc ? -1 : 1
  }

  if (!b) {
    return desc ? 1 : -1
  }

  return a - b
}
