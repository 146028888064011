import { useState, useCallback, useMemo, useEffect } from 'react'
import { useNetworksSelector, useExchangesListSelector } from 'redux/selectors/settings'

import { dexExecutionApi } from 'api/dex-execution-api'
import { useBalance } from 'api/balances-api'
import { getColumns } from './columns'
import { useLocalStorage } from 'hooks/use-local-storage'

export const useWalletsApi = () => {
  const [data, setData] = useState({})
  const { data: balance, isFetching: loading, refetch } = useBalance()

  const fetch = useCallback(() => {
    if (balance) {
      const wallets = {}

      balance.dexAssets.forEach(({ dexWallet, network }) => {
        if (dexWallet) {
          wallets[network] = { wallet: dexWallet, network }
        }
      })

      setData(wallets)
    }
  }, [balance])

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    data,
    fetch: refetch,
    loading,
  }
}

export const useAllowancesApi = () => {
  const [data, setData] = useState([])
  const [spenderAddress, setSpenderAddress] = useState('')
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async (spender) => {
    setLoading(true)
    const res = await dexExecutionApi.allowances(spender)

    if (res) {
      setSpenderAddress(res.spender_address)
      setData(res.assets || [])
    }

    setLoading(false)
  }, [])

  const reset = useCallback(() => {
    setData([])
    setSpenderAddress('')
  }, [])

  return {
    data,
    spenderAddress,
    fetch,
    reset,
    loading,
  }
}

export const useAllowance = () => {
  const availableNetworks = useNetworksSelector()
  const availableExchanges = useExchangesListSelector()
  const { data: wallets } = useWalletsApi()
  const exchanges = useMemo(() => availableExchanges
    .filter(({ network_name }) => !!wallets[network_name])
    .map(elm => ({ ...elm, wallet: wallets[elm.network_name].wallet }))
    .sort((a, b) => {
      return a.network_id - b.network_id
    })
  , [wallets, availableExchanges])
  const [selectedExchange, setSelectedExchange] = useLocalStorage('allowance-exchange', null)

  const { data, fetch, loading, reset, spenderAddress } = useAllowancesApi()
  const columns = useMemo(() => selectedExchange?.network_name
    ? getColumns(availableNetworks, selectedExchange.network_name)
    : []
  , [availableNetworks, selectedExchange])

  const selectSpender = useCallback((exchange) => {
    setSelectedExchange(exchange)

    if (!exchange) {
      reset()
    } else {
      const newSpender = {
        network: exchange.network_name,
        spender: exchange.internal_name,
        wallet: exchange.wallet,
      }

      fetch(newSpender)
    }
  }, [fetch, reset, setSelectedExchange])

  useEffect(() => {
    if (selectedExchange) {
      const newSpender = {
        network: selectedExchange.network_name,
        spender: selectedExchange.internal_name,
        wallet: selectedExchange.wallet,
      }

      fetch(newSpender)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    exchanges,
    networks: availableNetworks,
    selectSpender,
    spenderAddress,
    selectedExchange,
    data,
    columns,
    loading,
  }
}
