import { FormControl, InputAdornment, InputLabel, TextField } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import Select from 'react-select'

import s from './BotEditor.module.css'
import { Directions } from './components/Directions'

const steps = [1, 2, 3]

export const BotEditorTriple = ({
  values,
  handleChange,
  archived,
  dexExchanges,
  cexExchanges,
  networks,
  createAmountFields,
  changeAmountState,
  setValues,
}) => {
  return (
    <div className={s.tripleEditorWrapper}>
      <div className={s.tripleBaseParams}>
        <div className={s.tripleBaseParamsColumn}>
          <FormControl style={{ marginBottom: 8 }}>
            <InputLabel className={s.inputLabel} htmlFor={'pmin'}>
              Pmin
            </InputLabel>
            <NumericFormat
              id='pmin'
              name='pmin'
              customInput={TextField}
              value={values.pmin}
              size='small'
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              decimalSeparator={'.'}
              onChange={handleChange}
              autoComplete='off'
              disabled={archived}
            />
          </FormControl>
          <FormControl style={{ marginBottom: 8 }}>
            <InputLabel className={s.inputLabel} htmlFor={'pmin'}>
              CEX Multiplier
            </InputLabel>
            <NumericFormat
              id='cex_multiplier'
              name='cex_multiplier'
              customInput={TextField}
              value={values.cex_multiplier}
              size='small'
              decimalSeparator={'.'}
              onChange={handleChange}
              autoComplete='off'
              disabled={archived}
            />
          </FormControl>
        </div>
        <Directions
          values={values}
          archived={archived}
          createAmountFields={createAmountFields}
          changeAmountState={changeAmountState}
          setValues={setValues}
        />
      </div>
      <div className={s.tripleStepParams}>
        {steps.map((step) => (
          <div key={`step-${step}`} className={s.tripleStepParamsRow}>
            <h5 style={{ marginRight: 8 }}>Choose your fighter {step}</h5>
            {step === 1 && (
              <FormControl>
                <InputLabel className={s.inputLabel} htmlFor={`step${step}.network`}>
                  Network
                </InputLabel>
                <Select
                  className={s.inputSelect}
                  inputIt={`step${step}.network`}
                  styles={{
                    control: (base) => ({ ...base, maxWidth: 150, minWidth: 120, marginBottom: 10 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  options={Object.values(networks)}
                  value={values[`step${step}`]?.network}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.name}
                  onChange={(value) => handleChange({ target: { name: `step${step}.network`, value } })}
                  isDisabled={archived}
                  id={`step${step}_network_select`}
                />
              </FormControl>
            )}
            {step === 1 ? (
              <FormControl>
                <InputLabel className={s.inputLabel} htmlFor={`step${step}.exchange`}>
                  Exchange
                </InputLabel>
                <Select
                  className={s.inputSelect}
                  inputIt={`step${step}.exchange`}
                  styles={{
                    control: (base) => ({ ...base, maxWidth: 150, minWidth: 120, marginBottom: 10 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  options={
                    values.step1?.network?.id
                      ? dexExchanges.filter(({ network_id }) => network_id === values.step1.network.id)
                      : dexExchanges
                  }
                  value={values.step1?.exchange}
                  getOptionLabel={(x) => x.internal_name}
                  getOptionValue={(x) => x.internal_name}
                  onChange={(value) => handleChange({ target: { name: `step1.exchange`, value } })}
                  isDisabled={archived}
                  id={`step1_exchange_select`}
                />
              </FormControl>
            ) : (
              <FormControl>
                <InputLabel className={s.inputLabel} htmlFor={`step${step}.exchange`}>
                  CEX
                </InputLabel>
                <Select
                  className={s.inputSelect}
                  inputIt={`step${step}.exchange`}
                  styles={{
                    control: (base) => ({ ...base, maxWidth: 150, minWidth: 120, marginBottom: 10 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  options={cexExchanges}
                  value={values[`step${step}`]?.exchange}
                  getOptionLabel={(x) => x.internal_name}
                  getOptionValue={(x) => x.internal_name}
                  onChange={(value) => handleChange({ target: { name: `step${step}.exchange`, value } })}
                  isDisabled={archived}
                  id={`step${step}_exchange_select`}
                />
              </FormControl>
            )}
            <FormControl style={{ marginBottom: 8 }}>
              <InputLabel className={s.inputLabel} htmlFor={`step${step}.left`}>
                Left
              </InputLabel>
              <TextField
                className={s.input}
                name={`step${step}.left`}
                value={values[`step${step}`]?.left}
                onChange={handleChange}
                size='small'
                id={`step${step}_left`}
                inputProps={{ min: 0 }}
                disabled={archived}
              />
            </FormControl>
            <FormControl style={{ marginBottom: 8 }}>
              <InputLabel className={s.inputLabel} htmlFor={`step${step}.right`}>
                Right
              </InputLabel>
              <TextField
                className={s.input}
                name={`step${step}.right`}
                value={values[`step${step}`]?.right}
                onChange={handleChange}
                size='small'
                id={`step${step}_right`}
                inputProps={{ min: 0 }}
                disabled={archived}
              />
            </FormControl>
            {step === 1 && (
              <FormControl style={{ marginBottom: 8 }}>
                <InputLabel className={s.inputLabel} htmlFor={'pool'}>
                  Pool Address
                </InputLabel>
                <TextField
                  className={s.input}
                  name={`step${step}.pool`}
                  value={values[`step${step}`]?.pool}
                  onChange={handleChange}
                  size='small'
                  id={`step${step}.pool`}
                  inputProps={{ min: 0 }}
                  disabled={archived}
                />
              </FormControl>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
