import IconButton from '@mui/material/IconButton'

export const CreateRule = ({ setSelectedRule }) => {
  return <>
    Balance Leveling rule
    <IconButton
      onClick={() => {
        setSelectedRule?.({ _type: 'create' })
      }}
      size={'small'}
      style={{ margin: '0 8px', color: '#1976d2' }}
    >
      <i className='fas fa-plus' />
    </IconButton>
  </>
}
