import { createSelector } from 'reselect'
import { isEmpty } from 'lodash'

export const globalFiltersSelector = createSelector(state => state.globalFilters, v => v)

export const globalFiltersQuerySelector = createSelector(state => state.globalFilters, filters => {
  const searchParams = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const key in filters) {
    if (key && filters[key]?.enabled && !isEmpty(filters[key]?.value)) {
      searchParams[key] = Array.isArray(filters[key]?.value) ? filters[key]?.value.map(({ id }) => id).join(',') : filters[key]?.value?.id
    }
  }

  return searchParams
})
