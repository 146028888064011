import RulesTable from './components/RuleTable'
import { Alert, Box, Snackbar } from '@mui/material'
import { useState } from 'react'

const CexFirst = () => {
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  return (
    <>
      <Box style={{ width: '90%' }}>
        <RulesTable snackbar={setPopup} />
      </Box>
      <Snackbar
        open={!!popup.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setPopup({ open: false })}
      >
        <Alert onClose={() => setPopup({ open: false })} severity={popup.severity}>
          <div className='column' style={{ whiteSpace: 'pre-wrap' }}>
            {popup.message}
          </div>
        </Alert>
      </Snackbar>
    </>
  )
}

export default CexFirst
