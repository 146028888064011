export const validateLogin = (values) => {
  let errors = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (values.password.length < 6 || values.password.length > 30) {
    errors.password = 'Enter 6-30 symbols'
  }
  
  return errors
}
