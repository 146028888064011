import { fetch } from './'

const bookkeepingApi = {
  getBookkeeping: (page, page_size) => {
    return fetch
      .get(`bookkeeping`, {
        searchParams: {
          page,
          page_size,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
  getBookkeepingByPeriod: (startTime, endTime, filters) => {
    return fetch
      .get(`bookkeeping-period`, {
        searchParams: {
          start_time: startTime,
          end_time: endTime,
          ...filters,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
}

export default bookkeepingApi
