/* eslint-disable no-restricted-globals */
import { useContext, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { FullscreenContext } from 'providers/Fullscreen/Provider'

const useFullscreen = (orientation = 'landscape-primary') => {
  const { container } = useContext(FullscreenContext)

  useEffect(() => {
    if (!isMobile || !container.node.current) {
      return
    }

    // Entring fullscreen mode and setting browser orientation
    try {
      container.enter().catch((e) => console.log(e.message))
      screen.orientation.lock(orientation)
    } catch (err) {
      console.log(err.message)
    }

    return () => {
      container.exit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation])
}

export default useFullscreen
