import Dialog from '@mui/material/Dialog'

import { Exchange } from './Exchange'

export const ExchangeDialog = ({ onClose, value, update, remove, create, networks }) => {
  const { _type, id, ...exchange } = value || {}

  return (
    <Dialog onClose={onClose} open={!!value}>
      <Exchange
        id={id}
        type={_type}
        exchange={exchange}
        networks={networks}
        close={onClose}
        update={update}
        remove={remove}
        create={create}
      />
    </Dialog>
  )
}
