import { Paper } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import styled from '@emotion/styled'

import deploymentsApi from 'api/deployments-api'
import Table from 'components/Table/Table'
import { COLUMNS } from './columns'
import { Pagination, usePagination } from 'components/Pagination'

const useDeploymentsApi = ({ page, pageSize, setLastPage }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async (page, pageSize) => {
    setLoading(true)
    const res = await deploymentsApi.list(page, pageSize)

    setLastPage(res.length < pageSize)
    setData(res)
    setLoading(false)
  }, [setLastPage])

  useEffect(() => {
    fetch(page, pageSize)
  }, [fetch, page, pageSize])

  return [data, loading]
}

const DeploymentsContainer = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
`;

export const Deployments = () => {
  const {
    page,
    increment,
    decrement,
    pageSize,
    changePageSize,
    tableRef,
    isLastPage,
    setLastPage,
  } = usePagination({ name: 'deploymentPageSize' })

  const [deployments, loading] = useDeploymentsApi({ page, pageSize, setLastPage })

  return (
    <DeploymentsContainer elevation={2} id='deployments_table'>
      <Table title="Deployments" data={deployments} columns={COLUMNS} loading={loading} enableFilters />
      <Pagination
        page={page}
        increment={increment}
        decrement={decrement}
        pageSize={pageSize}
        changePageSize={changePageSize}
        tableRef={tableRef}
        isLastPage={isLastPage}
      />
    </DeploymentsContainer>
  )
}
