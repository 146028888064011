import { useState } from 'react'
import styled from '@emotion/styled'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { getAddressPage } from 'utilities/get-address-page'
import { CopyButton } from 'components/CopyButton'
import IconButton from '@mui/material/IconButton'

const AutocompleteContainer = styled.div`
  padding: 10px;
`

const Focus = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: grey;
  z-index: 3;
  opacity: .7;
  transition: all .3s ease;
`

const StyledAutocomplete = styled(Autocomplete)`
  z-index: 4;
  position: relative;
  background: #fff;
  border-radius: 4px;
`

const AddressesContainer = styled.div`

`

const SpenderSelectorContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const SpenderSelector = ({ exchanges, spenderAddress, networks, onSelectExchange, selectedExchange, focus = false }) => {
  const [focused, onFocus] = useState(!!selectedExchange)
  const walletHref = selectedExchange?.wallet && getAddressPage(networks, selectedExchange?.network_name, selectedExchange?.wallet)
  const shortAddr = selectedExchange?.wallet && `${selectedExchange?.wallet.slice(0, 8)}...${selectedExchange?.wallet.slice(-8)}`

  const spenderHref = spenderAddress && getAddressPage(networks, selectedExchange?.network_name, spenderAddress)
  const spenderShortAddr = spenderAddress && `${spenderAddress.slice(0, 8)}...${spenderAddress.slice(-8)}`

  return (
    <SpenderSelectorContainer>
      <AutocompleteContainer>
        {focus && !focused && <Focus />}
        <StyledAutocomplete
          disablePortal
          openOnFocus
          blurOnSelect
          value={selectedExchange}
          onChange={(_, newValue) => {
            onSelectExchange(newValue)
          }}
          isOptionEqualToValue={(val) => val.id === selectedExchange?.id}
          onFocus={() => onFocus(true)}
          getOptionLabel={(option) => option.name}
          options={exchanges}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select Spender" />}
          size="small"
        />
      </AutocompleteContainer>
      <AddressesContainer>
        <div>
          wallet:
          <CopyButton value={selectedExchange?.wallet} />
          {shortAddr}
          <IconButton
            compinent="a"
            target="_blank"
            href={walletHref}
            size={'small'}
          >
            <i className='fas fa-external-link-alt fa-xs' id='allowance_wallet' />
          </IconButton>
        </div>
        <div>
          spender:
          <CopyButton value={spenderAddress} />
          {spenderShortAddr}
          <IconButton
            compinent="a"
            target="_blank"
            href={spenderHref}
            size={'small'}
          >
            <i className='fas fa-external-link-alt fa-xs' id='allowance_spender' />
          </IconButton>
        </div>
      </AddressesContainer>
    </SpenderSelectorContainer>
  )
}
