import { useState } from 'react'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

export const BotFilterByExchange = ({ bots, exchanges, filterByExchange }) => {
  const [value, setValue] = useState(null)

  return (
    <Autocomplete
      disablePortal
      openOnFocus
      blurOnSelect
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue)
        filterByExchange(newValue?.id || null)
      }}
      options={exchanges}
      sx={{ width: 160 }}
      renderInput={(params) => <TextField {...params} label="Filter By CEX" />}
      size="small"
    />
  )
}
