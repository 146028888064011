import { useState } from 'react'

export const useLocalStorage = (key, initialValue, history) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)

      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)

      return initialValue
    }
  })

  const setValue = (value, opt = {}) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)
      if (typeof window !== 'undefined') {
        if (history && !opt.skipHistory) {
          const keyItems = Object.keys(window.localStorage).filter(k => k.startsWith(`${key}:`)).sort()

          if (keyItems.length >= history - 1) {
            const removeItems = keyItems.slice(0, keyItems.length - history + 1)

            for (const removeItem of removeItems) {
              window.localStorage.removeItem(removeItem)
            }
          }

          window.localStorage.setItem(`${key}:${Date.now()}`, JSON.stringify(valueToStore))
        }

        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
