import { Paper, TableContainer, Typography } from '@mui/material'
import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useSortBy, useTable } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { useNetworksSelector } from 'redux/selectors/settings'

import Preloader from 'components/Preloader/Preloader'
import { RefetchButton } from 'components/RefetchButton'
import { globalFiltersSelector } from 'redux/selectors/globalFilters'
import { useSelector } from 'react-redux'

import { getColumns } from './columns'
import s from './ReservesTable.module.css'
import { useBalanceData } from './useBalanceData'
import { ReservesTableBody } from './ReservesTableBody'
import { LowBalanceWarning } from './LowBalanceWarning'
import { ExpectedBalanceWarning } from './ExpectedBalanceWarning'

const ReservesTable = () => {
  const availableNetworks = useNetworksSelector()
  const [query, updateFactualCell] = useBalanceData()
  const filters = useSelector(globalFiltersSelector)
  const columns = useMemo(
    () => getColumns(query.data.currencies, updateFactualCell, availableNetworks, filters),
    [query.data.currencies, updateFactualCell, availableNetworks, filters]
  )

  const tableProps = useTable(
    {
      columns,
      data: query.data.tableData,
      disableMultiSort: true,
    },
    useSortBy,
    useSticky
  )

  return (
    <Paper className={`${s.paper} flex-column`} elevation={2} id='balances_table'>
      <div className={s.heading}>
        {!isMobile && <Typography variant={'h5'}>Balances <RefetchButton isRefetching={query.isRefetching} refetch={query.refetch} /></Typography>}
      </div>
      <ExpectedBalanceWarning isFetching={query.isFetching} data={query.data.tableData} />
      <LowBalanceWarning isFetching={query.isFetching} data={query.data.tableData} />
      <TableContainer className={s.table}>
        <ReservesTableBody isFetching={query.isFetching} {...tableProps} />
        {query.isFetching && (
          <div className={s.preloader}>
            <Preloader />
          </div>
        )}
      </TableContainer>
    </Paper>
  )
}

export default ReservesTable
