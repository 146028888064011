import QueryFilter from 'components/Table/components/QueryFilter'
import SelectColumnFilter from 'components/Table/components/SelectColumnFilter'

import { formatDateTime, formatFloat } from '../../utilities/number-helpers'

export const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    disableFilters: true,
  },
  {
    Header: 'Side',
    accessor: 'side',
    Filter: SelectColumnFilter,
  },
  {
    Header: 'Network',
    accessor: 'network',
    Filter: SelectColumnFilter,
  },
  {
    Header: 'Exchange',
    accessor: 'dexExchange',
    Filter: QueryFilter,
  },
  {
    Header: 'CEX',
    accessor: 'cex1Exchange',
    Filter: QueryFilter,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableFilters: true,
  },
  {
    Header: 'Min profit',
    accessor: 'minProfit',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'PMin',
    accessor: 'pmin',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Profit',
    accessor: 'profit',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'DEX symbol',
    accessor: 'dexSymbol',
    Filter: QueryFilter,
  },
  {
    Header: 'DEX price',
    accessor: 'dexPrice',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'DEX total',
    accessor: 'dexTotal',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'CEX symbol',
    accessor: 'cex1Symbol',
    Filter: QueryFilter,
  },
  {
    Header: 'CEX price',
    accessor: 'cex1Price',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'CEX total',
    accessor: 'cex1Total',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Date',
    accessor: 'timestamp',
    Cell: ({ value }) => formatDateTime(value, true),
    disableFilters: true,
  },
]
