import ky from 'ky'
import { redirectToCognitoPage } from './auth-api'

export const fetch = ky.extend({
  prefixUrl: `${process.env.REACT_APP_BASE_URL}/api`,
  throwHttpErrors: false,
  hooks: {
    beforeRequest: [
      request => {
        const token = localStorage.getItem('token')

        request.headers.set('X-Requested-With', 'ky');
        request.headers.set('Authorization', `Bearer ${token}`);
        request.headers.set('Content-Type', `application/json`);
      },
    ],
    afterResponse: [
      async (_, __, response) => {
        if (response.status === 401) {
          return redirectToCognitoPage()
        }

        if (response.status >= 400) {
          const data = await response.json()

          throw new Error(data.error || data)
        }

        return response;
      },
    ],
  },
})
