import { getAddressPage } from 'utilities/get-address-page'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import QueryFilter from 'components/Table/components/QueryFilter'
import { CopyButton } from 'components/CopyButton'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

const humanNumber = (strNumber) => {
  let number = +strNumber

  if (number === Infinity) {
    return 'Inf'
  }

  if (number >= 1000000) {
    return '1M+'
  }

  if (number >= 100000) {
    return '100K+'
  }

  if (number >= 10000) {
    return '10K+'
  }

  return number
}

export const getColumns = (networks, network) => ([
  {
    Header: 'Name',
    accessor: 'name',
    Filter: renderFilter,
  },
  {
    Header: 'Allowance',
    accessor: 'allowance',
    Filter: () => null,
    Cell: ({ value }) => {
      return (
        <Tooltip title={value}>
          <span>{humanNumber(value)}</span>
        </Tooltip>
      )
    },
  },
  {
    Header: 'Token',
    accessor: 'token_address',
    Filter: renderFilter,
    Cell: ({ value }) => {
      const href = getAddressPage(networks, network, value)
      const shortAddr = `${value.slice(0, 4)}...${value.slice(-4)}`

      return (
        <div>
          <CopyButton value={value} />
          {shortAddr}
          <IconButton
            compinent="a"
            target="_blank"
            href={href}
            size={'small'}
          >
            <i className='fas fa-external-link-alt fa-xs' id='token_column'/>
          </IconButton>
        </div>
      )
    },
  },
])
