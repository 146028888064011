import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

import { useForm } from "react-hook-form"

import { useCallback, useState } from 'react'
import styled from '@emotion/styled'

import { ControllerField } from './ControllerField'

export const FORM_ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  REMOVE: 'remove',
}

const FORM_TITLES = {
  [FORM_ACTION.CREATE]: 'Create',
  [FORM_ACTION.UPDATE]: 'Update',
  [FORM_ACTION.REMOVE]: 'Remove',
}

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 280px;
`

const InputContainerItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const AlertContainer = styled.div`
  padding: 10px 15px;
  padding: ${({ hasError }) => hasError ? '10px 15px' : '0px 15px'};
  height: ${({ hasError }) => hasError ? '100%' : 0};
  box-sizing: border-box;
  transition: all .3s ease-in;
  background: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
`

export const Form = ({ columns, formType, value, defaultValues, save, remove, close }) => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const hideError = useCallback(() => setError(), [setError])

  const isRemoveForm = formType === FORM_ACTION.REMOVE

  const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm({ defaultValues });
  const onSubmit = useCallback(async (data) => {
    setLoading(true)
    try {
      await save(data)

      close()
    } catch (error) {
      console.log(error)
      setError(error.message)
    }

    setLoading(false)
  }, [save, close])

  const removeAndClose = useCallback(async () => {
    setLoading(true)
    await remove(value?.id)
    setLoading(false)
    close()
  }, [value?.id, close, remove])

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={isRemoveForm ? () => {} : handleSubmit(onSubmit)}
      sx={{
        maxWidth: 400,
        width: '100%',
      }}
    >
      <Card onClick={hideError} square>
        <CardContent>
          <Typography variant="h5" component="div" mb={2} id={'edit_popup_title'}>
            {FORM_TITLES[formType]}
          </Typography>
          {isRemoveForm && (
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              You're about to remove. Are you sure?
            </Typography>
          )}
          <InputContainer>
            {columns.filter(({ formProps }) => !formProps.hidden).map(column => (
              <InputContainerItem key={column.accessor}>
                <ControllerField
                  column={column}
                  errors={errors}
                  isRemoveForm={isRemoveForm}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                />
              </InputContainerItem>
            ))}
          </InputContainer>
        </CardContent>
        <StyledCardActions>
          {isRemoveForm
            ? <Button size="small" color='error' onClick={removeAndClose} id={'confirm_button'}>Confirm</Button>
            : <Button size="small" color='primary' type="submit" id={'submit_button'}>Submit</Button>
          }
          <Button size="small" onClick={close} id={'cancel_button'}>Cancel</Button>
        </StyledCardActions>
      </Card>
      <AlertContainer hasError={!!error}>
        {error}
      </AlertContainer>
      <LinearProgress
        variant={loading ? undefined : 'determinate'}
        value={0}
        color={!!error ? 'error' : 'primary'}
      />
    </Box>
  )
}
