import React from 'react'

import s from './BurgerButton.module.css'

const BurgerButton = ({ opened, children, onClick, label }) => {
  return (
    <button className={s.burgerBtn} onClick={onClick}>
      <div className={s.burger} style={{ transition: 'all .3s linear' }}>
        <div style={{ transform: opened ? 'rotate(45deg)' : 'rotate(0)' }} />
        <div
          style={{
            opacity: opened ? 0 : 1,
            transform: opened ? 'translateX(100%)' : 'translateX(0)',
          }}
        />
        <div style={{ transform: opened ? 'rotate(-45deg)' : 'rotate(0)' }} />
      </div>
      {label}
    </button>
  )
}

export default BurgerButton
