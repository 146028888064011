import QueryFilter from 'components/Table/components/QueryFilter'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

export const COLUMNS = ([
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Filter: renderFilter,
  },
  {
    Header: 'Exchange',
    accessor: 'exchange',
    Filter: renderFilter,
  },
  {
    Header: 'Points',
    accessor: 'points',
    Filter: renderFilter,
  },
])
