export const networks = [
  { name: 'Ethereum', id: "ethereum" },
  { name: 'BSC', id: "binanceSmartChain" },
  { name: 'Polygon', id: "polygon" },
  { name: 'Avalanche', id: "avalanche" },
  { name: 'Cronos', id: "cronos" },
  { name: 'Arbitrum', id: "arbitrum" },
  { name: 'Base', id: "base" },
  { name: 'Blast', id: "blast" },
  { name: 'Ton', id: "ton" },
  { name: 'Solana', id: "solana" },
]

export const cexExchanges = [
  { name: "BINANCE_SPOT", id: "BINANCE_SPOT" },
  { name: "BINANCE_FUTURES", id: "BINANCE_FUTURES" },
  { name: "HUOBI_SPOT", id: "HUOBI_SPOT" },
  { name: "GATE_SPOT", id: "GATE_SPOT" },
  { name: "KUCOIN_SPOT", id: "KUCOIN_SPOT" },
  { name: "CRYPTO_SPOT", id: "CRYPTO_SPOT" },
  { name: "MEXC_SPOT", id: "MEXC_SPOT" },
  { name: "BINGX_SPOT", id: "BINGX_SPOT" },
]

export const dexExchanges = [
  { name: 'PancakeSwapV2', id: "pancakeSwapV2" },
  { name: 'PancakeSwapV3', id: "pancakeSwapV3" },
  { name: 'UniswapV2', id: "uniswapV2" },
  { name: 'UniswapV3', id: "uniswapV3" },
  { name: 'Oneinch', id: "oneinch" },
  { name: 'Mdex', id: "MDEX" },
  { name: 'SushiSwap', id: "sushiSwap" },
  { name: 'DYdX', id: "dYdX" },
  { name: 'Quickswap', id: "quickSwap" },
  { name: 'Biswap', id: "biswap" },
  { name: 'BabySwap', id: "babySwap" },
  { name: 'TraderJoe', id: "traderJoe" },
  { name: 'VulcanDex', id: "vulcanDex" },
  { name: 'Pangolin', id: "pangolin" },
  { name: 'VVSFinance', id: "vvsFinance" },
  { name: 'MMFinance', id: "mmFinance" },
  { name: 'ApeSwap', id: "apeSwap" },
  { name: 'QuickswapV3', id: "quickSwapV3" },
  { name: 'ParaSwap', id: "paraSwap" },
  { name: 'Dfyn', id: "dfyn" },
  { name: 'Thena', id: "thena" },
  { name: 'ThrusterV2 (1%)', id: "thrusterV2fee1" },
  { name: 'ThrusterV2 (0.3%)', id: "thrusterV2fee03" },
  { name: 'ThrusterV3', id: "thrusterV3" },
  { name: 'StonFi', id: "stonFinance" },
  { name: 'Meteora', id: "meteora" },
  { name: 'Orca', id: "orca" },
  { name: 'Raydium', id: "raydium" },
]
