import IconButton from '@mui/material/IconButton'

import { EXCHANGE_ACTION } from '../hooks'

export const CreateExchangeBtn = ({ setSelectedExchange }) => (
  <>
    Exchanges
    <IconButton
      onClick={() => {
        setSelectedExchange({ _type: EXCHANGE_ACTION.CREATE })
      }}
      size={'small'}
      style={{ margin: '0 8px', color: '#1976d2' }}
    >
      <i className='fas fa-plus' />
    </IconButton>
  </>
)
