import DetailedBalanceLeveling from './components/DetailedBalanceLeveling/DetailedBalanceLeveling'
import { AllRules } from './components/AllRules'

const BalanceLeveling = () => {
  return (
    <>
      <DetailedBalanceLeveling />
      <AllRules />
    </>
  )
}

export default BalanceLeveling
