import { IconButton, Paper, Popover, Tooltip } from '@mui/material'
import { useState } from 'react'

const DirectionProfitSection = ({ value, row }) => {
  const [anchorEl, setAcnchorEl] = useState(null)

  const handleClose = () => {
    setAcnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'detailed-profit' : undefined

  return (
    <div className='flex-row align-center'>
      {value}
      <Tooltip title='Direction profit'>
        <IconButton onClick={(e) => setAcnchorEl(e.currentTarget)} size={'small'} style={{ marginLeft: 8 }}>
          <i className='fas fa-angle-down flex-column' style={{ width: 20, height: 20, justifyContent: 'center' }} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper className='column' style={{ padding: 8 }}>
          {row.original.directionProfit?.map((r, i) => (
            <div key={i} id='profit_across_bots'>
              <b>Direction {r.direction}: </b>
              {Number(r.profit).toFixed(2)}
            </div>
          ))}
        </Paper>
      </Popover>
    </div>
  )
}

export const COLUMNS = [
  {
    Header: 'Bot ID',
    accessor: 'botId',
    Cell: DirectionProfitSection,
  },
  {
    Header: 'Pair',
    accessor: 'pair',
  },

  {
    Header: 'Profit per period in USD',
    accessor: 'profitUSD',
    sortType: 'alphanumericCustom',
    Cell: ({ value }) => (value ? Number(value).toFixed(2) : '-'),
  },
  {
    Header: 'Raw profit per period',
    accessor: 'profitPercent',
    sortType: 'alphanumericCustom',
    Cell: ({ value }) => (value ? Number(value).toFixed(3) + '%' : '-'),
  },
]
