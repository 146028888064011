import { FormControl, TextField } from '@mui/material'

export const BotEditorNotes = ({
  values,
  handleChange,
}) => {
  return (
    <FormControl style={{ marginBottom: 8 }} fullWidth>
      <TextField
        name='notes'
        value={values.notes}
        onChange={handleChange}
        size='small'
        id='notes'
        multiline
        minRows={8}
        maxRows={20}
        fullWidth
        inputProps={{ min: 0 }}
      />
    </FormControl>
  )
}
